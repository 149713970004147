/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import StoreDropdown from "views/CommonUseComponents/StoreDropdown.js";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import ReactTable from "components/ReactTable/ReactTable.js";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_SELECT from '@material-ui/icons/CheckCircleOutline';
import ICON_SELECTED from '@material-ui/icons/CheckCircle';
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Receivable_add(props) {
  // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_amount, setData_amount] = React.useState(0);
  const [data_amount_org, setData_amount_org] = React.useState(0);
  const [data_remark, setData_remark] = React.useState("");
  const [data_debtorID, setData_debtorID] = React.useState("");
  const data_targetCollection = "Receivable";

  const [data_date, setData_date] = React.useState(new Date());
  const [dateState, setdateState] = React.useState("");

  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");

  const [data_recordChangeType, setData_recordChangeType] = React.useState("-");
  const [
    data_recordChangeType_org,
    setData_recordChangeType_org,
  ] = React.useState("");

  const [data_paymentID, setData_paymentID] = React.useState("");
  const [data_paymentName, setData_paymentName] = React.useState("");

  const [data_debtorName, setData_debtorName] = React.useState("");
  const [data_debtorCode, setData_debtorCode] = React.useState("");
  const [data_debtorPhone, setData_debtorPhone] = React.useState("");


  const [pathToRedirect, setRedirect] = React.useState("");

  const [emptyState, setEmptyState] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [
    data_selectedCustomerData,
    setData_selectedCustomerData,
  ] = React.useState(null);
  const [
    data_selectedCustomerDataReceivableBalance,
    setData_selectedCustomerDataReceivableBalance,
  ] = React.useState(null);

  //   const collectionsToLoad = ["Store", "Customer", "Payment"];
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [isLoadedEditData, setIsLoadedEditData] = React.useState(false);

  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_searchID, setData_searchID] = React.useState("");
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(25);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const classes_tableBtn = useStyles_tableBtn();

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  React.useEffect(() => {
    var preloadListStr = localStorage.getItem("preloadDataList")
    var preloadList = JSON.parse(preloadListStr);

    if (!locationState || locationState == null) {
      setLocationState(props.location.state);
      setHistoryState(props.history.location.state);
    } else {
      if (data_dataListArray === null ) {
        // const stateConfig = locationState.config;

        if (preloadList["Store"].length > 0) {
          setData_storeID(preloadList["Store"][0].id);
          setData_storeName(preloadList["Store"][0].itemName);
        }
        setData_dataListArray(preloadList);
      } else {
        if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
          set_isLoaded(true);

          set_isLoading(true);

          getCustomerList();
        }
      }
      // pre load data for edit
      if (
        data_dataListArray !== null &&
        locationState !== null &&
        locationState.data !== null &&
        !isLoadedEditData
      ) {
        const stateData = locationState.data;

        setIsLoadedEditData(true);
        setIsEdit(true);
        setData_id(stateData.id);
        setData_itemName(stateData.itemName);
        setData_amount(stateData.amount);
        setData_amount_org(stateData.amount);
        setData_remark(stateData.remark);
        setData_date(stateData.date);
        setData_paymentID(stateData.payment);
        select_customer_withID(stateData.debtorID);

        setData_storeID(stateData.storeID);
        setData_recordChangeType(stateData.type);
        setData_recordChangeType_org(stateData.type);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [
    data_dataListArray,
    data_dataList,
    data_pageCount,
    data_page,
    locationState,
  ]);

  //   function do_loadListOfCollection(collectionsList, onHoldList)
  //   {
  //       var newList = collectionsList;
  //       var newOnHoldList = onHoldList;

  //       const targetCollection = newList[0];
  //       return new Promise((onDone) => {
  //           const body = {
  //               "data_targetCollection":targetCollection
  //           }
  //           fetchAPI.do_fetch('post', 'admin/list-all', body)
  //           .then((res) => {
  //             newOnHoldList[targetCollection] = res.data.data_list;
  //             newList.splice(0, 1);

  //             if (newList.length > 0)
  //             {
  //                 do_loadListOfCollection(newList, newOnHoldList).then(finalOnHoldList => {
  //                     onDone(finalOnHoldList);
  //                 })
  //             }
  //             else
  //             {
  //                 onDone(newOnHoldList);
  //             }
  //             //   onDone(res.data.data_list);
  //           }, error => {
  //               console.log("failed: ", error);
  //               onDone(null);
  //           })
  //       });
  //   }

  function select_customer_withID(debtorID) {
    getCustomerList("uid", debtorID, (dataList) => {
      dataList.map((c, key) => {
        if (c.id === debtorID) {
          do_selectCustomer(c);
        }
      });
    });
  }

  function processDataWithActions(targetData) {
    //   console.log("processDataWithActions called");
    //   console.log("data_debtorID: ", data_debtorID);
    if (targetData === null) {
      return [];
    }

    const newData = targetData
      .filter((v) => v.isDeleted != true)
      .map((prop, key) => {
        const item_id = prop.id;
        const itemName = prop.itemName === "" ? "/" : prop.itemName;
        const phoneStr = prop.phone === "" ? "/" : prop.phone;

        const code = prop.code;
        const isSelf = (item_id === data_debtorID);
        const data_tabContent = [
          code,
          <span>{itemName}</span>,
          phoneStr,
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                do_selectCustomer(prop);
              }}
              color="success"
              className="edit"
            //   disabled={data_debtorID === item_id}
            >
              {isSelf ? <ICON_SELECTED className={classes_tableBtn.icon} /> : <ICON_SELECT className={classes_tableBtn.icon} />}
              {/* <ICON_DONE /> */}
            </Button>
          </div>,
        ];

        if (isSelf)
        {
          return {
            color: "success",
            data: data_tabContent
          }
        }
        else
        {
          return data_tabContent
        }
      });

    // setData_customerTableList(newData);
    return newData;
  }

  function getReceivableBalanceContent(receivableBalanceData)
  {
    if (receivableBalanceData !== null)
    {
      if (receivableBalanceData.qty !== 0)
      {
        return (
          <Danger>{parseFloat(receivableBalanceData.qty).toLocaleString()}</Danger>
        );
      }
      else
      {
        return (
          <Success>{Loc.no_arrears}</Success>
        );
      }
    }
    else
    {
      return (
        "-"
      );
    }
  }

  function do_getCustomerReceivableBalance(targetCustomerID)
  {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Receivable-Balance",
        data_docID: targetCustomerID,
      };
      fetchAPI.do_fetch("post", "admin/get-related-records", body).then(
        (res) => {
          console.log("Get balance success: ", res.data);
          onDone(res.data);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function do_selectCustomer(customerData) {
    setData_debtorID(customerData.id);
    setData_debtorName(customerData.itemName);
    setData_debtorCode(customerData.code)
    setData_debtorPhone(customerData.phone);
    setData_selectedCustomerData(customerData);

    // load and show receivable balance
    setData_selectedCustomerDataReceivableBalance(null);
    do_getCustomerReceivableBalance(customerData.id).then((receivableBalanceData) => {
      setData_selectedCustomerDataReceivableBalance(receivableBalanceData);
    });
  }

  const validCheck = () => {
    var result = true;
    if (data_amount == "") {
      setEmptyState("error");
      showAlert(false, Loc.failed, Loc.errorMsg_empty);
      result = false;
    } else if (data_amount <= 0) {
      showAlert(false, Loc.failed, Loc.errorMsg_value_negative);
      result = false;
    } else if (dateState === "error") {
      showAlert(false, Loc.failed, Loc.errorMsg_date_empty);
      result = false;
    }

    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/receivable-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      api_add_record().then((isDone_addRecord) => {
        if (isDone_addRecord) {
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);
        } else {
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      });
    } else {
      // setFetchingStatus("");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };

  function api_add_record() {
    return new Promise((onDone) => {
      const oldValue =
        data_recordChangeType_org === "-" ? -data_amount_org : data_amount_org;
      const newValue =
        data_recordChangeType === "-" ? -data_amount : data_amount;
      const diffValue = newValue - oldValue;

      console.log("diffValue: ", diffValue);

      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      getDebtorName(data_debtorID);

      const body = {
        id: idToPass,
        content: {
          itemName: data_name,
          amount: data_amount,
          remark: data_remark,
          date: new Date(data_date).getTime(),
          debtorID: data_debtorID,
          debtorCode: data_debtorCode,
          debtorName: data_debtorName,
          debtorPhone: data_debtorPhone,
          storeID: data_storeID,
          storeName: data_storeName,
          type: data_recordChangeType,
          paymentID: data_paymentID,
          paymentName: data_paymentName,
          diffValue: diffValue,
        },
      };
      fetchAPI.do_fetch("post", "admin/create-receivable", body).then(
        (res) => {
          console.log("success: ", res);
          // setData_itemName("");
          onDone(true);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(false);
        }
      );
    });
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/receivable-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_amount("");
            setData_remark("");
            setData_date(new Date());
            setData_debtorID("");
            // window.location.reload(false);
            setData_selectedCustomerData(null);
            setData_selectedCustomerDataReceivableBalance(null);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChangeRadio = (event) => {
    setData_recordChangeType(event.target.value);
  };

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{ pathname: pathToRedirect, state: historyState.config }}
        />
      );
    } else {
      return null;
    }
  };

  const imageDidUpload = (pathInStorage) => {
    if (pathInStorage != null) {
      setData_imageUrl(pathInStorage);
      console.log("imageDidUpload called, path: ", pathInStorage);
    }
  };
  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    if (fieldList && fieldList.length > 0) {
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    // console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  const getDebtorName = (debtorID) => {
    var targetname = "";

    getCustomerList("uid", debtorID, (dataList) => {
      dataList.map((c, key) => {
        if (c.id === debtorID) {
          console.log(`c  ${JSON.stringify(c.itemName)}`);
          setData_debtorName(c.itemName);
          setData_debtorCode(c.code)
          setData_debtorPhone(c.phone);

        }
      });
    });
    // console.log(`getDebtorName  ${targetname}`)
    return targetname;
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  function getCustomerList(key = "", keyword = "", isDone) {
    var body = {
      page: data_page,
      pageCount: data_pageCount,
    };

    if (key !== "" && keyword !== "") {
      body = { ...body, searchKeyword: keyword, searchKey: key };
    }
    fetchAPI.do_fetch("post", "admin/list-customer", body).then(
      (res) => {
        set_isLoading(false);
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
        if (key !== "" && keyword !== "") {
          isDone(res.data.data_list);
        }
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function _renderSearchBar() {
    const searchFieldList = [
      { itemName: Loc.code_old, key: ["code"], id: "code", type: "text",isPreloadField:false },
      {
        itemName: Loc.customer_name,
        key: ["itemName"],
        id: "itemName",
        type: "text",
        isPreloadField:false
      },
      {
        itemName: Loc.customer_phone,
        key: ["phone"],
        id: "phone",
        type: "text",
        isPreloadField:false
      },
    ];

    return (
      <SearchBar
        currentSearchID={
          data_searchID == "" ? searchFieldList[0].id : data_searchID
        }
        searchFieldList={searchFieldList}
        // searchFieldNameList={searchFieldNameList}
        // searchIDList={searchIDList}
        apiPath={"admin/list-customer"}
        loadDataList={() => {
          // set_isLoading(true);
          getCustomerList();
        }}
        searchResultDataList={(dataList) => {
          const m = dataList.filter((v) => v.isDeleted !== true);
          console.log(`m ${JSON.stringify(m)}`)
          if (m.length === 1)
          {
            const prop = Array.from(m)[0];
            do_selectCustomer(prop);
          }
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        didSelectedID={(id) => {
          setData_searchID(id);
        }}
        showDelete={false}
        locationState={locationState}
      />
    );
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.receivable_add}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem sm={6} align="center">
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_storeID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_storeName(
                          getStringToShow(
                            Loc.store_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Store"],
                            "Store",
                            selectedID
                          )
                        );
                        setData_storeID(selectedID);
                      }}
                      inputProps={{
                        name: data_storeName,
                        id: data_storeID,
                      }}
                    >
                      {getSelectMenuItem("Store")}
                    </Select>
                    {/* <Dropdown
                            currentID={data_storeID}
                            listData={data_dataListArray === null ? [] : data_dataListArray["Store"]}
                            defaultSelectionString={Loc.store_default}
                            didSelectItem={(selectedItem) => {
                                if (selectedItem === null)
                                {
                                    setData_storeName("");
                                    setData_storeID("");
                                }
                                else
                                {
                                    const selectedID = selectedItem.id;
                                    const storeName = selectedItem.itemName;
                                    setData_storeName(storeName);
                                    setData_storeID(selectedID);
                                }
                                
                            }}
                        /> */}
                  </GridItem>

                  <GridItem sm={6}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={true}
                        defaultValue={data_date}
                        value={
                          data_date == null
                            ? moment(new Date())
                            : moment(data_date)
                        }
                        inputProps={{
                          placeholder: Loc.receivable_date,
                        }}
                        onChange={(m) => {
                          if (moment.isMoment(m)) {
                            setdateState("success");
                            setData_date(m.toDate());
                          } else {
                            setdateState("error");
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Card>
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={4} align="left">
                                <small>{Loc.customer_id}</small>
                              </GridItem>
                              <GridItem xs={8} align="right">
                                {data_selectedCustomerData == null
                                  ? "-"
                                  : data_selectedCustomerData.id}
                              </GridItem>
                              
                              <GridItem xs={4} align="left">
                                <small>{Loc.code_old}</small>
                              </GridItem>
                              <GridItem xs={8} align="right">
                                {data_selectedCustomerData == null
                                  ? "-"
                                  : data_selectedCustomerData.code}
                              </GridItem>

                              <GridItem xs={4} align="left">
                                <small>{Loc.customer_name}</small>
                              </GridItem>
                              <GridItem xs={8} align="right">
                                {data_selectedCustomerData == null
                                  ? "-"
                                  : data_selectedCustomerData.itemName}
                              </GridItem>

                              <GridItem xs={4} align="left">
                                <small>{Loc.customer_phone}</small>
                              </GridItem>
                              <GridItem xs={8} align="right">
                                {data_selectedCustomerData == null ||
                                data_selectedCustomerData.phone === ""
                                  ? "-"
                                  : data_selectedCustomerData.phone}
                              </GridItem>

                              <GridItem xs={4} align="left">
                                <small>{Loc.receivable_balance}</small>
                              </GridItem>
                              <GridItem xs={8} align="right">
                                {data_selectedCustomerData == null
                                  ? "-"
                                  : getReceivableBalanceContent(data_selectedCustomerDataReceivableBalance)}
                              </GridItem>

                              <GridItem xs={4} align="left">
                                <small>{Loc.remark}</small>
                              </GridItem>
                              <GridItem xs={8} align="right">
                                {data_selectedCustomerData == null ||
                                data_selectedCustomerData.remark === ""
                                  ? "-"
                                  : data_selectedCustomerData.remark}
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    {/* <CustomInput
                            labelText={Loc.select + Loc.receivable_debtor}
                            id="receivable_debtor"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: getDebtorName(data_debtorID),
                                type: "text",
                            }}
                            disabled
                        /> */}
                  </GridItem>

                  <GridItem xs={6}>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checked={data_recordChangeType === "-"}
                            onChange={handleChangeRadio}
                            value="-"
                            name={Loc.repayment}
                            aria-label="-"
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label={Loc.repayment}
                      />
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checked={data_recordChangeType === "+"}
                            onChange={handleChangeRadio}
                            value="+"
                            name={Loc.receivable}
                            aria-label="receivable"
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label={Loc.receivable}
                      />
                    </div>
                  </GridItem>

                  <GridItem xs={6} align="center">
                    {data_recordChangeType === "+" ? null : (
                      // null
                      <Dropdown
                        currentID={data_paymentID}
                        listData={
                          data_dataListArray == null
                            ? []
                            : data_dataListArray["Payment"]
                        }
                        defaultSelectionString={Loc.payment_default}
                        didSelectItem={(selectedItem) => {
                          if (selectedItem === null) {
                            setData_paymentID("");
                            setData_paymentName("");
                          } else {
                            const selectedID = selectedItem.id;
                            const paymentName = selectedItem.itemName;
                            setData_paymentID(selectedID);
                            setData_paymentName(paymentName);
                          }
                        }}
                      />
                    )}
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      error={emptyState === "error"}
                      labelText={Loc.receivable_amount + "*"}
                      id="receivable_amount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_amount,
                        onChange: (event) => {
                          setEmptyState("success");
                          setData_amount(event.target.value);
                        },
                        type: "number",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      labelText={Loc.receivable_name}
                      id="receivable_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_name,
                        onChange: (event) => {
                          setData_itemName(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      labelText={Loc.remark}
                      id="remark"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_remark,
                        onChange: (event) => {
                          setData_remark(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.search}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <>
                {_renderSearchBar()}
                <PagingTable
                  tableHead={[
                    "#",
                    Loc.customer_name,
                    Loc.customer_phone,
                    Loc.select,
                  ]}
                  tableData={processDataWithActions(data_dataList)}
                  customCellClasses={[
                    classes_tableBtn.center,
                    classes_tableBtn.center,
                    classes_tableBtn.right,
                  ]}
                  customClassesForCells={[0, 0, 5]}
                  customHeadCellClasses={[
                    classes_tableBtn.center,
                    classes_tableBtn.center,
                    classes_tableBtn.right,
                  ]}
                  customHeadClassesForCells={[0, 0, 5]}
                  gotoPageClicked={(targetPage) => {
                    set_page(targetPage);
                    set_isLoaded(false);
                  }}
                  changedPageCount={(newPageCount) => {
                    set_page(0);
                    set_pageCount(newPageCount);
                    set_isLoaded(false);
                  }}
                  totalDataCount={data_dataListCount}
                  page={data_page}
                  pageCount={data_pageCount}
                />
              </>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
