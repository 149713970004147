/*eslint-disable*/
import React from "react";

import defaultImage from "assets/img/default-avatar.png";
import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { v4 as uuidv4 } from 'uuid';

export default function PictureUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);

  React.useEffect(() => {  
    // pre load data for edit
    if (props.imageUrl != null && props.imageUrl != "" && file == null)
    {
        setImagePreviewUrl(props.imageUrl);
    }
    else
    {
      setFile(null);
      setImagePreviewUrl(defaultImage);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  });

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    reader.onloadend = () => {
      setFile(newFile);
      setImagePreviewUrl(reader.result);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);
    }

    // handle upload
    handleSubmit(e);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
    let newFile = e.target.files[0];
    var new_uuid = uuidv4();
    var fileName_org_array = newFile.name.split(".");
    var fileExtention = fileName_org_array[fileName_org_array.length - 1];
    var fileName = new_uuid + "." + fileExtention;
    var fileType = newFile.type;
    var targetFolder = props.targetFolder;

    fetchAPI.do_uploadToCloud(newFile, targetFolder, fileName, fileType)
    .then((downloadUrl) => 
    {
      // console.log("upload success, downloadUrl: ", downloadUrl);
      props.imageDidUpload(downloadUrl);
    }, error => 
    {
        console.log("upload failed: ", error);
        props.imageDidUpload(null);
    })
  };
  return (
    <div className="picture-container">
      <div className="picture">
        <img src={imagePreviewUrl} className="picture-src" alt="..." />
        <input type="file" onChange={e => handleImageChange(e)} />
      </div>
      <h6 className="description">{Loc.choose_picture}</h6>
    </div>
  );
}
