import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import { TextField } from "@material-ui/core";

  import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
  const useStyles = makeStyles(styles);

// const useStyles = styles
export default function CustomTextField(props) {
    const classes = useStyles();
  const { id, inputProps, label } = props;

  const underlineClasses = classNames({
    [classes.underline]: true,

  });

  return (
    <TextField
        // classes={underlineClasses}
      label={label}
      id={id}
      inputProps={{ ...inputProps}}
    />
  );
}

// CustomTextField.propTypes = {
//   labelText: PropTypes.node,
//   labelProps: PropTypes.object,
//   id: PropTypes.string,
//   inputProps: PropTypes.object,
//   formControlProps: PropTypes.object,
//   inputRootCustomClasses: PropTypes.string,
//   error: PropTypes.bool,
//   success: PropTypes.bool,
//   white: PropTypes.bool,
//   helperText: PropTypes.node
// };
