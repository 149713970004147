/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import moment from 'moment';

import Dropdown from 'views/CommonUseComponents/Dropdown.js';

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";


const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import Success from "components/Typography/Success";
import Warning from "components/Typography/Warning";
import Danger from "components/Typography/Danger";
import Muted from "components/Typography/Muted";

export default function Staff_attendance(props) {
    
    const [pathToRedirect, setRedirect] = React.useState("");
    const [fetchingStatus, setFetchingStatus] = React.useState("");
    const [data_staffInfo, setData_staffInfo] = React.useState(null);
    const [data_storeList, setData_storeList] = React.useState(null);

    const [data_storeID, setData_storeID] = React.useState("");
    const [data_storeName, setData_storeName] = React.useState("");

    const [isLoading, set_isLoading] = React.useState(false);
    // alert
    const [alert_info, setAlert_info] = React.useState(null);
    const [alert_confirm, setAlert_confirm] = React.useState(null);
    const hideAlert = () => {
        setAlert_info(null);
        setAlert_confirm(null);
    }

    const classes = useStyles();
    const classes_alert = useStyles_alert();

  React.useEffect(() => {  

    get_staffInfo();
    do_loadStoreList();
    
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, []);

  function get_staffInfo()
  {
    // get admin info
    const body = {
        // "id":firebase.auth().currentUser.uid
    }
    fetchAPI.do_fetch('get', 'staff/attendance-get-state', body)
    .then((res) => {
        setData_staffInfo(res.data);

        const storeName = ((res.data.dutyStoreName && res.data.dutyStoreName !== "default") ? res.data.dutyStoreName : "");
        setData_storeName(storeName);
    }, error => {
        console.log("Get staff state faild");
    })
  }

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect to={pathToRedirect} />);
      }
      else
      {
          return null;
      }
  }

  function do_loadStoreList()
  {
    set_isLoading(true);
    const body = {

    }
    fetchAPI.do_fetch('post', 'staff/list-store-all', body)
    .then((res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        setData_storeList(res.data.data_list);
    }, error => {
      set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const showloading = () => {
    if (isLoading)
    {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      )
    }
    else
    {
      return null;
    }
  }

  function showHide_onDutyPart()
  {
      if (data_staffInfo && data_staffInfo.state && data_staffInfo.state === "off")
      {
        return (
            <GridItem xs={12}>
                <GridContainer>
                <GridItem xs={6}>
                    <Dropdown
                        currentID={data_storeID}
                        listData={(data_storeList == null) ? [] : data_storeList}
                        defaultSelectionString={Loc.store_default}
                        didSelectItem={(selectedItem) => {
                            console.log("selectedItem: ", selectedItem);
                            if (selectedItem === null)
                            {
                                setData_storeName("");
                                setData_storeID("");

                                var newStaffInfo = data_staffInfo;
                                newStaffInfo.dutyStoreName = "";
                                setData_staffInfo(newStaffInfo);
                            }
                            else
                            {
                                const selectedID = selectedItem.id;
                                const storeName = selectedItem.itemName;
                                setData_storeName(storeName);
                                setData_storeID(selectedID);

                                var newStaffInfo = data_staffInfo;
                                newStaffInfo.dutyStoreName = storeName;
                                setData_staffInfo(newStaffInfo);
                            }
                            
                        }}
                    />
                </GridItem>

                <GridItem xs={6}>
                    <Button 
                        color="info"
                        block
                        round
                        onClick = {() => handle_OnOffDuty("free")}
                        >
                            {Loc.on_duty}
                    </Button>
                </GridItem>
            </GridContainer>
        </GridItem>
        )
      }
      else
      {
          return null
      }
  }

  function showHide_dutyStatePart()
  {
      if (data_staffInfo && data_staffInfo.state && data_staffInfo.state !== "off")
      {
        return (
            <GridItem xs={12}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Button 
                            color={data_staffInfo.state === "free" ? "success" : null} 
                            onClick={() => handle_OnOffDuty("free")}
                            block>
                            {Loc.duty_state_free}
                        </Button>

                        <Button 
                            color={data_staffInfo.state === "busy" ? "warning" : null} 
                            onClick={() => handle_OnOffDuty("busy")}
                            block>
                            {Loc.duty_state_busy}
                        </Button>

                        <Button 
                            color={data_staffInfo.state === "break" ? "rose" : null}  
                            onClick={() => handle_OnOffDuty("break")}
                            block>
                            {Loc.duty_state_break}
                        </Button>
                        <hr/>
                        <Button 
                            color="danger"
                            block
                            round
                            onClick = {() => handle_OnOffDuty("off")}
                            >
                                {Loc.off_duty}
                        </Button>
                    </GridItem>
                 </GridContainer>
            </GridItem>
        )
      }
      else
      {
          return null
      }
  }

  function handle_OnOffDuty(targetState)
  {
    set_isLoading(true);
    const body = {
        "data_state": targetState,
        "data_storeID": (data_storeID !== "") ? data_storeID : "default",
        "data_storeName": (data_storeName !== "") ? data_storeName : "default",
    }

    console.log("body: ", body);
    fetchAPI.do_fetch('post', 'staff/attendance-change-state', body)
    .then((res) => {
        console.log("success: ", res.data);

        var newStaffInfo = data_staffInfo;
        newStaffInfo.state = targetState;
        newStaffInfo.lastDutyChangeTime = new Date();
        setData_staffInfo(newStaffInfo);
        set_isLoading(false);
    }, error => {
      set_isLoading(false);
        console.log("failed: ", error);
    });
  }

  function get_state_string()
  {
    var returnBlock = null;

    if (data_staffInfo && data_staffInfo.state && data_staffInfo.state !== "")
    {
        switch (data_staffInfo.state)
        {
            case "free":
                returnBlock = (
                    <b><Success>{Loc.duty_state_free}</Success></b>
                );
                break;

            case "busy":
                returnBlock = (
                    <b><Warning>{Loc.duty_state_busy}</Warning></b>
                );
                break;

            case "break":
                returnBlock = (
                    <b><Danger>{Loc.duty_state_break}</Danger></b>
                );
                break;

            case "off":
                returnBlock = (
                    <b><Muted>{Loc.duty_state_off}</Muted></b>
                );
                break;

            default:
                break;
        }
    }

    return returnBlock;
  }

  function get_storeName_string()
  {
    var nameToReturn = Loc.store_default;

    if (data_staffInfo !== null && data_staffInfo.state && data_staffInfo.state === "off")
    {
        nameToReturn = "-"
    }
    else if (data_storeName === "")
    {
        nameToReturn = Loc.store_default;
    }
    else{
        nameToReturn = data_storeName;
    }

    return nameToReturn;
  }

  function get_lastUpdate_string()
  {
    var stringToReturn = "-";
    if (data_staffInfo && data_staffInfo.lastDutyChangeTime)
    {
        const newDate = new Date(data_staffInfo.lastDutyChangeTime);
        const momentObj = moment(newDate);
        stringToReturn = momentObj.format("YYYY-MM-DD, HH:mm:ss");
    }
    
    return stringToReturn;
  }

    return (
    <div>
        {check_redirect()}
        {showloading()}
        <GridContainer>
        <GridItem xs={12} sm={6} >
            <Card>
            <CardHeader color="info" text>
                <CardText color="info">
                    <h4 className={classes.cardTitle}>{Loc.staff_attendance}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            {Loc.staff_name + ": "}
                                        </GridItem> 
                                        <GridItem xs={6}>
                                            {data_staffInfo === null ? "-" : data_staffInfo.itemName}
                                        </GridItem>  
                                    </GridContainer>
                                </GridItem>  

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            {Loc.staff_phone + ": "}
                                        </GridItem> 
                                        <GridItem xs={6}>
                                            {data_staffInfo === null ? "-" : data_staffInfo.phone}
                                        </GridItem>  
                                    </GridContainer>
                                </GridItem> 

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            {Loc.store_name + ": "}
                                        </GridItem> 
                                        <GridItem xs={6}>
                                            {get_storeName_string()}
                                        </GridItem>  
                                    </GridContainer>
                                </GridItem> 

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            {Loc.current_state + ": "}
                                        </GridItem> 
                                        <GridItem xs={6}>
                                            {get_state_string()}
                                        </GridItem>  
                                    </GridContainer>
                                </GridItem> 

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            {Loc.last_update + ": "}
                                        </GridItem> 
                                        <GridItem xs={6}>
                                            {get_lastUpdate_string()}
                                        </GridItem>  
                                    </GridContainer>
                                </GridItem> 

                            </GridContainer>
                            
                        </CardBody>
                    </Card>
                    </GridItem>

                    {showHide_onDutyPart()}


                    {showHide_dutyStatePart()}
                </GridContainer>
            </CardBody>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}