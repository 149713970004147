import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { v4 as uuidv4 } from 'uuid';

export default function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  let fileInput = React.createRef();
  React.useEffect(() => {  
    // pre load data for edit
  if (props.imageUrl != null && props.imageUrl != "" && file == null)
  {
      setImagePreviewUrl(props.imageUrl);
  }
  else{
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  }
  // Specify how to clean up after this effect:
  return function cleanup() {
    
  };
});
  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }

    if (props.imageDidSelect)
    {
      props.imageDidSelect();
    }
    
    // handle upload
    handleSubmit(e);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
    let newFile = e.target.files[0];
    var new_uuid = uuidv4();
    var fileName_org_array = newFile.name.split(".");
    var fileExtention = fileName_org_array[fileName_org_array.length - 1];
    var fileName = new_uuid + "." + fileExtention;
    var fileType = newFile.type;
    var targetFolder = props.targetFolder;

    fetchAPI.do_uploadToCloud(newFile, targetFolder, fileName, fileType)
    .then((downloadUrl) => 
    {
      // console.log("upload success, downloadUrl: ", downloadUrl);
      props.imageDidUpload(downloadUrl);
    }, error => 
    {
        console.log("upload failed: ", error);
        props.imageDidUpload(null);
    })
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? Loc.choose_picture : Loc.choose_picture}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              {Loc.choose_picture_change}
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> {Loc.choose_picture_remove}
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
