import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";

import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Muted from "components/Typography/Muted.js"
import Success from "components/Typography/Success.js"
import Warning from "components/Typography/Warning.js"
import Danger from "components/Typography/Danger.js"

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import Refresh from "@material-ui/icons/Refresh";
import moment from 'moment';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);


export default function Staff_onDuty_list() {
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  }
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);

  React.useEffect(() => {  
    // do_loadStaffOnDutyList();


    if (!data_isLoaded)
    {
      set_isLoaded(true);
      // do_loadDataList();
      do_loadStaffOnDutyList();
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [data_dataList]);

  function do_loadStaffOnDutyList()
  {
    set_isLoading(true);
    const body = {

    }
    fetchAPI.do_fetch('get', 'admin/list-staff-onDuty', body)
    .then((res) => {
        set_isLoading(false);
        console.log("get all staff success: ", res.data);
        process_staff_data(res.data);
        
    }, error => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function process_staff_data(staffData)
  {
    var processedStaffData = [];

    staffData.map((staff, k) => {
      const dutyStoreID = staff.dutyStoreID;
      const dutyStoreName = (staff.dutyStoreName === "default") ? Loc.store_default : staff.dutyStoreName;

      // check and create store item
      var storeIndex = -1;
      processedStaffData.map((s, k) => {
        if (s.id === dutyStoreID)
        {
          storeIndex = k;
        }
      })
      if (storeIndex === -1)
      {
        const storeItem = {
          "id": dutyStoreID,
          "storeName": dutyStoreName,
          "staffList": [],
        };
        processedStaffData.push(storeItem);
        storeIndex = processedStaffData.length  -1;
      }

      // add staff item
      processedStaffData[storeIndex].staffList.push(staff);
    });

    console.log("processedStaffData: ", processedStaffData);

    setData_dataList(processedStaffData);
  }

  function render_each_store_dutyList()
  {
    if (data_dataList !== null && data_dataList.length !== 0)
    {
      const storesList = data_dataList.map((storeData, k_store) => {
        return(
          <GridContainer key={storeData.id}>
          <GridItem xs={12}>
            {render_storeDiv(storeData)}
          </GridItem>
        </GridContainer>
        )
      });

      return(
        <GridContainer>
        <GridItem xs={12}>
          {storesList}
        </GridItem>
      </GridContainer>
      )
    }
    else
    {
      return (
        div_list_empty()
      );
    }
  }

  function get_staff_state(targetState)
  {
    var stateDiv = null;

    if (targetState === "off")
    {
      stateDiv = (<Muted>{Loc.duty_state_off}</Muted>)
    }
    else if (targetState === "free")
    {
      stateDiv = (<Success>{Loc.duty_state_free}</Success>)
    }
    else if (targetState === "busy")
    {
      stateDiv = (<Warning>{Loc.duty_state_busy}</Warning>)
    }
    else if (targetState === "break")
    {
      stateDiv = (<Danger>{Loc.duty_state_break}</Danger>)
    }

    return stateDiv;
  }

  function get_phoenLink(targetPhoneNum)
  {
    return ("tel:" + targetPhoneNum);
  }

  function render_storeDiv(storeData)
  {
    console.log("storeData: ", storeData);
    const staffList = storeData.staffList.map((staffData, k_staff) => {
      return (
        <GridItem xs={12} sm={4} key={staffData.id}>
          <Card>
          <CardBody>
            <GridContainer>
            
            <GridItem xs={12}>
              <Muted>{Loc.last_update}</Muted>
              <Muted>{moment(new Date(staffData.lastDutyChangeTime)).format("YYYY-MM-DD, HH:mm:ss")}</Muted>
            </GridItem>
            <GridItem xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <h4><b>{staffData.itemName}</b></h4>
            </GridItem>
            <GridItem xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <a href={get_phoenLink(staffData.phone)}>{staffData.phone}</a>
            </GridItem>
            <GridItem xs={12}>
              <hr/>
            </GridItem>
            <GridItem xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              {get_staff_state(staffData.state)}
            </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        </GridItem>
      )
    })

    return (
      <Card>
        <CardHeader color="info" text>
          <CardText color="info">
           <h5>{(storeData.storeName !== "") ? storeData.storeName : Loc.store_default}</h5>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
          {staffList}
        </GridContainer>
        </CardBody>
      </Card>
    )
  }

  function do_deleteData(targetID)
  {
    const body = {
      "idList": [targetID],
    }

    fetchAPI.do_fetch('post', 'admin/del-staff', body)
    .then((res) => {
        console.log("success: ", res.data);
        do_loadStaffOnDutyList();
        showAlert(true, Loc.success, Loc.data_delete_success);
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_delete_failed);
    })
  }

  
  function showConfirmDeleteAlert(customerID){
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_delete_title}
        onConfirm={() => {
          hideAlert();
          do_deleteData(customerID);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={Loc.confirm_delete_confirm}
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "")
    {
      if (itemToEdit != null)
      {
        return (<Redirect to={{
          pathname: pathToRedirect,
          state: itemToEdit
        }} />);
      }
      else
      {
        return (<Redirect to={pathToRedirect} />);
      }
    }
    else
    {
        return null;
    }
}

const div_list_empty = () => {
  const theDiv =  <GridContainer><GridItem xs={12} align="center"><Muted><h4>{Loc.no_staff_on_duty}</h4></Muted></GridItem></GridContainer>
  return theDiv;
}


const showloading = () => {
  if (isLoading)
  {
    return (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.loading}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
        showConfirm={false}
        showCancel={false}
      >
        {Loc.please_wait}
      </SweetAlert>
    )
  }
  else
  {
    return null;
  }
}

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{Loc.staff_onDuty_list}</h4>
          </CardHeader>
          <CardBody>
          <Button color="success" round onClick={() => do_loadStaffOnDutyList()}>
              <Refresh />
              {Loc.refresh}
          </Button>

          {render_each_store_dutyList()}
            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
