/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import routes from "routes.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_checkbox = makeStyles(styles_checkbox);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import SwitchUI from "@material-ui/core/Switch";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import allConfigsList from "allConfigs";
import DeleteIcon from '@material-ui/icons/Delete';

export default function System_config(props) {
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_adminLevelName, setData_adminLevelName] = React.useState("");
  const [adminLevelNameState, setAdminLevelNameState] = React.useState("");

  //config
  const [data_configList, setData_configList] = React.useState({});
  const [data_configCheckList, setData_configCheckList] = React.useState(null);

  const [pathToRedirect, setRedirect] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_checkbox = useStyles_checkbox();

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  //Switch
  //   const [data_hasDuration, setData_hasDuration] = React.useState(false);
  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [isLoading, set_isLoading] = React.useState(false);
  
  React.useEffect(() => {
    // init config item list
    const allConfigList = getConfigs(allConfigsList,[],"has-duration");
    var defaultConfigList = {};
    allConfigList.map((p) => {
      defaultConfigList[p.key] = false;
  });
    setData_configList(defaultConfigList);
    setData_configCheckList(configCheckList(allConfigList,defaultConfigList));

    //get config list
    getConfigList(allConfigList)
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, []);

  const doneClicked = () => {
    setFetchingStatus("fetching");
    const idToPass = isEdit ? data_id : null;
    const body = {
      id: idToPass,
      content: {
        itemName: data_adminLevelName,
        configList: data_configList,
      },
    };
    fetchAPI.do_fetch("post", "admin/set-system-config", body).then(
      (res) => {
        console.log("success: ", res);
        setFetchingStatus("");
        showAlert(true, Loc.success, Loc.data_save_success);
      },
      (error) => {
        console.log("failed: ", error);
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
      }
    );
  };

  const getConfigList = (allConfigList) => {
    set_isLoading(true);
    const body = {
      "page":data_page,
      "pageCount":data_pageCount,
    }
    fetchAPI.do_fetch('post', 'admin/list-system-config', body)
    .then((res) => {
        set_isLoading(false);
        if(res.data){
        var configList = res.data.configList
        setData_configList(configList);
        setData_configCheckList(configCheckList(allConfigList, configList));
        }
      }, error => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };
  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            setData_adminLevelName("");
            setData_configList({});
            setAdminLevelNameState("");
            hideAlert()
            // setRedirect("/admin/admin-permission-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_adminLevelName("");
            setData_configList({});
            setAdminLevelNameState("");
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={pathToRedirect} />;
    } else {
      return null;
    }
  };

  const getConfigs = (allConfigs, returnArray) => {
    var newReturnArray = returnArray;
    allConfigs.map((prop, key) => {
        const item = {
            key: prop.key,
            name: prop.name,
        }
        newReturnArray.push(item);
    });

    return newReturnArray;
  };

  const handleSwitchConfig = (targetSwitch, configList,allConfigList) => {
    var newList = configList;   
    // console.log(`configListconfigListconfigList  ${JSON.stringify(newList)}`)
    newList[targetSwitch] = newList[targetSwitch] === true ? false : true;
    setData_configList(newList);
    setData_configCheckList(configCheckList( allConfigList,configList));
  };

  const configCheckList = (allConfigList,configList) => {
    const checkboxList = allConfigList.map((r, k_r) => {
      return (
        <div
          key={k_r}
          className={
            classes_checkbox.checkboxAndRadio +
            " " +
            classes_checkbox.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <SwitchUI
                checked={configList[r.key]}
                onChange={() => {
                  handleSwitchConfig(r.key,configList,allConfigList);
                }}
                // value={FALSE}
                classes={{
                  switchBase: classes_switch.switchBase,
                  checked: classes_switch.switchChecked,
                  thumb: classes_switch.switchIcon,
                  track: classes_switch.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={r.name}
          />
         
        </div>
      );
    });
    return (
      <div>
        <h5>
          <small>{Loc.system_config_switch_title}</small>
        </h5>
        {checkboxList}
        <Button
              justIcon
              round
              simple
              onClick={() => {
                localStorage.clear()
              }}
              color="warning"
              className="edit"
            >
              {" "}<DeleteIcon/>{"Local"}
            </Button>
      </div>
    );
  };

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.system_config}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  {/* Admin level name */}
                  <GridItem xs={6}>
                    <GridContainer>
                      {/* Admin permission list */}
                      <GridItem xs={12}>{data_configCheckList}</GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {Loc.save}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
