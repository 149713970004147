/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import moment from 'moment';

import Dropdown from 'views/CommonUseComponents/Dropdown.js';

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_DELETE from "@material-ui/icons/Clear";


const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import Success from "components/Typography/Success";
import Warning from "components/Typography/Warning";
import Danger from "components/Typography/Danger";
import Muted from "components/Typography/Muted";

export default function CustomerPage(props) {
    
    const [pathToRedirect, setRedirect] = React.useState("");
    const [fetchingStatus, setFetchingStatus] = React.useState("");
    const [data_staffInfo, setData_staffInfo] = React.useState(null);
    const [data_storeList, setData_storeList] = React.useState(null);

    const [data_storeID, setData_storeID] = React.useState("");
    const [data_storeName, setData_storeName] = React.useState("");

    const [data_customerCode, setData_customerCode] = React.useState("");
    const [data_customerPhone, setData_customerPhone] = React.useState("");

    const [totalAmount,setTotalAmount] = React.useState(0)
    const [cartList,setCartList] = React.useState([])
    const [productList,setProductList] = React.useState([])
    const [packageList,setPackageList] = React.useState([])

    const [isLoading, set_isLoading] = React.useState(false);
    const [data_customID, setData_customID] = React.useState("");
    const [data_customName, setData_customName] = React.useState("");
  const [data_imageUrl, setData_imageUrl] = React.useState("");

    // alert
    const [alert_info, setAlert_info] = React.useState(null);
    const [alert_confirm, setAlert_confirm] = React.useState(null);
  const [alert_save, setSaveAlert] = React.useState(null);

    const hideAlert = () => {
        setAlert_info(null);
        setAlert_confirm(null);
        setSaveAlert(null);

    }

    const classes = useStyles();
    const classes_alert = useStyles_alert();

  React.useEffect(() => {  

    // get_staffInfo();
    // do_loadStoreList();

    do_loadProductList()
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [cartList,totalAmount]);


  

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect to={pathToRedirect} />);
      }
      else
      {
          return null;
      }
  }
  function calculateAmount(){
      var _totalAmount = 0
    cartList.map(data=>{
        _totalAmount += parseFloat(data.price)
    })

    setTotalAmount(_totalAmount)

  }

  function removeProduct(
    index
  ) {
    var newCartList = cartList
    newCartList.splice(index, 1);
    setCartList([...newCartList]);

  }

  const imageDidUpload = (pathInStorage) => {
    if (pathInStorage != null) {
      setData_imageUrl(pathInStorage);
      console.log("imageDidUpload called, path: ", pathInStorage);
    }
  };

  function do_loadProductList()
  {
    set_isLoading(true);
    const body = {

    }
    fetchAPI.do_fetch_withoutTokenID('post', 'customer/get_product_list', body)
    .then((res) => {
        set_isLoading(false);
            // console.log("success: ", JSON.stringify(res.data));
            setProductList(res.data.productList);
            setPackageList(res.data.packageList);

    }, error => {
      set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function do_submit()
  {
    set_isLoading(true);
    const body = {
        id: null,
        content: {
          customerCode:data_customerCode,
          customerPhone:data_customerPhone,
          cartList:cartList,
          amount:totalAmount,
          image_url:data_imageUrl
        },
      };
    fetchAPI.do_fetch_withoutTokenID('post', 'customer/createOrder', body)
    .then((res) => {
        set_isLoading(false);
            // console.log("success: ", JSON.stringify(res.data));
        showAlert(true, Loc.success, Loc.data_save_success);
        
    }, error => {
      set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()

            setTotalAmount(0)
        setData_customerPhone("")
        setData_imageUrl("")
        setData_customerCode("")
        setCartList([])
            hideAlert()

          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}

        
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        btnSize="lg"
        showCancel={false}
      >
        {content}
      </SweetAlert>
    );
  }

  const showloading = () => {
    if (isLoading)
    {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      )
    }
    else
    {
      return null;
    }
  }


  function isProduct(productID){
    var  result = false
    for(var i = 0; i<productList.length;i++){
      const data = productList[i]
      if(data.id == productID){
        result = true
        break
      }
    }
    return result
}

  function getProductData(productID){
      for(var i = 0; i<productList.length;i++){
        const data = productList[i]
        if(data.id == productID){
            return data
            break
        }
      }
  }

  function getPackageData(productID){
    for(var i = 0; i<packageList.length;i++){
      const data = packageList[i]
      if(data.id == productID){
          return data
          break
      }
    }
}

  const getSelectMenuItem = () => {
    var menuItems = [];

      var newProductList = [];

      var newPackageList = [];

      menuItems.push(
        <MenuItem
          disabled
          key={Loc.type_product}

          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {Loc.type_product}
        </MenuItem>
      );

      productList.map((item,key)=>{
        menuItems.push(
          <MenuItem
            key={'product'+key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );

      });


      menuItems.push(
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {Loc.type_package}
        </MenuItem>
      );
      packageList.map((item,key)=>{
        menuItems.push(
          <MenuItem
            key={'package'+key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );

      });
    

    return menuItems;
  };

  const renderProductCells = (data,index) =>{
    var newCartDataList = cartList;
    var newCartData = cartList[index];

    const {productID} = newCartData
    const productData = isProduct(productID) ? getProductData(productID):getPackageData(productID)

    return (
        <>
        <FormControl
        fullWidth
        className={
          classes.selectFormControl + " " + classes.formControlMargins
        }
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          {Loc.select_category}
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={productID}
          onChange={(event) => {
            const selectedID = event.target.value;
            const _productData = isProduct(selectedID) ? getProductData(selectedID):getPackageData(selectedID)
            // console.log("productData  " + JSON.stringify(_productData));

            newCartDataList[index].productID = selectedID
            newCartDataList[index].productName = _productData.itemName
            newCartDataList[index].price = _productData.price
            setCartList([...newCartDataList])
            calculateAmount()
          }}
          inputProps={{
            name: "",
            id: productID,
          }}
        >
          {getSelectMenuItem()}
        </Select>
        
      </FormControl>
      <GridItem xs={2}>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
                removeProduct(index)
            }}
          >
            <ICON_DELETE />
          </IconButton>
          </GridItem>
      </>
    )
  }

  const renderCartList = () =>{
    var newCartList = cartList;
    // var data = cartList[index];
    const ui_gridItems = cartList.map((data,index)=>{
        return renderProductCells(data,index)

    })

    console.log('newCartList  '+JSON.stringify(newCartList))

    return (
        <Card>
          <CardBody>
            <GridContainer>{ui_gridItems}</GridContainer>
            <Button
              color="success"
              round
              onClick={() => {
                newCartList.push({ productID:"",productName:"",price:0});

                setCartList([...newCartList])
              }}
            >
              <ICON_ADD />
            </Button>
          </CardBody>
        </Card>
      );
  }

    return (
    <div>
        {/* {check_redirect()} */}
        {showloading()}
        {alert_save}
        <GridContainer>
        <GridItem xs={12}  >
            <Card>
            <CardHeader color="info" text>
                <CardText color="info">
                    <h4 className={classes.cardTitle}>{Loc.staff_attendance}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <GridContainer>
                <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      error={""}
                      labelText={Loc.customer_id + "*"}
                      id="customerCode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_customerCode,
                        onChange: (event) => {
                        //   setproductNameState("success");
                          setData_customerCode(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      error={""}
                      labelText={Loc.customer_phone + "*"}
                      id="customerPhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_customerPhone,
                        onChange: (event) => {
                        //   setproductNameState("success");
                          setData_customerPhone(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>

                 
                    <GridItem xs={12}>
                    
                
        {renderCartList()}
                 
                    </GridItem>

                </GridContainer>
                <GridItem xs={12} align="right"><Muted><h4>{Loc.total+': $'+totalAmount}</h4></Muted></GridItem>

                <GridItem xs={12} align="center">
                    <ImageUpload
                      imageDidUpload={(res) => imageDidUpload(res)}
                      imageUrl={data_imageUrl}
                      targetFolder="SimpleOrder"
                    />
                  </GridItem>
               
                <GridItem xs={12} align="center">
                  <Button
                    color="rose"
                    onClick={()=>{

                        do_submit()
                    }}
                    disabled={false}
                  >
                    <ICON_DONE />
                    {Loc.create}
                  </Button>
                </GridItem>
            </CardBody>
            </Card>
        </GridItem>
        
        </GridContainer>
    </div>
  );
}