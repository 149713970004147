import React from "react";
import { Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";

import NavPills from "components/NavPills/NavPills.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Info from "components/Typography/Info.js";
import FormControl from "@material-ui/core/FormControl";
import Table from "components/Table/Table.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_SEARCH from "@material-ui/icons/Search";
import { FormatAlignRight } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

import Dropdown from "views/CommonUseComponents/Dropdown.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Recover from "@material-ui/icons/Replay";

import { saveAs } from "file-saver";
import moment from 'moment';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);

export default function Customer_list(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setConfirmAlert(null);
  };

  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);

  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_switch = useStyles_switch();
  const classes_tableBtn = useStyles_tableBtn();

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);

  const [data_strSearchKey, set_strSearchKey] = React.useState("");

  const [locationState, setLocationState] = React.useState(null);
  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);
  // const [data_searchItemName, setData_searchItemName] = React.useState("");

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());

  React.useEffect(() => {
    if (locationState == null) {
      setLocationState(props.location.state);
    } else {
      if (data_dataList != null) {
        processDataWithActions();
      }

      if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
        // set_isLoaded(true);
        // do_loadDataList();
      }
    }

    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [
    data_pageCount,
    data_page,
    data_dataList,
    locationState,
    showDeletedData,
  ]);

  var xls = require("exceljs");

  async function operation() {
    var workbook = new xls.Workbook();
  
    workbook.created = new Date();
  
    workbook.modified = new Date();
  
    const worksheet = workbook.addWorksheet("sheet");
  
    //calcualte part
  
    // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
    // //merge cell
    // worksheet.mergeCells("A1:"+headerEndColumn+"1");
    // worksheet.mergeCells("A2:"+headerEndColumn+"2");
  
    worksheet.columns = getExcelColumns()
  
    const tableHeaderList =  tableHeader.map(item=>{
      if(item !== Loc.actions){
        return item
      }
    })
    //Fixed Items
    const rows = [
      tableHeaderList,
    ];
    // //columns
    const titleRows = worksheet.getRow(1);
  
    // // // report_itemName
    titleRows.values = tableHeaderList;
    titleRows.alignment = { vertical: "middle", horizontal: "center" };
  
  // console.log("getExcelData()   "+JSON.stringify(getExcelData()))
    // worksheet.addRows(rows);
    //add data part
    worksheet.addRows(getExcelData());
  
    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
  
    const blob = new Blob([buffer], { type: fileType });
   
    const fileName = `${Loc.customer_management}_${moment(data_startDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}~${moment(data_endDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}`;
    saveAs(blob, fileName + fileExtension);
  }
  
    function getExcelColumns(){
      var columns = []
      tableHeader.map((item)=>{
        if (item != Loc.actions)
        columns.push({ header: "", key: item, width: 20 })           
          })
      
      return columns
    }
  
    function getExcelData(){
  
      const newData = data_dataList.map((prop, key)=>{
        var genderStr = "";
        if (prop.gender === "U") {
          genderStr = Loc.secret;
        } else if (prop.gender === "F") {
          genderStr = Loc.female;
        } else if (prop.gender === "M") {
          genderStr = Loc.male;
        }

        const phone = prop.phone === "" ? "/" : prop.phone;
        const email = prop.email === "" ? "/" : prop.email;
        const remark = prop.remark === "" ? "/" : prop.remark;
        const customer_id = prop.id;
        const code_old =
          !prop.code || prop.code === "" ? Loc.no_code : prop.code;

    
        return [
          code_old,
          prop.itemName,
          genderStr,
          phone,
          email,
      ]
      })
    
      return newData
  
    } 

  function do_createDefaultCustomer() {
    return new Promise((onDone) => {
      const body = {
        id: "0",
        content: {
          itemName: Loc.default_customer,
          gender: "U",
          email: "",
          phone: "",
          remark: "",
          profileUrl: "",
        },
      };
      fetchAPI.do_fetch("post", "admin/create-customer", body).then(
        (res) => {
          console.log("create default success: ", res);
          onDone(true);
        },
        (error) => {
          console.log("create default failed: ", error);
          onDone(false);
        }
      );
    });
  }

  function do_loadDataList() {
    set_isLoading(true);
    var body = {
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: showDeletedData,
    };

    fetchAPI.do_fetch("post", "admin/list-customer", body).then(
      (res) => {
        set_isLoading(false);
        // console.log("success: ", JSON.stringify(res.data));
        // if (res.data.data_list.length === 0) {
        //   do_createDefaultCustomer().then((isCreated) => {
        //     do_loadDataList();
        //   });
        // } else {
          setData_dataList(res.data.data_list);
          setData_dataListCount(res.data.count);
        // }
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  // function do_loadDataListWithSearchKeyword() {
  //   set_isLoading(true);
  //   const body = {
  //     page: data_page,
  //     pageCount: data_pageCount,
  //     searchKeyword: data_strSearchKey,
  //     searchKey: data_searchID,
  //   };

  //   fetchAPI.do_fetch("post", "admin/list-customer", body).then(
  //     (res) => {
  //       set_isLoading(false);
  //       console.log("success: ", res.data);
  //       if (res.data.data_list.length === 0) {
  //         setData_dataList([]);
  //         setData_dataListCount(0);
  //       } else {
  //         setData_dataList(res.data.data_list);
  //         setData_dataListCount(res.data.count);
  //       }
  //     },
  //     (error) => {
  //       set_isLoading(false);
  //       console.log("failed: ", error);
  //       showAlert(false, Loc.failed, Loc.data_fetch_failed);
  //     }
  //   );
  // }

  function do_deleteData(targetID) {
    const body = {
      idList: [targetID],
      targetCollection: "Customer",
      targetID: targetID,
    };
    const path = showDeletedData
      ? "admin/recover-deleted"
      : "admin/del-customer";
    fetchAPI.do_fetch("post", path, body).then(
      (res) => {
        console.log("success: ", res.data);
        do_loadDataList();
        showAlert(
          true,
          Loc.success,
          showDeletedData ? Loc.data_recover_success : Loc.data_delete_success
        );
      },
      (error) => {
        console.log("failed: ", error);
        showAlert(
          false,
          Loc.failed,
          showDeletedData ? Loc.data_recover_failed : Loc.data_delete_failed
        );
      }
    );
  }

  function processDataWithActions() {
    const newData = data_dataList
      // .filter((v) => v.isDeleted != true)
      .map((prop, key) => {
        var genderStr = "";
        if (prop.gender === "U") {
          genderStr = Loc.secret;
        } else if (prop.gender === "F") {
          genderStr = Loc.female;
        } else if (prop.gender === "M") {
          genderStr = Loc.male;
        }

        const phone = prop.phone === "" ? "/" : prop.phone;
        const email = prop.email === "" ? "/" : prop.email;
        const remark = prop.remark === "" ? "/" : prop.remark;
        const customer_id = prop.id;
        const code_old =
          !prop.code || prop.code === "" ? Loc.no_code : prop.code;

        // "#",
        // Loc.customer_name,
        // Loc.customer_phone,
        // Loc.customer_email,
        // Loc.gender,
        // Loc.remark,
        // Loc.actions,

        return [
          code_old,
          prop.itemName,
          genderStr,
          phone,
          email,
          // remark,
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setItemToEdit(prop);
                setRedirect("/admin/customer-new");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(customer_id);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover /> : <Close />}
            </Button>{" "}
          </div>,
        ];
      });
    setData(newData);
  }

  function showConfirmDeleteAlert(customerID) {
    setConfirmAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={
          showDeletedData ? Loc.confirm_recover_title : Loc.confirm_delete_title
        }
        onConfirm={() => {
          hideAlert();
          do_deleteData(customerID);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={
          showDeletedData
            ? Loc.confirm_recover_confirm
            : Loc.confirm_delete_confirm
        }
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      const state = {
        data: itemToEdit,
        config: locationState,
      };
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: state,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const _renderSearchBar = () => {
    const searchFieldList = [
      {
        itemName: Loc.code_old,
        id: "code",
        key: ["code"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.customer_name,
        id: "itemName",
        key: ["itemName"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.gender,
        id: "gender",
        key: ["gender"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.customer_phone,
        id: "phone",
        key: ["phone"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.customer_email,
        id: "email",
        key: ["email"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: "",
        id: "divider",
        key: ["divider"],
        type: "none",
        isPreloadField: false,
      },
      {
        itemName: Loc.search_by_date,
        id: "date",
        key: ["createDate"],
        type: "date",
        isPreloadField: false,
      },
      // { itemName: Loc.multi_search,id:"date_staff",key: ["date", "staffName"], type: "multi" },
    ];

    // console.log(`searchFieldNameList  ${searchFieldNameList}   searchIDList  ${searchIDList} data_searchID : ${data_searchID}`)
    if (locationState && locationState !== null) {
      return (
        <SearchBar
          // currentSearchID={data_searchID == "" ? searchIDList[0]:data_searchID}
          searchFieldList={searchFieldList}
          // searchFieldNameList={searchFieldNameList}
          // searchIDList={searchIDList}
          apiPath={"admin/list-customer"}
          loadDataList={() => do_loadDataList()}
          searchResultDataList={(dataList) => {
            setData_dataList(dataList);
          }}
          searchResultDataCount={(count) => {
            setData_dataListCount(count);
          }}
          // didSelectedID={(id) => {
          //   setData_searchID(id);
          // }}
          showDeletedData={showDeletedData}
          locationState={locationState}
          didSelectedShowDeletedData={(_showDeletedData) => {
            setShowDeletedData(_showDeletedData);
            set_isLoaded(false);
          }}
          showExtendedTable={(bool) => {
            setShowExtendedTable(bool);
          }}
          searchPeriod={(from, to) => {
            setData_startDate(from);
            setData_endDate(to);
          }}
        />
      );
    }
  }
  const tableHeader = [
    Loc.code_old,
    Loc.customer_name,
    Loc.gender,
    Loc.customer_phone,
    Loc.customer_email,
    // Loc.remark,
    Loc.actions,
  ]

  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={tableHeader}
        tableData={data}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
      />
    );
  }

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  <Button
                    color="success"
                    round
                    onClick={() => {
                      setRedirect("/admin/customer-new");
                    }}
                  >
                    <ICON_ADD />
                    {Loc.customer_new}
                  </Button>

                  <Button
                    color="info"
                    round
                    onClick={() => {
                      var state = {
                        data: null,
                        config: locationState,
                      };
                      setItemToEdit(state);
                      setRedirect("/admin/customer-coupon");
                    }}
                  >
                    <ICON_ADD />
                    {Loc.coupon_on_hand}
                  </Button>
                  <Button
                    color="success"
                    round
                    onClick={() => {
                      operation();
                    }}
                  >
                    {Loc.download_excel}
                  </Button>
                </GridItem>
                {_renderSearchBar()}
              </GridContainer>
              {showExtendedTable
                ? _renderExtendedTable()
                : _renderPagingTable()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
