import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ChartistGraph from "react-chartist";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Timeline from "@material-ui/icons/Timeline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";
import moment from "moment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { InputLabel } from "@material-ui/core";
import Datetime from "react-datetime";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
// import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_ADD from "@material-ui/icons/Add";
import Recover from "@material-ui/icons/Replay";
import { saveAs } from "file-saver";
import ICON_SEARCH from "@material-ui/icons/Search";
// const styles = {
//   cardIconTitle: {
//     ...cardTitle,
//     marginTop: "15px",
//     marginBottom: "0px"
//   }
// };

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

export default function Payment_list(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  };
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const data_targetCollection = "Payment";

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const classes_tableBtn = useStyles_tableBtn();

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const [locationState, setLocationState] = React.useState(null);

  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());

  const [data_selectedPeriod, setData_selectedPeriod] = React.useState("");
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [data_reportList, setData_reportList] = React.useState([]);
  const [refreshData, set_refreshData] = React.useState(false);

  const [dateState_startDate, setdateState_startDate] = React.useState(true);
  const [dateState_endDate, setdateState_endDate] = React.useState(true);
  const [pieChartData, set_pieChartData] = React.useState(null);
  const [reportDetailData, setReportDetailData] = React.useState(null);
  const pieChartColorCodeList = [
    "#00acc1",
    "#f05b4f",
    "#f4c63d",
    "#d17905",
    "#453d3f",
    "#59922b",
    "#0544d3",
    "#6b0392",
    "#f05b4f",
    "#dda458",
    "#eacf7d",
    "#86797d",
    "#b2c326",
    "#6188e2",
    "#a748ca",
  ];

  const [searchData, setSearchData] = React.useState(null);

  React.useEffect(() => {
    if (locationState == null) {
      setLocationState(props.location.state);
    } else {
      // console.log("locationState.config  "+JSON.stringify(locationState))
      if (data_dataListArray === null) {
        setData_dataListArray(locationState);
      }

      if (refreshData) {
        set_refreshData(false);
        _renderPaymentReport(data_dataList);
      }

      if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
        set_isLoaded(true);
        // do_loadDataList();
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [
    data_pageCount,
    data_page,
    data_dataList,
    locationState,
    data_dataListArray,
    refreshData,
    reportDetailData,
  ]);

  // function do_deleteData(targetID)
  // {
  //   const body = {
  //     "idList": [targetID],
  //     "data_targetCollection":data_targetCollection,
  //     "targetID":targetID
  //   }

  //   const path = showDeletedData ? 'admin/recover-deleted':'admin/del-payment'
  //   fetchAPI.do_fetch('post', path, body)
  //   .then((res) => {
  //       console.log("success: ", res.data);
  //       do_loadDataList();
  //       showAlert(true, Loc.success, showDeletedData ? Loc.data_recover_success:Loc.data_delete_success);
  //   }, error => {
  //       console.log("failed: ", error);
  //       showAlert(false, Loc.failed, showDeletedData ? Loc.data_recover_failed:Loc.data_delete_failed);
  //   })
  // }

  function getStoreNameContent(startDate, endDate, paymentID, paymentName) {
    var content_storeName = null;
    const storeList = data_dataListArray["Store"];
    content_storeName = storeList.map((item) => {
      return (
        <GridItem xs={12} key={item.id}>
          {/* <Button
              simple
              onClick={() => {
                const searchData = {
                  startDate: startDate,
                  endDate: endDate,
                  searchStr: paymentName,
                  searchID: paymentID,
                  secondSearchStr: item.itemName,
                  secondSearchID: item.id,
                  id: "date_payment",
                  key: ["createDate", "paymentID","storeID"],
                  keyToShow:"itemName",
                  type: "multi",
                  preloadCollectionName:["Payment","Store"],
                  preloadDefaultName:[Loc.select_payment,Loc.select_store],
                  isPreloadField: true,
                };
                
                // console.log("searchData  in report:::::  "+JSON.stringify(searchData))
                localStorage.setItem("searchData", JSON.stringify(searchData));
                setRedirect("/admin/sales-list");
              }}
              color="primary"
              className="edit"
            > */}
          <h5>{item.itemName}</h5>
          {/* </Button> */}
        </GridItem>
      );
    });

    return <div>{content_storeName}</div>;
  }

  // function getStoreReceiveContent(startDate,endDate,paymentID,paymentName,targetData){
  //   var content_receive = null;

  //   const storeList = data_dataListArray["Store"];
  //   var dataList = targetData;

  //   content_receive = storeList.map(storeData=>{
  //     var _totalReceive = 0;
  //     dataList.map((data) => {
  //         // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
  //         if ((paymentID == data.paymentID) && (data.storeID == storeData.id)) {
  //         // console.log(`Find `)
  //         _totalReceive += data.totalReceive
  //       }
  //     });

  //     return (
  //       <GridItem xs={12} key={storeData.id}>
  //         {/* <small> */}
  //         <Button
  //               simple
  //               onClick={() => {
  //                 const searchData = {
  //                   startDate: startDate,
  //                   endDate: endDate,
  //                   searchStr: paymentName,
  //                   searchID: paymentID,
  //                   secondSearchStr: storeData.itemName,
  //                   secondSearchID: storeData.id,
  //                   id: "date_payment",
  //                   key: ["createDate", "paymentID","storeID"],
  //                   keyToShow:"itemName",
  //                   type: "multi",
  //                   preloadCollectionName:["Payment","Store"],
  //                   preloadDefaultName:[Loc.select_payment,Loc.select_store],
  //                   isPreloadField: true,
  //                 };

  //                 // console.log("searchData  in report:::::  "+JSON.stringify(searchData))
  //                 localStorage.setItem("searchData", JSON.stringify(searchData));
  //                 setRedirect("/admin/sales-list");
  //               }}
  //               color="primary"
  //               className="edit"
  //             >
  //               <h5>{parseFloat(_totalReceive).toLocaleString(
  //         undefined,
  //         {
  //           minimumFractionDigits: 1,
  //           maximumFractionDigits: 1,
  //         }
  //       )}</h5>
  //             </Button>

  //         {/* </small> */}
  //       </GridItem>
  //     );
  //     })

  //   return (
  //     <div>
  //       {content_receive}
  //     </div>
  //   );
  // }

  function getStoreReceiveNetContent(
    startDate,
    endDate,
    paymentID,
    paymentName,
    targetData
  ) {
    var content_receive_net = null;

    const storeList = data_dataListArray["Store"];
    var dataList = targetData;

    content_receive_net = storeList.map((storeData) => {
      var _totalReceiveNet = 0;
      dataList.map((data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == data.paymentID && data.storeID == storeData.id) {
          // console.log(`Find `)
          _totalReceiveNet += data.totalReceive_net;
        }
      });

      return (
        <GridItem xs={12} key={storeData.id}>
          {/* <small> */}
          <Button
            simple
            onClick={() => {
              const searchData = {
                startDate: startDate,
                endDate: endDate,
                searchStr: paymentName,
                searchID: paymentID,
                secondSearchStr: storeData.itemName,
                secondSearchID: storeData.id,
                id: "date_payment",
                key: ["createDate", "paymentID", "storeID"],
                keyToShow: "itemName",
                type: "multi",
                preloadCollectionName: ["Payment", "Store"],
                preloadDefaultName: [Loc.select_payment, Loc.select_store],
                isPreloadField: true,
              };

              // console.log("searchData  in report:::::  "+JSON.stringify(searchData))
              localStorage.setItem("searchData", JSON.stringify(searchData));
              setRedirect("/admin/sales-list");
            }}
            color="primary"
            className="edit"
          >
            <h5>
              {parseFloat(_totalReceiveNet).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })}
            </h5>
            </Button>

          {/* </small> */}
        </GridItem>
      );
    });

    return <div>{content_receive_net}</div>;
  }

  function getStoreReceivable(
    paymentID,
    targetData
  ) {
    var _totalReceivable = 0;

    // const storeList = data_dataListArray["Store"];
    var dataList = targetData;

    // storeList.map((storeData) => {
      dataList.map((data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == data.paymentID && data.type == "-") {
          // console.log(`Find `)
            _totalReceivable += parseFloat(data.amount);
        }
      });
    // });
    return _totalReceivable
  }

  function getStoreReceivableContent(
    startDate,
    endDate,
    paymentID,
    paymentName,
    targetData
  ) {
    var content_receive_net = null;

    const storeList = data_dataListArray["Store"];
    var dataList = targetData;

    content_receive_net = storeList.map((storeData) => {
    var _totalReceivable = 0;

      dataList.map((data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == data.paymentID && data.storeID == storeData.id && data.type == "-") {
          console.log(`${data.debtorName} amount ${data.amount} `)

          _totalReceivable += parseFloat(data.amount);
        }
      });

      return (
        <GridItem xs={12} key={storeData.id}>
          {/* <small> */}
          <Button
            simple
            onClick={() => {
              const searchData = {
                startDate: startDate.getTime(),
                endDate: endDate.getTime(),
                searchStr: paymentName,
                searchID: paymentID,
                secondSearchStr: storeData.itemName,
                secondSearchID: storeData.id,
                id: "date_payment",
                key: ["createDate", "paymentID", "storeID"],
                keyToShow: "itemName",
                type: "multi",
                preloadCollectionName: ["Payment", "Store"],
                preloadDefaultName: [Loc.select_payment, Loc.select_store],
                isPreloadField: true,
                needFilterType:true,
              };

              // console.log("searchData  in report:::::  "+JSON.stringify(searchData))
              localStorage.setItem("searchData", JSON.stringify(searchData));
              setRedirect("/admin/receivable-list");
            }}
            color="primary"
            className="edit"
          >
            <h5>
              {parseFloat(_totalReceivable).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })}
            </h5>
          </Button>

          {/* </small> */}
        </GridItem>
      );
    });

    return <div>{content_receive_net}</div>;
  }

  function getAmount(
    startDate,
    endDate,
    paymentID,
    paymentName,
    saleDataList,
    receivableDataList
  ) {
    var content_amount = null;
    var total = 0;
    const storeList = data_dataListArray["Store"];
    // var dataList = targetData;
    
    content_amount = storeList.map((storeData) => {
    var _totalAmount = 0;
    var _totalReceivable = 0;
    var _totalReceiveNet = 0;
      //net + receivable => amount
      saleDataList.map((data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == data.paymentID && data.storeID == storeData.id) {
          // console.log(`Find `)
          _totalReceiveNet += parseFloat(data.totalReceive_net);
        }
      });

      receivableDataList.map((r_data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == r_data.paymentID && r_data.storeID == storeData.id && r_data.type == "-") {
          // console.log(`Find `)
          _totalReceivable += parseFloat(r_data.amount);
        }
      })


      _totalAmount = parseFloat(_totalReceiveNet) + parseFloat(_totalReceivable);
      total += _totalAmount;
    });

    return total
  }
  
  function getAmountContent(
    startDate,
    endDate,
    paymentID,
    paymentName,
    saleDataList,
    receivableDataList
  ) {
    var content_amount = null;

    const storeList = data_dataListArray["Store"];
    // var dataList = targetData;
    
    content_amount = storeList.map((storeData) => {
    var _totalAmount = 0;
    var _totalReceivable = 0;
    var _totalReceiveNet = 0;
      //net + receivable => amount
      saleDataList.map((data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == data.paymentID && data.storeID == storeData.id) {
          // console.log(`Find `)
          // _totalReceiveNet += parseFloat(data.totalReceive_net);
          _totalReceiveNet += isNaN(parseFloat(data.totalReceive_net)) ? 0:parseFloat(data.totalReceive_net);
        }
      });

      receivableDataList.map((r_data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (paymentID == r_data.paymentID && r_data.storeID == storeData.id && r_data.type == "-") {
          // console.log(`Find `)
          _totalReceivable += parseFloat(r_data.amount);
        }
      })


      _totalAmount = _totalReceiveNet + _totalReceivable;
      return (
        <GridItem xs={12} key={storeData.id}>
          {/* <small> */}
          
            <h5>
              {parseFloat(_totalAmount).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })}
            </h5>

          {/* </small> */}
        </GridItem>
      );
    });

    return <div>{content_amount}</div>;
  }

  function getPayment(paymentName, paymentID, targetData) {
    var _totalPayment = 0;
    var _totalReceiveNet = 0;
    //receive with store name
    // console.log("getPayment  "+JSON.stringify(data_dataList))
    var dataList = targetData;
    dataList.map((data) => {
      if (paymentID == data.paymentID) {
        _totalPayment += 1;
        _totalReceiveNet += data.totalReceive_net;
      }
    });
    var data = {
      paymentName: paymentName,
      paymentID: paymentID,
      total: _totalPayment,
      totalReceiveNet: _totalReceiveNet,
    };
    return data;
  }

  function getPaymentsList(targetData) {
    var newDataList = [];
    const paymentMethodList = data_dataListArray["Payment"];
    paymentMethodList.map((item) => {
      newDataList.push(getPayment(item.itemName, item.id, targetData));
    });

    // console.log("getTotalPaymentList   "+JSON.stringify(newDataList))
    return newDataList;
  }

  function getTotalPayment(targetData) {
    var total = 0;
    const paymentMethodList = getPaymentsList(targetData);
    paymentMethodList.map((item) => {
      total += item.total;
    });

    // console.log("getTotalPaymentList   "+JSON.stringify(newDataList))
    return total;
  }

  // function getTotalReceiveAmount(targetData) {
  //   var total = 0;
  //   var dataList = targetData;
  //   dataList.map((data) => {
  //     total += data.totalReceive;
  //   });
  //   // console.log("getTotalPaymentList   "+JSON.stringify(newDataList))
  //   return total;
  // }


  function getAllTotalAmount(
    saleDataList,
    receivableDataList
  ) {
    var content_amount = null;
    // var total = 0;
    // var dataList = targetData;
    
    var _totalAmount = 0;
    var _totalReceivable = 0;
    var _totalReceiveNet = 0;
      //net + receivable => amount
      saleDataList.map((data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
          _totalReceiveNet += isNaN(parseFloat(data.totalReceive_net)) ? 0:parseFloat(data.totalReceive_net);
          // console.log(`_totalReceiveNet ${parseFloat(data.totalReceive_net)}`)
        
      });

      receivableDataList.map((r_data) => {
        // console.log(`PaymentID ${paymentID} ${data.paymentID}  storeID:  ${storeData.id} ${data.storeID}`)
        if (r_data.type == "-") {
          _totalReceivable += parseFloat(r_data.amount);
        }
      })
        // console.log(`_totalReceiveNet ${_totalReceiveNet} _totalReceivable:  ${_totalReceivable}`)

      _totalAmount = parseFloat(_totalReceiveNet) + parseFloat(_totalReceivable);
 
      // if (isNaN(_totalAmount)){
      //   console.log(`_totalAmount is NANNNNNNN`)

      // }
    return _totalAmount
  }

  function roundValue(value) {
    return Math.floor(value * 100) / 100;
  }

  function processDataWithActions(startDate, endDate, targetData,receivableData) {
    // console.log("ready to set data  "+JSON.stringify(getTotalPaymentList()))

    var pieLabelData = [];
    var pieSeriesData = [];
    var _colorCodes = [];

    var _totalReceiveNetAmount = 0;
    var _totalReceivableAmount = 0;
    var _totalAmount = 0;

    var newData = getPaymentsList(targetData)
      // .filter(v => v.isDeleted != true)
      .sort((a, b) => b.totalReceiveNet - a.totalReceiveNet)
      .map((prop, key) => {
        const itemName = prop.paymentName;
        const id = prop.paymentID;
        const total = prop.total;
        const totalReceiveNet = parseFloat(prop.totalReceiveNet);
        _totalReceiveNetAmount += totalReceiveNet;

        const totalReceiveNetContent = getStoreReceiveNetContent(
          startDate,
          endDate,
          id,
          itemName,
          targetData
        );

        const totalReceivableAmount = getStoreReceivable(id,receivableData)
        _totalReceivableAmount += totalReceivableAmount
        const totalReceivableContent = getStoreReceivableContent(
          startDate,
          endDate,
          id,
          itemName,
          receivableData
        );
        const totalAmount = getAmount(
          startDate,
          endDate,
          id,
          itemName,
          targetData,
          receivableData
        );

        const totalAmountContent = getAmountContent(
          startDate,
          endDate,
          id,
          itemName,
          targetData,
          receivableData
        );
         const totalAllAmount = getAllTotalAmount(targetData,receivableData);
        
        // console.log("totalAmount:  "+totalAmount+" "+totalAllAmount)
        const percentage = isNaN(
          roundValue((totalAmount / totalAllAmount) * 100)
        )
          ? 0
          : roundValue((totalAmount / totalAllAmount) * 100);

        if (totalReceiveNet !== 0) {
          pieLabelData.push(itemName + "\n" + percentage + "%");
          pieSeriesData.push(percentage);
          _colorCodes.push({
            code: pieChartColorCodeList[key],
            name: itemName,
          });
        }

        return [
          <div>
            {/* use this button to add a edit kind of action */}
            {/* <Button
              simple
              onClick={() => {
                const searchData = {
                  startDate: startDate,
                  endDate: endDate,
                  searchStr: itemName,
                  searchID: id,
                  id: "date_payment",
                  key: ["createDate", "paymentID","storeID"],
                  keyToShow: "itemName",
                  type: "multi",
                  preloadCollectionName: ["Payment"],
                  preloadDefaultName:[Loc.select_payment],
                  isPreloadField: true,
                };
                
                // console.log("searchData  in report:::::  "+JSON.stringify(searchData))
                localStorage.setItem("searchData", JSON.stringify(searchData));
                setRedirect("/admin/sales-list");
              }}
              color="primary"
              className="edit"
            > */}
            <h5>{itemName}</h5>
            {/* </Button>{" "} */}
            {/* use this button to remove the data row */}
          </div>,
          <h5>{total}</h5>,
          getStoreNameContent(startDate, endDate, id, itemName),
          totalReceiveNetContent,
          totalReceivableContent,
          totalAmountContent,
          <h5>{percentage}</h5>,
        ];
      });
    const totalAmountRow = [
      "",
      <h5>{getTotalPayment(targetData)}</h5>,
      "",
      <b>
        <h5>
          {Loc.payments_total_receive_net +
            ": " +
            parseFloat(_totalReceiveNetAmount).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }
            )}
        </h5>
      </b>,
      <b>
      <h5>
        {Loc.payments_total_repayment_amount +
          ": " +
          parseFloat(_totalReceivableAmount).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }
          )}
      </h5>
    </b>,
    <b>
    <h5>
      {
        parseFloat(getAllTotalAmount(targetData,receivableData)).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }
        )}
    </h5>
  </b>
      ,
      "",
    ];
    newData.push(totalAmountRow);
    newData.unshift(totalAmountRow);

    setData(newData);

    const _pieChart = {
      data: {
        labels: pieLabelData,
        series: pieSeriesData,
      },
      options: {
        height: "500px",
        labelInterpolationFnc: function (value) {
          return value[0];
        },
      },
      responsiveOptions: [
        [
          "screen and (min-width: 640px)",
          {
            chartPadding: 30,
            labelOffset: 100,
            labelDirection: "explode",
            labelInterpolationFnc: function (value) {
              return value;
            },
          },
        ],
        [
          "screen and (min-width: 1024px)",
          {
            labelOffset: 80,
            chartPadding: 100,
          },
        ],
      ],
      colorCodes: _colorCodes,
    };

    set_pieChartData(_pieChart);
  }

  // function showConfirmDeleteAlert(targetID){
  //   setAlert_confirm(
  //     <SweetAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       closeOnClickOutside={false}
  //       title={showDeletedData ? Loc.confirm_recover_title:Loc.confirm_delete_title}
  //       onConfirm={() => {
  //         hideAlert();
  //         do_deleteData(targetID);
  //       }}
  //       onCancel={() => hideAlert()}
  //       confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
  //       cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
  //       confirmBtnText={showDeletedData ? Loc.confirm_recover_confirm:Loc.confirm_delete_confirm}
  //       cancelBtnText={Loc.cancel}
  //       showCancel
  //     >
  //       {Loc.are_you_sure}
  //     </SweetAlert>
  //   );
  // }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (locationState != null) {
        return (
          <Redirect to={{ pathname: pathToRedirect, state: locationState }} />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };
  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const tableHeader = [
    Loc.payment_name,
    Loc.total,
    Loc.store_name,
    Loc.sales_amount_net_receive,
    Loc.repayment_amount,
    Loc.payments_total_amount,
    "%",
  ];

  function get_dropdownTextToShow() {
    switch (data_selectedPeriod) {
      case "":
        return Loc.select_period;
      case "today":
        return Loc.today;
      case "week":
        return Loc.this_week;
      case "month":
        return Loc.this_month;
      case "year":
        return Loc.this_year;
      case "other":
        return Loc.select_custom_period;
      default:
        return Loc.select_period;
    }
  }

  const handle_fast_search = (selected) => {
    switch (selected) {
      case "week":
        const monday = moment().startOf("isoWeek").toDate();
        const sunday = moment().endOf("isoWeek").toDate();

        setData_startDate(monday);
        setData_endDate(sunday);
        do_loadReportInPeriod(monday, sunday);

        // console.log("monday: ", monday, "\nsunday; ", sunday);
        break;
      case "month":
        const start_month = moment().startOf("month").toDate();
        const end_month = moment().endOf("month").toDate();

        setData_startDate(start_month);
        setData_endDate(end_month);
        do_loadReportInPeriod(start_month, end_month);
        // console.log("start_month: ", start_month, "\nend_month; ", end_month);
        break;
      case "year":
        const start_year = moment().startOf("year").toDate();
        const end_year = moment().endOf("year").toDate();

        setData_startDate(start_year);
        setData_endDate(end_year);
        do_loadReportInPeriod(start_year, end_year);
        // console.log("start_year: ", start_year, "\nend_year; ", end_year);
        break;
      case "today":
        const start_day = moment().startOf("day").toDate();
        const end_day = moment().endOf("day").toDate();

        setData_startDate(start_day);
        setData_endDate(end_day);
        do_loadReportInPeriod(start_day, end_day);
        // console.log("start_year: ", start_year, "\nend_year; ", end_year);
        break;
      default:
        break;
    }
  };

  function do_loadReportInPeriod(startDate, endDate) {
    console.log("startDate: ", startDate, "\nendDate; ", endDate);

    if (locationState !== null && data_dataListArray !== null) {
      // console.log("locationState  "+ endDate.getTime())
      set_isLoading(true);

      var keywordArray = [
        {
          fromDate: startDate.getTime(),
          toDate: endDate.getTime(),
        },
      ];
      var body = {
        searchKeyword: keywordArray,
        searchType: "date",
        page: data_page,
        pageCount: data_pageCount,
        showDeletedData: false,
      };

      // console.log("locationState  "+ JSON.stringify())

      fetchAPI.do_fetch("post", "admin/list-sales", body).then(
        (res) => {
          // console.log("report content: ", JSON.stringify(res.data));
          setData_dataList(res.data.data_list);
          do_loadReceivableInPeriod(startDate,endDate,res.data.data_list);
          // processDataWithActions(startDate, endDate, res.data.data_list);
          // set_refreshData(true);
          // set_isLoading(false);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, Loc.errorMsg_load_report_faild);
          set_isLoading(false);
        }
      );
    }
  }

  function do_loadReceivableInPeriod(startDate, endDate,salesData) {
    console.log("startDate: ", startDate, "\nendDate; ", endDate);

    if (locationState !== null && data_dataListArray !== null) {
      // console.log("locationState  "+ endDate.getTime())
      set_isLoading(true);

      var keywordArray = [
        {
          fromDate: startDate.getTime(),
          toDate: endDate.getTime(),
        },
      ];
      var body = {
        searchKeyword: keywordArray,
        searchType: "date",
        page: data_page,
        pageCount: data_pageCount,
        showDeletedData: false,
      };

      // console.log("locationState  "+ JSON.stringify())

      fetchAPI.do_fetch("post", "admin/list-receivable", body).then(
        (res) => {
          // console.log("report content: ", JSON.stringify(res.data));
          processDataWithActions(startDate, endDate,salesData,res.data.data_list);
          set_refreshData(true);
          set_isLoading(false);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, Loc.errorMsg_load_report_faild);
          set_isLoading(false);
        }
      );
    }
  }

  // function do_loadDataList()
  // {
  //   set_isLoading(true);
  //   const body = {
  //     "page":data_page,
  //     "pageCount":data_pageCount,
  //     showDeletedData:false
  //   }
  //   fetchAPI.do_fetch('post', 'admin/list-sales', body)
  //   .then((res) => {
  //     set_isLoading(false);
  //     // console.log("success: ", res.data);
  //     setData_dataList(res.data.data_list);
  //     setData_dataListCount(res.data.count);
  //   }, error => {
  //     set_isLoading(false);
  //     // console.log("failed: ", error);
  //     showAlert(false, Loc.failed, Loc.data_fetch_failed);
  //   })
  // }

  const searchClicked = () => {
    do_loadReportInPeriod(data_startDate, data_endDate);
  };
  function showHide_selectPeriod() {
    if (data_selectedPeriod !== "other") {
      return null;
    } else {
      return (
        <GridItem xs={12} sm={9} md={9} lg={9}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4} lg={4}>
              <InputLabel className={classes.label}>{Loc.from_date}</InputLabel>
              <Datetime
                timeFormat={false}
                defaultValue={data_startDate}
                value={
                  data_startDate == null ? new Date() : moment(data_startDate)
                }
                inputProps={{
                  placeholder: Loc.from_date,
                }}
                onChange={(m) => {
                  if (moment.isMoment(m)) {
                    setdateState_startDate(true);
                    setData_startDate(m.startOf("day").toDate());
                  } else {
                    setdateState_startDate(false);
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4} md={4} lg={4}>
              <InputLabel className={classes.label}>{Loc.to_date}</InputLabel>
              <Datetime
                timeFormat={false}
                defaultValue={data_endDate}
                value={data_endDate == null ? new Date() : moment(data_endDate)}
                inputProps={{
                  placeholder: Loc.to_date,
                }}
                onChange={(m) => {
                  if (moment.isMoment(m)) {
                    setdateState_endDate(true);
                    setData_endDate(m.endOf("day").toDate());
                  } else {
                    setdateState_endDate(false);
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4} md={4} lg={4}>
              <Button color="info" onClick={searchClicked}>
                <ICON_SEARCH />
                {Loc.search}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    }
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
      />
    );
  }

  function _renderPieChart() {
    return (
      <GridItem xs={12} sm={12} md={5}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Timeline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Pie Chart</h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={pieChartData.data}
              type="Pie"
              options={pieChartData.options}
              responsiveOptions={pieChartData.responsiveOptions}
            />
          </CardBody>
          {_renderPieChartFooter(pieChartData.colorCodes)}
        </Card>
      </GridItem>
    );
  }
  function _renderPieChartFooter(colorCodes) {
    var footerElement = colorCodes.map((item) => {
      return (
        <>
          <i className={"fas fa-circle"} style={{ color: item.code }} />
          {item.name}
          {` `}
        </>
      );
    });

    return (
      <CardFooter stats className={classes.cardFooter}>
        <h6 className={classes.legendTitle}>{Loc.payment_name}</h6>
        {footerElement}
      </CardFooter>
    );
  }

  function _renderPaymentReport(targetData) {
    if (targetData.length == 0) {
      // return null
      setReportDetailData([
        <>
          <GridItem xs={12} sm={12} md={7}>
            <Card>
              <CardBody>{_renderExtendedTable()}</CardBody>
            </Card>
          </GridItem>
          {!pieChartData ? null : _renderPieChart()}
        </>,
      ]);
    } else {
      setReportDetailData([
        <>
          <GridItem xs={12} sm={12} md={7}>
            <Card>
              <CardBody>{_renderExtendedTable()}</CardBody>
            </Card>
          </GridItem>
          {!pieChartData ? null : _renderPieChart()}
        </>,
      ]);
    }
  }

  const pageName = Loc.payment_management;
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{Loc.payments}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} lg={3}>
                  <CustomDropdown
                    dropup
                    dropdownHeader={Loc.select_period}
                    buttonText={get_dropdownTextToShow()}
                    buttonProps={{
                      round: true,
                      color: "info",
                    }}
                    dropdownList={[
                      Loc.today,
                      Loc.this_week,
                      Loc.this_month,
                      Loc.this_year,
                      { divider: true },
                      Loc.select_custom_period,
                    ]}
                    itemIDList={["today", "week", "month", "year", "", "other"]}
                    onClick={(e) => {
                      if (e !== "other") {
                        handle_fast_search(e);
                      } else {
                        setData_startDate(moment().startOf("day").toDate());
                        setData_endDate(moment().endOf("day").toDate());
                      }
                      setData_selectedPeriod(e);
                    }}
                  />

                  {/* {showHide_download()} */}
                </GridItem>

                {showHide_selectPeriod()}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {reportDetailData}
      </GridContainer>
    </div>
  );
}
