/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";


const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Product_add(props) {

      // type validation
  const [requiredState, setrequiredState] = React.useState("");
  const [typeEmailState, settypeEmailState] = React.useState("");
  const [numberState, setnumberState] = React.useState("");
  const [urlState, seturlState] = React.useState("");
  const [equalToState, setequalToState] = React.useState("");

  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");
  const data_targetCollection = "Product-Category";


  const [pathToRedirect, setRedirect] = React.useState("");

  const [productNameState, setcategoryNameState] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const [historyState,setHistoryState] = React.useState(null)
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  }

  React.useEffect(() => {  
      // pre load data for edit
      setHistoryState(props.history.location.state)
    if (props.location.state.data != null)
    {
        var dataToEdit = props.location.state.data;
        console.log("testing get state: ", props.location.state);
        setIsEdit(true);
        setData_id(dataToEdit.id);
        setData_itemName(dataToEdit.itemName);
        setData_remark(dataToEdit.remark);
        
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, []);

  const validCheck = () => {
    var result = true;
    if (data_name == "")
    {
        result = false;
    }
    return result;
  }

  const backClicked = () => {
    setRedirect("/admin/product-category-list");
  }

  const doneClicked =() => {
    setFetchingStatus("fetching");
    if (validCheck())
    {
        const idToPass = isEdit ? data_id : null;
        console.log("idToPass: ", idToPass);
        const body = {
            "id":idToPass,
            "content":{
                "itemName":data_name,
                "remark":data_remark,
            }
        }
        fetchAPI.do_fetch('post', 'admin/create-category', body)
        .then((res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
        }, error => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
        })
    }
    else
    {
        setcategoryNameState("error");
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  }

  function showAlert(issuccess, title, content){
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
            if (issuccess)
            {
                // hideAlert()
                setRedirect("/admin/product-category-list");
            }
            else
            {
                hideAlert()
            }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            if (issuccess)
            {
                setData_itemName("");
                setData_remark("");
                // window.location.reload(false);
                hideAlert()
            }
            else
            {
                hideAlert()
            }
            
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChange_gender = event => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}  />);
      }
      else
      {
          return null;
      }
  }

  const imageDidUpload = (pathInStorage) => {
      if (pathInStorage != null)
      {
        setData_imageUrl(pathInStorage);
        console.log("imageDidUpload called, path: ", pathInStorage);
      }
  }
  
    const classes = useStyles();
    const classes_alert = useStyles_alert();

    return (
    <div>
        {check_redirect()}
        {alert_save}
        <GridContainer>
        <GridItem xs={6}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <form>
                <GridContainer>

                    <GridItem xs={12}>
                        <CustomInput
                            // success={requiredState === "success"}
                            error={productNameState === "error"}
                            labelText={Loc.category_name+"*"}
                            id="category_name"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: data_name,
                                onChange: event => {
                                    setcategoryNameState("success");
                                    setData_itemName(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>  

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.remark}
                        id="remark"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_remark,
                            onChange: event => {
                                setData_remark(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>                 

                </GridContainer>
                
                </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer spacing={2}
                direction="row"
                justify="center"
                alignItems="center">
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button color="rose" onClick={doneClicked} disabled={fetchingStatus==="fetching" ? true : false}>
                        <ICON_DONE />
                        {isEdit ? Loc.save : Loc.create}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}