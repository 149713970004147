/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";


// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";


const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Customer_add(props) {

    const data_targetCollection = "Customer";
      // type validation
  const [required, setrequired] = React.useState("");
  const [requiredState, setrequiredState] = React.useState("");
  const [typeEmail, settypeEmail] = React.useState("");
  const [typeEmailState, settypeEmailState] = React.useState("");
  const [number, setnumber] = React.useState("");
  const [numberState, setnumberState] = React.useState("");
  const [url, seturl] = React.useState("");
  const [urlState, seturlState] = React.useState("");
  const [equalTo, setequalTo] = React.useState("");
  const [whichEqualTo, setwhichEqualTo] = React.useState("");
  const [equalToState, setequalToState] = React.useState("");

  const collectionsToLoad = ["Store"];
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);

  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_profileUrl, setData_profileUrl] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_customerCode, setData_customerCode] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_phone, setData_phone] = React.useState("");
  const [data_email, setData_email] = React.useState("");
  const [data_gender, setData_gender] = React.useState("U");
  const [data_remark, setData_remark] = React.useState("");

  const [pathToRedirect, setRedirect] = React.useState("");

  const [customerNameState, setCustomerNameState] = React.useState("");
  const [customerCodeState, setCustomerCodeState] = React.useState("");
  const [historyState,setHistoryState] = React.useState(null)
  const [fetchingStatus, setFetchingStatus] = React.useState("");
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  }

  React.useEffect(() => {  
    // console.log("testing get state: ", props.location.state);
    setHistoryState(props.history.location.state)
    var promise_preLoad = null;
    if (!isPreLoadedData && props.location.state.config !== null)
    {
        setIsPreLoadedData(true);

        var preloadListStr = localStorage.getItem("preloadDataList")
        var preloadList = JSON.parse(preloadListStr);
    //   console.log("preloadList  "+JSON.stringify(preloadList))

        setData_dataListArray(preloadList);
        const storeList = preloadList["Store"]
        const customerData = props.location.state.data
        var firstStoreID = storeList[0].id;
        
        if (props.location.state.data !== null){
            storeList.map(item=>{
                if ( customerData.code.includes(item.code) ){
                    setData_storeID(item.id);
                    do_generateStoreCode(item.id, props.location.state.config);
                }
            }) 
            
        }else{
            setData_storeID(firstStoreID);
            do_generateStoreCode(firstStoreID, props.location.state.config);
        }
    }
    
      // pre load data for edit
    if (data_dataListArray !== null && props.location.state.data != null)
    {
        var dataToEdit = props.location.state.data;
        setIsEdit(true);
        setData_id(dataToEdit.id);
        setData_gender(dataToEdit.gender);
        setData_itemName(dataToEdit.itemName);
        setData_customerCode(dataToEdit.code);
        setData_phone(dataToEdit.phone);
        setData_email(dataToEdit.email);
        setData_remark(dataToEdit.remark);
        setData_profileUrl(dataToEdit.profileUrl);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
        promise_preLoad = null;
    };
  }, [data_dataListArray]);

//   function do_loadListOfCollection(collectionsList, onHoldList)
//   {
//       var newList = collectionsList;
//       var newOnHoldList = onHoldList;

//       const targetCollection = newList[0];
//       return new Promise((onDone) => {
//           const body = {
//               "data_targetCollection":targetCollection
//           }
//           fetchAPI.do_fetch('post', 'admin/list-all', body)
//           .then((res) => {
//             newOnHoldList[targetCollection] = res.data.data_list;
//             newList.splice(0, 1);

//             if (newList.length > 0)
//             {
//                 do_loadListOfCollection(newList, newOnHoldList).then(finalOnHoldList => {
//                     onDone(finalOnHoldList);
//                 })
//             }
//             else
//             {
//                 onDone(newOnHoldList);
//             }
//             //   onDone(res.data.data_list);
//           }, error => {
//               console.log("failed: ", error);
//               onDone(null);
//           })
//       });
//   }

  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("error");
    }
    if (typeEmailState === "") {
      settypeEmailState("error");
    }
    if (numberState === "") {
      setnumberState("error");
    }
    if (urlState === "") {
      seturlState("error");
    }
    if (equalToState === "") {
      setequalToState("error");
    }
  };

  const validCheck = (onDone) => {
    if (data_name == "")
    {
        setCustomerNameState("error");
        onDone(false);
    }
    else
    {
        if (data_customerCode === "" || isEdit)
        {
            onDone(true);
        }
        else
        {
            var data = {}
            data["code"] = data_customerCode;
            var list_unique_to_check = [];
            list_unique_to_check.push(data);
            handle_checkUniqueField(list_unique_to_check, checkResult => {
                onDone(checkResult);
                if (!checkResult)
                {
                    setCustomerCodeState("error");
                }
            });
        }
    }
  }

  function handle_checkUniqueField(_uniqueFieldList,callback){
    const body = {
      "collectionName":"Customer",
      "content":{
        uniqueFieldList:_uniqueFieldList
      }
    };

    // console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/custom-unique-checking", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data);
      },
      (error) => {
        console.log("failed: ", error);
        callback(false);
      }
    );
  }

  const backClicked = () => {
    setRedirect("/admin/customer-list");
  }

  const doneClicked =() => {
    setFetchingStatus("fetching");
    validCheck(isValid => 
    {
        if (isValid)
        {
            const idToPass = isEdit ? data_id : null;
            const body = {
                "id":idToPass,
                "content":{
                    itemName:data_name,
                    code:data_customerCode.toUpperCase(),
                    gender:data_gender, 
                    email:data_email, 
                    phone:data_phone, 
                    remark:data_remark,
                    profileUrl:data_profileUrl
                }
            }
            fetchAPI.do_fetch('post', 'admin/create-customer', body)
            .then((res) => {
                console.log("success: ", res);
                setFetchingStatus("");
                showAlert(true, Loc.success, Loc.data_save_success);
            }, error => {
                console.log("failed: ", error);
                setFetchingStatus("");
                showAlert(false, Loc.failed, Loc.data_save_failed);
            })
        }
        else
        {
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
        }
    });
  }

  function showAlert(issuccess, title, content){
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
            if (issuccess)
            {
                // hideAlert()
                setRedirect("/admin/customer-list");
            }
            else
            {
                hideAlert()
            }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            if (issuccess)
            {
                setData_gender("U");
                setData_itemName("");
                setData_phone("");
                setData_email("");
                setData_remark("");
                setData_profileUrl("");
                hideAlert()
            }
            else
            {
                hideAlert()
            }
            
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChange_gender = event => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
        return (
            <Redirect
              to={{
                pathname: pathToRedirect,
                state: historyState.config,
              }}
            />
        );
      }
      else
      {
          return null;
      }
  }

  const imageDidUpload = (pathInStorage) => {
      if (pathInStorage != null)
      {
        setData_profileUrl(pathInStorage);
        console.log("imageDidUpload called, path: ", pathInStorage);
      }
  }

  const getStringToShow = () => {
    var strToShow = (data_dataListArray !== null && data_dataListArray["Store"] !== null) ? (data_dataListArray["Store"][0].itemName) : Loc.store_default;
    if (data_dataListArray !== null && data_dataListArray["Store"] !== null)
    {
        data_dataListArray["Store"].map((prop, key) => {
            if (prop.id === data_storeID) {
                strToShow = prop.itemName;
            }
        });
    }
    return strToShow;
  };

  function do_generateStoreCode(selectedID, dataList)
  {
        if (dataList !== null && dataList["Store"] !== null)
        {
            var storeCode = "";
            dataList["Store"].map(i => {
                if (i.id == selectedID)
                {
                    storeCode = i.code;
                }
            });
            if (storeCode !== "")
            {
                setData_customerCode(storeCode);
            }
        }
    }

  const render_storeDropdown = () =>
  {
    var searchFieldNameList = [];
    var searchIDList = [];

    if (data_dataListArray !== null && data_dataListArray["Store"] !== null)
    {
        data_dataListArray["Store"].map(i => {
            searchFieldNameList.push(i.itemName);
            searchIDList.push(i.id);
        });
    }

    return (
        <CustomDropdown
            buttonProps={{
            round: true,
            color: "info",
            disabled: isEdit
            }}
            buttonText={<span>{getStringToShow()}</span>}
            dropdownList={searchFieldNameList}
            itemIDList={searchIDList}
            onClick={(selectedID) => {
                console.log("selectedID: " + selectedID);
                if (selectedID === null) {
                    setData_storeID("");
                } else {
                    setData_storeID(selectedID);
                    do_generateStoreCode(selectedID, data_dataListArray);
                }
            }}
        />
    );
  }
  
    const classes = useStyles();
    const classes_alert = useStyles_alert();

    return (
    <div>
        {check_redirect()}
        {alert_save}
        <GridContainer>
        <GridItem xs={6}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <form>
                <GridContainer>
                    <GridItem xs={12} align="center">
                        {render_storeDropdown()}
                    </GridItem>
                    
                    <GridItem xs={12} sm={6}>
                        <PictureUpload imageDidUpload={(res) => imageDidUpload(res)} imageUrl={data_profileUrl} targetFolder="Customer"/>
                    </GridItem>

                    <GridItem xs={12} sm={6}>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_gender === "U"}
                            onChange={handleChange_gender}
                            value="U"
                            name={Loc.secret}
                            aria-label="U"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.secret}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_gender === "M"}
                            onChange={handleChange_gender}
                            value="M"
                            name={Loc.male}
                            aria-label="M"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.male}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_gender === "F"}
                                onChange={handleChange_gender}
                                value="F"
                                name={Loc.female}
                                aria-label="F"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.female}
                        />
                        </div>
                    </GridItem>

                    <GridItem xs={9}>
                    <CustomInput
                        // success={requiredState === "success"}
                        error={customerNameState === "error"}
                        labelText={Loc.customer_name+"*"}
                        id="customer_name"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_name,
                            onChange: event => {
                                setCustomerNameState("success");
                                setData_itemName(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={3}>
                    <CustomInput
                        // success={requiredState === "success"}
                        error={customerCodeState === "error"}
                        labelText={Loc.code_old}
                        id="customer_code"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_customerCode,
                            onChange: event => {
                                setCustomerNameState("success");
                                setData_customerCode(event.target.value);
                            },
                            type: "text",
                            disabled: isEdit
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.customer_phone}
                        id="customer_phone"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_phone,
                            onChange: event => {
                                setData_phone(event.target.value);
                            },
                            type: "tel",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.customer_email}
                        id="customer_email"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_email,
                            onChange: event => {
                                setData_email(event.target.value);
                            },
                            type: "email",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.remark}
                        id="remark"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_remark,
                            onChange: event => {
                                setData_remark(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>

                </GridContainer>
                
                </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer spacing={2}
                direction="row"
                justify="center"
                alignItems="center">
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button color="rose" onClick={doneClicked} disabled={fetchingStatus==="fetching" ? true : false}>
                        <ICON_DONE />
                        {isEdit ? Loc.save : Loc.create}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}