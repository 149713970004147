/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_DELETE from "@material-ui/icons/Clear";

export default function Product_add(props) {
  // type validation

  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");

  //--- "Field Name" "Field ID"
  const [data_createFieldList, setData_createFieldList] = React.useState([]);
  //---
  const [data_UILayout, setData_UILayout] = React.useState([]);

  const [data_setDataName, setData_setDataName] = React.useState("");
  const [data_iconName, setData_iconName] = React.useState("ChildCare");
  const [pathToRedirect, setRedirect] = React.useState("");

  const [setDataNameState, setSetDataNameState] = React.useState(false);
  const [iconNameState, setIconNameState] = React.useState(false);

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [historyState,setHistoryState] = React.useState(null)
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  React.useEffect(() => {
    setHistoryState(props.history.location.state)
    // pre load data for edit
    if (props.location.state.data != null) {
      console.log("testing get state: ", props.location.state);
      var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      setData_itemName(dataToEdit.itemName);
      setData_iconName(dataToEdit.iconName);
      setData_setDataName(dataToEdit.setDataName);
    }

    process_UI_Layout(data_createFieldList);
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, []);

  function process_UI_Layout(_data_createFieldList) {
    const new_layout = _renderUILayout(_data_createFieldList);
    setData_UILayout(new_layout);
  }

  const validCheck = () => {
    var result = true;
    if (data_setDataName == "" || data_iconName == "" || !checkIconAvailable()) {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/data-set-management-type-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      const body = {
        id: idToPass,
        content: {
          setDataName: data_setDataName,
          iconName: data_iconName,
        },
      };
      fetchAPI
        .do_fetch("post", "admin/create-set-data-management-type-config", body)
        .then(
          (res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
          },
          (error) => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
          }
        );
    } else {
      if(data_setDataName === ""){setSetDataNameState(true)}
      if(data_iconName === "" || !checkIconAvailable()){setIconNameState(true)}
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/data-set-management-type-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_id("")
            setData_iconName("")
            setData_setDataName("")
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  function checkIconAvailable(){
    if (_renderIconPreview() === null){return false}
    return true
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={{
        pathname: pathToRedirect,
        state: historyState.config,
      }}  />;
    } else {
      return null;
    }
  };

  function _renderUILayout() {
    return (
      <>
        {" "}
        {/* {Collection name: (inputview)} */}
        <GridItem xs={12}>
          <CustomInput
            // success={requiredState === "success"}
            error={setDataNameState}
            labelText={Loc.data_set_management_type_name_key + "*"}
            id="set_data_name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_setDataName,
              onChange: (event) => {
                setSetDataNameState(false)
                setData_setDataName(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            error={iconNameState}
            labelText={Loc.icon_name + "*"}
            id="set_data_icon"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_iconName,
              onChange: (event) => {
                setIconNameState(false)
                setData_iconName(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        {_renderIconPreview()}
        <a href="https://mui.com/components/material-icons/" target="_blank"> Click to Browse Icons </a>
      </>
    );
  }

  function _renderIconPreview() {
    //get image name from: https://mui.com/components/material-icons/
    if(!data_iconName)return null
    
    try {
      const CustomIcon = require(`@material-ui/icons/${data_iconName ?? ""}`).default;
      
      return (
        <GridItem xs={12}>
          <CustomIcon style={{ fontSize: 100 }} />
        </GridItem>
      );
    } catch (ex) {
      
      return null;
    }
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  return (
    <div>
      {check_redirect()}
      {alert_save}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>{_renderUILayout()}</GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
