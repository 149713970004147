/*eslint-disable*/
import React from "react";
import { Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import uuid from "react-uuid";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import NavPills from "components/NavPills/NavPills.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Info from "components/Typography/Info.js";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import Datetime from "react-datetime";

import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_SELECT from '@material-ui/icons/CheckCircleOutline';
import ICON_SELECTED from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import { TextField } from "@material-ui/core";
import CustomTextField from "components/CustomTextField/CustomTextField.js";
import { TrendingUpOutlined } from "@material-ui/icons";

export default function Sales_add(props) {
  const data_targetCollection = "Sales-Record";
  // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);
  const [data_isDataListLoaded, setData_isDataListLoaded] = React.useState(
    false
  );

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");
  const [data_itemsInPackage, setData_itemsInPackage] = React.useState([]);
  const [data_customerID, setData_customerID] = React.useState("");
  const [data_customerCode, setData_customerCode] = React.useState("");
  const [data_customerName, setData_customerName] = React.useState("");
  const [data_customerPhone, setData_customerPhone] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_staffID, setData_staffID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_staffName, setData_staffName] = React.useState("");
  const [data_paymentID, setData_paymentID] = React.useState("");
  const [data_paymentName, setData_paymentName] = React.useState("");
  const [data_commissionID, setData_commissionID] = React.useState("");
  const [data_receivableID, setData_receivableID] = React.useState("");
  const [data_product_expenditureID, setData_product_expenditureID] = React.useState("");
  const [data_coupon_expenditureID, setData_coupon_expenditureID] = React.useState("");

  const [
    data_commissionAmount_org,
    setData_commissionAmount_org,
  ] = React.useState(0);

  const [data_OrgItemsInPackage, setData_OrgItemsInPackage] = React.useState(
    []
  );

  const [data_totalAmount, setData_totalAmount] = React.useState(0);
  const [data_totalDiscount, setData_totalDiscount] = React.useState(0);
  const [data_receive, setData_receive] = React.useState(0);
  const [data_receiveable_org, setData_receiveable_org] = React.useState(0);

  const [data_receive_net, setData_receive_net] = React.useState(0.0);
  const [
    data_selectedCustomerData,
    setData_selectedCustomerData,
  ] = React.useState(null);
  const [
    data_selectedCustomerDataReceivableBalance,
    setData_selectedCustomerDataReceivableBalance,
  ] = React.useState(null);

  

  const [data_date, setData_date] = React.useState(new Date());
  const [dateState, setdateState] = React.useState("");

  const [data_dataListArray, setData_dataListArray] = React.useState(null);

  const [
    data_itemsInPackageTableList,
    setData_itemsInPackageTableList,
  ] = React.useState([]);
  const [
    data_customerCouponTableList,
    setData_customerCouponTableList,
  ] = React.useState([]);
  const [data_customerCoupon, setData_customerCoupon] = React.useState([]);

  const [data_couponToUse, setData_couponToUse] = React.useState([]);
  const [data_orgCouponToUse, setData_orgCouponToUse] = React.useState([]);
  const [
    data_couponToUseTableList,
    setData_couponToUseTableList,
  ] = React.useState([]);

  // const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [isLoadedEditData, setIsLoadedEditData] = React.useState(false);

  const [pathToRedirect, setRedirect] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);

  const [isLoading, set_isLoading] = React.useState(false);

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_searchID, setData_searchID] = React.useState("");

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(25);

  const [customerData, setCustomerData] = React.useState([]);
  const [data_isLoaded, set_isLoaded] = React.useState(false);

  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);
  const [hasDuration, setHasDuration] = React.useState(null);

  const classes_switch = useStyles_switch();

  const hideAlert = () => {
    setSaveAlert(null);
    setConfirmAlert(null);
  };

  const div_list_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Danger>{Loc.list_empty}</Danger>
      </GridItem>
    );
    return theDiv;
  };

  const div_coupon_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Info>{Loc.list_coupon_empty}</Info>
      </GridItem>
    );
    return theDiv;
  };

  const div_couponToUse_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Info>{Loc.list_couponToUse_empty}</Info>
      </GridItem>
    );
    return theDiv;
  };

  React.useEffect(() => {
    // var promise_preLoad = null;
    if (hasDuration == null){
      getConfigList(()=>{
        initData()
      })
    }else{
      initData()
    }
    

    // Specify how to clean up after this effect:
    return function cleanup() {
      // promise_preLoad = null;
    };
  }, [
    data_itemsInPackage,
    data_dataListArray,
    data_dataList,
    data_pageCount,
    data_page,
    data_couponToUseTableList,
    locationState,
  ]);

  function initData(){
    var preloadListStr = localStorage.getItem("preloadDataList")
    var preloadList = JSON.parse(preloadListStr);
    if (!locationState || locationState == null) {
      setLocationState(props.location.state);
      setData_dataListArray(preloadList);
      // setLocationState(props.location.state);
      setHistoryState(props.history.location.state);
      
      // getConfigList();
    } else {
      if (data_dataListArray === null) {
        // const stateConfig = locationState.config;
        // console.log("stateConfig  " + JSON.stringify(stateConfig))

        if (preloadList["Store"].length > 0) {
          setData_storeID(preloadList["Store"][0].id);
          setData_storeName(preloadList["Store"][0].itemName);
        }

        var adminInfo = props.location.state.config.adminInfo;
              // console.log("Admin Store :"+ JSON.stringify(adminInfo))

              if(adminInfo.storeID){
                console.log("Admin Store :"+ adminInfo.storeName)
                setData_storeID(adminInfo.storeID);
                setData_storeName(adminInfo.storeName);
              }

        if (preloadList["Staff"].length > 0) {
          setData_staffID(preloadList["Staff"][0].id);
          setData_staffName(preloadList["Staff"][0].itemName);
        }

        if (preloadList["Payment"].length > 0) {
          setData_paymentID(preloadList["Payment"][0].id);
          setData_paymentName(preloadList["Payment"][0].itemName);
        }
        setData_dataListArray(preloadList);
      } else {
        if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
          set_isLoaded(true);
          if (hasDuration !== null){
            set_isLoading(true);
          }
          getCustomerList();
        }
      }

      if (
        data_dataListArray !== null &&
        locationState !== null &&
        locationState.data !== null &&
        !isLoadedEditData
      ) {
        const stateData = locationState.data;

        setIsLoadedEditData(true);
        setIsEdit(true);
        console.log("stateData.id  "+stateData.id)

        setData_id(stateData.id);
        setData_date(stateData.date);
        setData_customerID(stateData.customerID);
        setData_customerCode(stateData.customerCode);
        setData_customerName(stateData.customerName);
        setData_customerPhone(stateData.customerPhone);
        var temp_itemsInPackage = stateData.itemsInPackage;
        temp_itemsInPackage.map(temp_item => {
          temp_item.isNeedToUpdate = false;
        });
        setData_itemsInPackage(temp_itemsInPackage);
        setData_commissionAmount_org(
          get_commissionAmount(
            stateData.itemsInPackage,
            stateData.couponUsageList
          )
        );
        setData_paymentID(stateData.paymentID);
        setData_paymentName(stateData.paymentName);
        setData_receive(stateData.totalReceive);
        setData_receive_net(parseFloat(stateData.totalReceive_net));

        if (stateData.totalReceive > stateData.totalReceive_net) {
          setData_receiveable_org(
            stateData.totalReceive - stateData.totalReceive_net
          );
        }
        setData_remark(stateData.remark);
        setData_storeID(stateData.storeID);
        setData_staffID(stateData.staffID);
        setData_storeName(stateData.storeName);
        setData_staffName(stateData.staffName);

        select_customer_withID(stateData.customerID, stateData.couponUsageList);
        setData_commissionID(stateData.commissionID);
        setData_receivableID(stateData.receivableID);
        // NOTE try after create success
        // setData_expenditureID(stateData.data_expenditureID);
        setData_product_expenditureID(stateData.product_expenditureID)
        setData_coupon_expenditureID(stateData.coupon_expenditureID)
        var couponUsageList = []
        
        // stateData.couponUsageList.map(item=>{

        //   couponUsageList.push({...item,exchange_salesPrice:(!item.exchange_salesPrice || item.exchange_salesPrice == null ) ? 0:item.exchange_salesPrice})

        // })
        // console.log("couponUsageList  edit mode  "+ JSON.stringify(stateData.couponUsageList))
        setData_couponToUse(stateData.couponUsageList);

        var orgCouponToUse = [];
        stateData.couponUsageList.map((item, key) => {
          const copyItem = {
            id: item.id,
            use: item.use,
            couponID: item.couponID,
            qty: item.qty,
          };
          orgCouponToUse.push(copyItem);
        });
        // console.log("orgCouponToUse: ", orgCouponToUse);
        setData_orgCouponToUse(orgCouponToUse);
        process_itemsInPackageTableList(stateData.itemsInPackage);
        process_couponToUseTableList(stateData.couponUsageList);
        const orgData = stateData.itemsInPackage.map((item, key) => {
          const copyItem = {
            id: item.id,
            discount: item.discount,
            type: item.type,
            price: item.price,
            qty: item.qty,
            cart_uuid: item.cart_uuid,
          };
          return copyItem;
        });
        setData_OrgItemsInPackage(orgData);
      }

      if (data_itemsInPackage !== null) {
        // console.log("refresh calculateAmounts");
        calculateAmounts(data_itemsInPackage);
      }
    }
  }

  function getConfigList(success) {
    set_isLoading(true);
    const body = {
      page: 0,
      pageCount: 50,
    };
    fetchAPI.do_fetch("post", "admin/list-system-config", body).then(
      (res) => {
          if(res.data){
            var configList = res.data.configList;
            setHasDuration(configList["has-duration"]);
          }else{
            setHasDuration(false);
          }
          
          // console.log("configList: ", JSON.stringify(configList["has-duration"]));
          success()
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function select_customer_withID(customerID, couponUsageRecords) {
    console.log("select_customer_withID called: ", customerID);

    getCustomerList("uid", customerID, (dataList) => {
      dataList.map((c, key) => {
        if (c.id === customerID) {
          do_selectCustomer(c, couponUsageRecords,true);
        }
      });
    });

    // if (data_dataList == null) {
    // console.log(`select_customer_withID  ${JSON.stringify(data_dataListArray["Customer"])}`)
    // data_dataList.map((c, key) => {
    //   if (c.id === customerID) {

    //   }
    // });
    // }
  }
  // function do_loadListOfCollection(collectionsList, onHoldList) {
  //   var newList = collectionsList;
  //   var newOnHoldList = onHoldList;

  //   console.log(`do_loadListOfCollection =====>`);

  //   const targetCollection = newList[0];
  //   return new Promise((onDone) => {
  //     set_isLoading(true);
  //     const body = {
  //       data_targetCollection: targetCollection,
  //     };

  //     fetchAPI.do_fetch("post", "admin/list-all", body).then(
  //       (res) => {
  //         newOnHoldList[targetCollection] = res.data.data_list;
  //         newList.splice(0, 1);

  //         if (newList.length > 0) {
  //           do_loadListOfCollection(newList, newOnHoldList).then(
  //             (finalOnHoldList) => {
  //               onDone(finalOnHoldList);
  //               // set_isLoading(false);
  //             }
  //           );
  //         } else {
  //           onDone(newOnHoldList);
  //         }
  //         //   onDone(res.data.data_list);
  //       },
  //       (error) => {
  //         console.log("failed: ", error);
  //         onDone(null);
  //       }
  //     );
  //   });
  // }

  function process_itemTableList(targetData) {
    const newData = targetData
      .filter((v) => v.isDeleted != true && v.isOld != true)
      .map((prop, key) => {
        const product_id = prop.id;
        const itemName = prop.itemName;
        const price = prop.price;

        return {
          no: key + 1,
          id: key,
          product_id: product_id,
          itemName: itemName,
          //   (
          //   <span>
          //     {/* <img src={prop.imageUrl} alt="..." /> */}
          //     {itemName}
          //   </span>
          //   ),
          product_id: product_id,
          price: "$ " + parseFloat(price).toLocaleString(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                color="success"
                simple
                className={classes_tableBtn.actionButton}
                onClick={() => {
                  do_addItemIntoPackage(prop, "item");
                }}
              >
                <ICON_ADD className={classes_tableBtn.icon} />
              </Button>
            </div>
          ),
        };
      });

    return newData;
    //   setData_itemTableList(newData);
  }

  function process_couponTableList(targetData) {
    const newData = targetData
      .filter((v) => v.isDeleted != true && v.isOld != true)
      .map((prop, key) => {
        const product_id = prop.id;
        const itemName = prop.itemName;
        const price = prop.price;

        return {
          no: key + 1,
          id: key,
          product_id: product_id,
          itemName: itemName,
          //   (
          //   <span>
          //     {/* <img src={prop.imageUrl} alt="..." /> */}
          //     {itemName}
          //   </span>
          //   ),
          product_id: product_id,
          price: "$ " + parseFloat(price).toLocaleString(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                color="success"
                simple
                className={classes_tableBtn.actionButton}
                onClick={() => {
                  do_addItemIntoPackage(prop, "coupon");
                }}
              >
                <ICON_ADD className={classes_tableBtn.icon} />
              </Button>
            </div>
          ),
        };
      });

    return newData;
    //   setData_couponTableList(newData);
  }

  function process_packageTableList(targetData) {
    const newData = targetData
      .filter((v) => v.isDeleted != true && v.isOld != true)
      .map((prop, key) => {
        const product_id = prop.id;
        const itemName = prop.itemName;
        const price = prop.price;

        return {
          no: key + 1,
          id: key,
          product_id: product_id,
          itemName: itemName,
          //   (
          //   <span>
          //     {/* <img src={prop.imageUrl} alt="..." /> */}
          //     {itemName}
          //   </span>
          //   ),
          product_id: product_id,
          price: "$ " + parseFloat(price).toLocaleString(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                color="success"
                simple
                className={classes_tableBtn.actionButton}
                onClick={() => {
                  var typeToShow = "package";
                  if (prop.type === "coupon") {
                    typeToShow += "_coupon";
                  } else if (prop.type === "product") {
                    typeToShow += "_product";
                  }
                  do_addItemIntoPackage(prop, typeToShow);
                  calculateAmounts(data_itemsInPackage);
                }}
              >
                <ICON_ADD className={classes_tableBtn.icon} />
              </Button>
            </div>
          ),
        };
      });

    return newData;
    //   setData_couponTableList(newData);
  }

  function process_customerTableList(targetData) {
    if (targetData === null) {
      return [];
    }
    const m = targetData.filter((v) => v.isDeleted !== true);

    const newData = m
      .map((prop, key) => {
        const customer_id = prop.id;
        const code = prop.code;
        const itemName = prop.itemName;
        const phone = prop.phone === "" ? "/" : prop.phone;

        const isSelf = (customer_id === data_customerID);

        const data_tabContent =  [
          // no: key + 1,
          // id: key,
          // targetData: targetData,
          code,
          itemName,
          //   (
          //   <span>
          //     {/* <img src={prop.imageUrl} alt="..." /> */}
          //     {itemName}
          //   </span>
          //   ),
          // customer_id: customer_id,
          phone,

          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              color="success"
              simple
              className={classes_tableBtn.actionButton}
              onClick={() => {
                // if (isEdit && customer_id != data_customerID) {
                //   setConfirmAlert(
                //     <SweetAlert
                //       danger
                //       style={{ display: "block", marginTop: "-100px" }}
                //       title={Loc.error}
                //       onConfirm={() => {
                //         hideAlert();
                //       }}
                //       confirmBtnCssClass={
                //         classes_alert.button + " " + classes_alert.success
                //       }
                //       cancelBtnCssClass={
                //         classes_alert.button + " " + classes_alert.info
                //       }
                //       onCancel={() => {
                //         hideAlert();
                //       }}
                //       confirmBtnText={Loc.confirm}
                //       cancelBtnText={Loc.cancel}
                //       btnSize="lg"
                //     >
                //       {Loc.edit_sales_used_coupon_select_customer}
                //     </SweetAlert>
                //   );
                // } else {
                do_selectCustomer(prop, []);
                // }
              }}
            >
              {isSelf ? <ICON_SELECTED className={classes_tableBtn.icon} /> : <ICON_SELECT className={classes_tableBtn.icon} />}
            </Button>
          </div>,
        ];

        if (isSelf)
        {
          return {
            color: "success",
            data: data_tabContent
          }
        }
        else
        {
          return data_tabContent
        }

      });
    // console.log("newData: " + newData.length);
    // setCustomerData(newData);
    return newData;
  }

  function getReceivableBalanceContent(receivableBalanceData)
  {
    if (receivableBalanceData !== null)
    {
      if (receivableBalanceData.qty !== 0)
      {
        return (
          <Danger>{parseFloat(receivableBalanceData.qty).toLocaleString()}</Danger>
        );
      }
      else
      {
        return (
          <Success>{Loc.no_arrears}</Success>
        );
      }
    }
    else
    {
      return (
        "-"
      );
    }
  }

  function do_getCustomerReceivableBalance(targetCustomerID)
  {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Receivable-Balance",
        data_docID: targetCustomerID,
      };
      fetchAPI.do_fetch("post", "admin/get-related-records", body).then(
        (res) => {
          console.log("Get balance success: ", res.data);
          onDone(res.data);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function do_selectCustomer(customerData, couponUsageRecords,IsFromInit) {
    setData_customerID(customerData.id);
    setData_customerCode(customerData.code);
    setData_customerName(customerData.itemName);
    setData_customerPhone(customerData.phone);

    // console.log("customerData: ", customerData);
    setData_selectedCustomerData(customerData);

    // load and show receivable balance
    setData_selectedCustomerDataReceivableBalance(null);
    do_getCustomerReceivableBalance(customerData.id).then((receivableBalanceData) => {
      setData_selectedCustomerDataReceivableBalance(receivableBalanceData);
    });

    // load and show coupon on hand
    do_getCustomerCouponList(customerData.id).then(
      (couponBalanceRecordList) => {
        if (couponBalanceRecordList != null) {
          const data = couponBalanceRecordList.filter(
            (v) => v.isDeleted != true && v.qty != 0
          );
          process_customerCouponTableList(data, couponUsageRecords);
          setData_customerCoupon(
            couponBalanceRecordList.filter((v) => v.isDeleted != true)
          );
          
        } else {
          console.log("couponBalanceRecordList is null");
        }
        
        if(!IsFromInit){
          process_couponToUseTableList([])
          //Init use coupon list
          setData_orgCouponToUse([])
          setData_couponToUse([])
        }
        
      }
    );
  }

  function do_getCustomerCouponList(targetCustomerID) {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Coupon-Balance",
        data_relatedIDName: "customerID",
        data_relatedID: targetCustomerID,
      };
      fetchAPI.do_fetch("post", "admin/list-related-records", body).then(
        (res) => {
          // console.log("coupon List success: ", res.data);
          onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function do_addItemIntoPackage(itemData, targetType) {
    var newItemList = data_itemsInPackage;
    var itemIndexKey = -1;
    const targetID = itemData.id;
    const targetName = itemData.itemName;
    var data_toPutItemsInPackage = null;
    var duration = 0;
    newItemList.map((item, key) => {
      if (item.id === targetID) {
        itemIndexKey = key;
      }
    });
    if (itemIndexKey == -1) {
      var targetCollection = null;
      var targetItemPrice = 0;
      var targetItemCommission = 0;

      if (targetType === "item") {
        targetCollection = data_dataListArray["Product"];
      } else if (targetType === "coupon") {
        targetCollection = data_dataListArray["Product-Coupon"];
      } else if (
        targetType === "package" ||
        targetType === "package_coupon" ||
        targetType === "package_product"
      ) {
        targetCollection = data_dataListArray["Product-Package"];
      }

      if (targetCollection != null) {
        targetCollection.map((item, key) => {
          if (item.id === targetID) {
            targetItemPrice = item.price;
            targetItemCommission = item.commission;
            duration = item.duration ? item.duration : 0;
            // console.log(`item ===== ${JSON.stringify(item)}`)
          }

          if (
            targetType === "package" ||
            targetType === "package_coupon" ||
            targetType === "package_product"
          ) {
            data_toPutItemsInPackage = item.itemsInPackage;
          }
        });
      }

      const newItem = {
        cart_uuid: uuid(),
        id: targetID,
        itemName: targetName,
        qty: 1.0,
        type: targetType,
        discount: 0.0,
        price: targetItemPrice,
        commission: targetItemCommission,
        itemsInPackage: data_toPutItemsInPackage,
        duration: parseFloat(duration),
        currentDuration: parseFloat(duration),
        isNeedToUpdate: true
      };
      newItemList.push(newItem);
    } else {
      var newQty = parseFloat(newItemList[itemIndexKey].qty);
      newQty += 1.0;
      newItemList[itemIndexKey].qty = newQty;
      newItemList[itemIndexKey].isNeedToUpdate = true;
    }
    setData_itemsInPackage(newItemList);
    // setData_couponToUse(newItemList)
    process_itemsInPackageTableList(newItemList);
    // process_couponToUseTableList(newItemList)
    // console.log("data_itemsInPackage: ", data_itemsInPackage);
    calculateAmounts(newItemList);
  }

  const validCheck = () => {
    var result = true;
    if(data_customerID == "" || data_customerName ==""){
      showAlert(false,Loc.failed,Loc.please_select_customer)
      result = false;
    }else if (dateState === "error") {
      showAlert(false, Loc.failed, Loc.errorMsg_date_empty);
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/sales-list");
  };

  const warningAlert = () => {
    setConfirmAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.alert_sales_amount_warning}
        onConfirm={() => {
          doneClicked();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.default}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.continue}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      ></SweetAlert>
    );
  };

  const warningExchangeAlert = (checked, newList,key_couponToUse) => {
    setConfirmAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.are_you_sure}
        onConfirm={() => {
          newList[key_couponToUse].isExchange = checked;
          setData_couponToUse(newList);
          process_couponToUseTableList(newList);
          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.default}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.continue}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      ></SweetAlert>
    );
  };

  const doneClicked = () => {
    setConfirmAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_receive}
        onConfirm={() => {
          setFetchingStatus("fetching");

          // get commission content
          const commissionBody = get_commissionBody();
          const commissionID = commissionBody.id;
          const commissionContent = commissionBody.content;

          // get inventory balance change list
          const inventoryBalanceChangeList = get_inventoryBalanceChangeList();
          // console.log(
          //   "inventoryBalanceChangeList: ",
          //   inventoryBalanceChangeList
          // );

          // get receivable content
          const receivableToAddBody = get_receivableToAddBody();
          const receivableID = receivableToAddBody.id;
          const receivableContent = receivableToAddBody.content;

          // get to delete receivableID
          const receivableIDToDelet = get_receivableToDeleteList();
          console.log(`receivableIDToDelet: ${JSON.stringify(receivableIDToDelet)}`)

          // get coupon balance on-hand to-delete change list
          const couponBalanceToDeleteList = get_toDeleteCouponBalanceList();

          // get coupon balance on-hand to-add change list
          const couponBalanceChangeList = get_couponBalanceChangeList();
          // console.log(`couponBalanceChangeList: ${JSON.stringify(couponBalanceChangeList)}`)

          // get coupon to use change list
          const couponToUseChangeList = get_couponToUseChangeList();

          // get expenditure list
          const product_expenditureBody = get_expenditureBody("product")
          const product_expenditureID = product_expenditureBody.id
          const product_expenditureContent = product_expenditureBody.content

          const coupon_expenditureBody = get_expenditureBody("coupon")
          const coupon_expenditureID = coupon_expenditureBody.id
          const coupon_expenditureContent = coupon_expenditureBody.content
          // console.log (`get_expenditureBody()  :${JSON.stringify(get_expenditureBody())}`)

          // get actucal sales amount
          // const actualSales = get_actualSales();
        

          if(data_customerID == "" || data_customerName == ""){
            hideAlert();
            setFetchingStatus("");
            showAlert(false,Loc.failed,Loc.please_select_customer)
            
          }else{
            do_submit(
              commissionID,
              commissionContent,
              receivableIDToDelet,
              receivableID,
              receivableContent,
              inventoryBalanceChangeList,
              couponBalanceToDeleteList,
              couponBalanceChangeList,
              couponToUseChangeList,
              product_expenditureID,
              product_expenditureContent,
              coupon_expenditureID,
              coupon_expenditureContent
            ).then((salesID) => {
              if (salesID != null) {
                console.log("do_submit done");
                showAlert(true, Loc.success, Loc.data_save_success);
                setFetchingStatus("");
              } else {
                console.log("create sales record fail");
                showAlert(false, Loc.failed, Loc.data_save_failed);
                setFetchingStatus("");
              }
            });
            hideAlert();
            // setFetchingStatus("");
  
            
          }

          // submit and create/update sales order
          
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.default}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      >
        <GridContainer>
          <GridItem xs={12}>
            {data_receive < 0 ? (
              <Danger>{Loc.sales_amount_receive + ":" + roundValue(data_receive)}</Danger>
            ) : (
              Loc.sales_amount_receive + ":" + roundValue(data_receive)
            )}
          </GridItem>
          <GridItem xs={12}>
            {Loc.sales_amount_net_receive + ":" + data_receive_net}
          </GridItem>
          <GridItem xs={12}>
            {data_receive_net < data_receive ? (
              <Danger>
                {Loc.sales_amount_receivable +
                  ":" +
                  roundValue(data_receive - data_receive_net)}
              </Danger>
            ) : (
              Loc.sales_amount_change + ":" + roundValue((data_receive_net - data_receive))
            )}
          </GridItem>
        </GridContainer>
      </SweetAlert>
    );
  };

  //   function get_actualSales()
  //   {
  //       var total = parseFloat(0.0);

  //       // calculate all "item" type product sales
  //       data_itemsInPackage.map((iip, k_iip) => {
  //           if (iip.type === "item")
  //           {
  //             total += parseFloat(iip.price) * parseFloat(iip.qty);
  //           }
  //       })

  //       console.log("temp total actual sales amount: ", total);

  //       // calculate all redeem item's sales price
  //       data_couponToUse.map((ctu, k_ctu) => {
  //         total += parseFloat(ctu.exchange_salesPrice) * parseFloat(ctu.use);
  //       })

  //       console.log("final total actual sales amount: ", total);

  //       return total;
  //   }

  function get_commissionAmount(
    targetItemsInPackageData,
    targetUsedCouponData
  ) {
    var amount_total = 0;
    // calculate with items in package
    targetItemsInPackageData.map((prop, key) => {
      var targetCollection = null;
      var amount = 0;
      if (prop.type === "item") {
        targetCollection = data_dataListArray["Product"];
      } else if (prop.type === "coupon") {
        targetCollection = data_dataListArray["Product-Coupon"];
      } else if (
        prop.type === "package" ||
        prop.type === "package_coupon" ||
        prop.type === "package_product"
      ) {
        targetCollection = data_dataListArray["Product-Package"];
      }

      if (targetCollection != null) {
        targetCollection.map((item, key) => {
          if (item.id === prop.id) {
            // get item/package commission
            amount += parseFloat(item.commission) * parseFloat(prop.qty);
          }
        });
      } else {
        onDone(null);
        console.log("targetCollection is null");
      }

      amount_total += amount;
    });

    // calculate with used coupon
    targetUsedCouponData.map((c_used, k) => {
      const usedQty = c_used.use;
      if (
        !c_used.isExchange ||
        c_used.isExchange === null ||
        c_used.isExchange === false
      ) {
        amount_total +=
          parseFloat(c_used.exchange_commission) * parseFloat(usedQty);
      }
    });

    return amount_total;
  }
//package 100+9 sales price /qty+free qty *free qty
//
  function get_expenditureBody(type) {
    // var expenditureBodyList = []
    // console.log(`get_expenditureList  ${JSON.stringify(data_itemsInPackage)}`)
    const isProduct = (type == "product") //|| type == "coupon")
      var product_expenditure_amount = 0
      var coupon_expenditure_amount = 0
      var idToPass = isEdit ? (isProduct ? data_product_expenditureID: data_coupon_expenditureID) : null;
      idToPass = idToPass === "" ? null : idToPass;
      var itemName = ""
      const expType = isProduct ? Loc.discount_allowed_sales : Loc.discount_allowed_prepaid;

      data_itemsInPackage.map(item=>{
        // console.log("idToPass: ", idToPass); 
        if (itemName == ""){
          itemName += expType + ": " + item.itemName
        }else{
          itemName += ", "+item.itemName
        }

        if(isProduct){
          product_expenditure_amount += item.discount
        }else{
          data_dataListArray["Product-Package"].map((p, k) => {
            if (p.id === item.id) {
              // loop items in package
              p.itemsInPackage.map((i, k_i) => {
                if (i.type === "coupon") {
                  const qty = i.qty;
                  const qty_free = (i.qty_free && i.qty_free !== null) ? i.qty_free : 0;
                  const max_qty = qty + qty_free
            
                  coupon_expenditure_amount += (((item.price)/(max_qty-qty_free)) * qty_free)*item.qty
                  // console.log("coupon_expenditure_amount    "+coupon_expenditure_amount)
        
                
                }
              });
            }
          });

        }
       

      })
      //discount case
      const body = {
        id:idToPass,
        content:{
            itemName:itemName,
            amount:isProduct ? product_expenditure_amount:coupon_expenditure_amount,
            remark:data_remark,
            date:new Date(data_date).getTime(),
            storeID:data_storeID,
            storeName:data_storeName,
            staffName:data_staffName,
            type: isProduct ? "discount_allowed_sales":"discount_allowed_prepaid",
            staffID: "",
            isConfidential:false,
        }
      }

      return body
      // return expenditureBodyList
  }



  function get_commissionBody() {
    const amount_total_new = get_commissionAmount(
      data_itemsInPackage,
      data_couponToUse
    );
    const diffValue = amount_total_new - data_commissionAmount_org;
    // console.log("amount_total_new: ", amount_total_new);
    // console.log("data_commissionAmount_org: ", data_commissionAmount_org);

    const idToPass = isEdit ? data_commissionID : null;


    const body = {
      id: idToPass,
      content: {
        itemName: Loc.commission_expenditure,
        amount: amount_total_new,
        remark: "",
        date: new Date(data_date).getTime(),
        staffID: data_staffID,
        storeID: data_storeID,
        storeName: data_storeName,
        staffName: data_staffName,
        type: "sales_commission",
        diffValue: diffValue,
      },
    };

    return body;
  }

  function get_inventoryBalanceChangeList() {
    if (!isEdit) {
      var inventoryBalanceChangeList = [];
      // create coupon balance with items in cart
      data_itemsInPackage.map((item, key) => {
        if (item.type === "item") {
          const newChange = {
            id: item.id,
            type: item.type,
            change_val: -item.qty,
            itemName: item.itemName,
          };
          inventoryBalanceChangeList.push(newChange);
        } else if (item.type === "package_product") {
          //prop.type === "package" ||prop.type === "package_coupon" ||prop.type === "package_product"
          data_dataListArray["Product-Package"].map((p, key) => {
            if (p.id === item.id) {
              const itemsInPackage = p.itemsInPackage;
              itemsInPackage.map((i, k) => {
                if (i.type === "item") {
                  const newChange = {
                    id: i.id,
                    type: i.type,
                    change_val: -i.qty * item.qty,
                    itemName: item.itemName,
                  };
                  inventoryBalanceChangeList.push(newChange);
                }
              });
            }
          });
        }
      });

      // create change list with used coupon
      data_couponToUse.map((c_use, k_use) => {
        const couponID = c_use.couponID;
        const useQty = c_use.use;
        data_dataListArray["Product-Coupon"].map((c, k_c) => {
          if (c.id === couponID) {
            const exchange_itemID = c.exchange_itemID;
            data_dataListArray["Product"].map((i, k_i) => {
              if (i.id === exchange_itemID) {
                const newChange = {
                  id: i.id,
                  type: "item",
                  change_val: -useQty,
                  itemName: i.itemName,
                };
                inventoryBalanceChangeList.push(newChange);
              }
            });
          }
        });
      });

      return inventoryBalanceChangeList;
    } else {
      // edit inventory balance
      var inventoryBalanceChangeList = [];

      // find match ID and [change] and [only in new]
      data_itemsInPackage.map((item, key) => {
        var changeVal = 0;
        var inNewOnly = true;

        // get change val
        data_OrgItemsInPackage.map((oldItem, key) => {
          if (oldItem.id === item.id) {
            inNewOnly = false;
            changeVal = oldItem.qty - item.qty;
          }
        });

        if (inNewOnly) {
          changeVal = -item.qty;
        }

        if (changeVal != 0) {
          if (item.type === "item") {
            const newChange = {
              id: item.id,
              type: item.type,
              change_val: changeVal,
              itemName: item.itemName,
            };
            inventoryBalanceChangeList.push(newChange);
          } else if (item.type === "package") {
            data_dataListArray["Product-Package"].map((p, k) => {
              if (p.id === item.id) {
                p.itemsInPackage.map((i, k) => {
                  const newChange = {
                    id: i.id,
                    type: i.type,
                    change_val: changeVal * i.qty,
                    itemName: i.itemName,
                  };
                  inventoryBalanceChangeList.push(newChange);
                });
              }
            });
          }
        }
      });

      // find [only in old]
      data_OrgItemsInPackage.map((oldItem, key) => {
        var inOldOnly = true;
        data_itemsInPackage.map((item, key) => {
          if (oldItem.id === item.id) {
            inOldOnly = false;
          }
        });

        if (inOldOnly) {
          if (oldItem.type === "item") {
            const newChange = {
              id: oldItem.id,
              type: oldItem.type,
              change_val: oldItem.qty,
              itemName: "",
            };
            inventoryBalanceChangeList.push(newChange);
          } else if (oldItem.type === "package") {
            data_dataListArray["Product-Package"].map((p, k) => {
              if (p.id === oldItem.id) {
                p.itemsInPackage.map((i, k) => {
                  const newChange = {
                    id: i.id,
                    type: i.type,
                    change_val: oldItem.qty * i.qty,
                    itemName: "",
                  };
                  inventoryBalanceChangeList.push(newChange);
                });
              }
            });
          }
        }
      });
      return inventoryBalanceChangeList;
    }
  }

  function get_receivableToAddBody() {
    if (data_receive_net < data_receive) {
      const receivable_val_new = data_receive - data_receive_net;
      var idToPass = isEdit ? data_receivableID : null;
      idToPass = idToPass === "" ? null : idToPass;
      console.log("idToPass: ", idToPass);

      const diffVal = receivable_val_new - data_receiveable_org;

      const body = {
        id: idToPass,
        content: {
          itemName: data_name,
          amount: receivable_val_new,
          remark: Loc.sales_receivable,
          date: new Date(data_date).getTime(),
          debtorID: data_customerID,
          debtorCode: data_customerCode,
          debtorName: data_customerName,
          debtorPhone: data_customerPhone,
          storeID: data_storeID,
          storeName: data_storeName,
          type: "+",
          paymentID: data_paymentID,
          paymentName: data_paymentName,
          diffValue: diffVal,
        },
      };

      return body;
    } else {
      const body = {
        id: null,
        content: null,
      };
      return body;
    }
  }

  function get_receivableToDeleteList() {
    if (data_receive_net >= data_receive) {
      if (data_receivableID != "") {
        return [data_receivableID];
        // const body = {
        //     "id": data_receivableID,
        //     "data_targetCollection":"Receivable",
        // }

        // fetchAPI.do_fetch('post', 'admin/del-item', body)
        // .then((res) => {
        //     console.log("success: ", res.data);
        //     onDone(data_receivableID);
        // }, error => {
        //     console.log("failed: ", error);
        //     onDone(null);
        // })
      } else {
        return [];
      }
      // else
      // {
      //     onDone(data_receivableID);
      // }
    } else {
      return [];
    }
  }

  function do_submit(
    commissionID,
    commissionContent,
    receivableToDeleteList,
    receivableID,
    receivableContent,
    inventoryBalanceChangeList,
    couponBalanceToDeleteList,
    couponBalanceChangeList,
    couponToUseChangeList,
    product_expenditureID,
    product_expenditureContent,
    coupon_expenditureID,
    coupon_expenditureContent,
  ) {
    return new Promise((onDone) => {
      if (validCheck()) {
        // console.log("data_itemsInPackage: ", JSON.stringify(data_couponToUse));

        // console.log(`data_storeID  ${data_storeID}  data_storeName ${data_storeName} paymentID  ${data_paymentID} data_paymentName  ${data_paymentName} staffID  ${data_staffID}  data_staffName  ${data_staffName} `)
        const idToPass = isEdit ? data_id : null;
        var body = {
          id: idToPass,
          content: {
            itemName: data_name,
            date: new Date(data_date).getTime(),
            couponUsageList: data_couponToUse,
            itemsInPackage: data_itemsInPackage,
            totalReceive: data_receive,
            totalReceive_net: data_receive_net,
            // "actualSales":actualSales,
            remark: data_remark,
            customerID: data_customerID,
            customerCode: data_customerCode,
            customerName: data_customerName,
            storeID: data_storeID,
            storeName: data_storeName,
            staffID: data_staffID,
            staffName: data_staffName,
            paymentID: data_paymentID,
            paymentName: data_paymentName,
            paymentName: data_paymentName,
          },
          commissionID: commissionID,
          commission_content: commissionContent,
          receivableIDToDelet: receivableToDeleteList,
          receivableID: receivableID,
          receivable_content: receivableContent,
          inventoryBalanceChangeList: inventoryBalanceChangeList,
          couponBalanceRecordToDelete: couponBalanceToDeleteList,
          couponBalanceChangeList: couponBalanceChangeList,
          couponBalanceChangeList_toUse: couponToUseChangeList,
           // TODO add expenditure 
          product_expenditureID:product_expenditureID,
          coupon_expenditureID:coupon_expenditureID,
          product_expenditure_content:product_expenditureContent,
          coupon_expenditure_content: coupon_expenditureContent
        };

        // console.log("create sales body: ", body);
        set_isLoading(true)
        fetchAPI.do_fetch("post", "admin/create-sales", body).then(
          (res) => {
            const item_id = res.data.item_id;
            set_isLoading(false)
            onDone(item_id);
          },
          (error) => {
            console.log("failed: ", error);
            set_isLoading(false)
            onDone(null);
          }
        );
      } else {
        onDone(null);
      }
    });
  }

  function do_handleCouponBalance() {
    return new Promise((onDone) => {
      const toDeleteList = get_toDeleteCouponBalanceList();
      const balanceChangeList = get_couponBalanceChangeList();

      if (toRemoveRecordList.length != 0) {
        delete_couponBalanceRecord(toRemoveRecordList).then((isdeleted) => {
          if (isdeleted) {
            if (balanceChangeList.length != 0) {
              submit_couponBalanceRecord(balanceChangeList).then((idList) => {
                onDone(idList);
              });
            } else {
              onDone([]);
            }
          } else {
            onDone(null);
          }
        });
      } else {
        if (balanceChangeList.length != 0) {
          submit_couponBalanceRecord(balanceChangeList).then((idList) => {
            onDone(idList);
          });
        } else {
          onDone([]);
        }
      }
    });
  }

  function get_couponBalanceChangeList() {
    var balanceChangeList = [];

    // find new and edited buy record
    data_itemsInPackage.map((item, key) => {
      if (item.isNeedToUpdate)
      {
        if (item.type === "coupon") 
        {
          const couponID = item.id;
          const qty = item.qty;
          const couponName = item.itemName;
          
          var couponBalanceID = null;
          data_customerCoupon.map((i, k) => {
            if (i.cart_uuid === item.cart_uuid) {
              couponBalanceID = i.id;
            }
          });

          const data = {
            id: couponBalanceID,
            content: {
              couponID: couponID,
              couponName: couponName,
              max_qty: qty,
              qty: qty,
              price: item.price * qty, // total price
              customerID: data_customerID,
              customerCode: data_customerCode,
              cart_uuid: item.cart_uuid,
            },
          };
          balanceChangeList.push(data);
        } 
        else if (item.type === "package_coupon") 
        {
          // get package data
          data_dataListArray["Product-Package"].map((p, k) => {
            if (p.id === item.id) {
              // loop items in package
              p.itemsInPackage.map((i, k_i) => {
                if (i.type === "coupon") {
                  const couponID = i.id;
                  const couponName = i.itemName;
                  const qty = i.qty;
                  const qty_free = (i.qty_free && i.qty_free !== null) ? i.qty_free : 0;

                  var couponBalanceID = null;
                  data_customerCoupon.map((c, k) => {
                    if (
                      c.cart_uuid === item.cart_uuid &&
                      c.couponID === couponID
                    ) {
                      couponBalanceID = c.id;
                    }
                  });

                  const data = {
                    id: couponBalanceID,
                    content: {
                      couponID: couponID,
                      couponName: couponName,
                      max_qty: (qty + qty_free) * item.qty,
                      free_qty: (qty_free) * item.qty,
                      qty: (qty + qty_free) * item.qty,
                      price: (item.price * item.qty) / item.itemsInPackage.length, // total price for each itemSet in package
                      customerID: data_customerID,
                      customerCode: data_customerCode,
                      cart_uuid: item.cart_uuid,
                    },
                  };
                  balanceChangeList.push(data);
                }
              });
            }
          });
        }
      }
      
    });
    return balanceChangeList;
  }

  function get_toDeleteCouponBalanceList() {
    // find old and removed buy record, and delete
    var toRemoveRecordList = [];
    data_OrgItemsInPackage.map((i_org, k_org) => {
      var isExist = false;
      data_itemsInPackage.map((i_new, k_new) => {
        if (i_org.id === i_new.id && i_org.cart_uuid === i_new.cart_uuid) {
          isExist = true;
        }
      });

      console.log("isExist: ", isExist);
      if (!isExist) {
        console.log("i_org.type: ", i_org.type);
        if (i_org.type === "coupon" || i_org.type === "package_coupon") {
          data_customerCoupon.map((c, k) => {
            console.log("checking id: ", c.cart_uuid, " vs ", i_org.cart_uuid);
            if (c.cart_uuid === i_org.cart_uuid) {
              toRemoveRecordList.push(c.id);
            }
          });
        }
      }
    });

    // console.log("toRemoveRecordList: ", toRemoveRecordList);
    return toRemoveRecordList;
  }

  function submit_couponBalanceRecord(balanceChangeList) {
    return new Promise((onDone) => {
      const body = {
        changeList: balanceChangeList,
        customerID: data_customerID,
        customerCode: data_customerCode,
      };
      fetchAPI.do_fetch("post", "admin/create-coupon-balance", body).then(
        (res) => {
          console.log("do_handleCouponBalance success");
          onDone(res.data.idList);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function delete_couponBalanceRecord(listToDelete) {
    return new Promise((onDone) => {
      const body = {
        idList: listToDelete,
        data_targetCollection: "Coupon-Balance",
      };

      fetchAPI.do_fetch("post", "admin/del-item", body).then(
        (res) => {
          // console.log("success: ", res.data);
          onDone(true);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(false);
        }
      );
    });
  }

  function get_couponToUseChangeList() {
    var listToUpdate = [];

    if (!isEdit) {
      data_couponToUse.map((c_toUse, key_toUse) => {
        data_customerCoupon.map((c_onHand, key_onHand) => {
          if (c_toUse.id === c_onHand.id) {
            const changeItem = {
              id: c_onHand.id,
              content: {
                qty: c_onHand.qty - c_toUse.use,
              },
            };
            listToUpdate.push(changeItem);
          }
        });
      });
    } else {
      // find new use and edited use
      data_couponToUse.map((c_new, k_new) => {
        var newQty = c_new.use;
        var oldQty = 0;

        data_orgCouponToUse.map((c_org, k_org) => {
          if (c_new.id === c_org.id) {
            oldQty = c_org.use;
          }
        });

        data_customerCoupon.map((c_onHand, key_onHand) => {
          if (c_new.id === c_onHand.id) {
            const changeItem = {
              id: c_onHand.id,
              content: {
                qty: c_onHand.qty - (newQty - oldQty),
              },
            };
            listToUpdate.push(changeItem);
          }
        });
      });

      // find removed old use
      data_orgCouponToUse.map((c_org, k_org) => {
        var isExist = false;
        data_couponToUse.map((c_new, k_new) => {
          if (c_org.id === c_new.id) {
            isExist = true;
          }
        });

        if (!isExist) {
          data_customerCoupon.map((c_onHand, key_onHand) => {
            if (c_org.id === c_onHand.id) {
              const changeItem = {
                id: c_org.id,
                content: {
                  qty: c_onHand.qty + c_org.use,
                },
              };
              listToUpdate.push(changeItem);
            }
          });
        }
      });
    }

    // console.log("get_couponToUseChangeList listToUpdate: ", listToUpdate);
    return listToUpdate;
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/sales-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_totalAmount(0);
            setData_totalDiscount(0);
            setData_receive(0);
            setData_receive_net(0);
            setData_remark("");
            setData_itemsInPackage([]);
            process_itemsInPackageTableList([]);
            setData_date(new Date());
            setData_couponToUse([]);
            process_couponToUseTableList([]);
            setData_customerCoupon([]);
            setData_customerCouponTableList([]);

            setData_customerID("");
            setData_customerCode("");
            setData_customerName("");
            setData_customerPhone("");
            setData_selectedCustomerData(null);
            setData_selectedCustomerDataReceivableBalance(null);
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }
  function showConfirmRemoveFromPackageAlert(itemKey, dataSet) {
    setConfirmAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_delete_title}
        onConfirm={() => {
          var newItemsInPackage = dataSet;
          newItemsInPackage.splice(itemKey, 1);
          setData_itemsInPackage(newItemsInPackage);
          process_itemsInPackageTableList(newItemsInPackage);
          calculateAmounts(newItemsInPackage);
          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }



  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{ pathname: pathToRedirect, state: historyState.config }}
        />
      );
    } else {
      return null;
    }
  };

  function process_customerCouponTableList(targetData, couponUsageRecords) {
    // console.log("process_customerCouponTableList targetData: ", targetData);
    if (targetData.length != 0) {
      const list = targetData.map((prop, key) => {
        const couponBalanceID = prop.id;
        const couponID = prop.couponID;
        var couponName = "-";
        data_dataListArray["Product-Coupon"].map((coupon, key) => {
          if (coupon.id === couponID) {
            couponName = coupon.itemName;
          }
        });
        // var qty_toUse = 0;
        // data_couponToUse.map((item, key) => {
        //     if (item.id === couponBalanceID)
        //     {
        //         qty_toUse = item.use;
        //     }
        // })

        const max = prop.max_qty;
        var current = prop.qty;
        // if (qty_toUse != 0)
        // {
        //     current += "(" + -qty_toUse + ")";
        // }
        const qtyStrToShow = current + "/" + max;

        return [
          key + 1,
          couponName,
          qtyStrToShow,
          <Button
            color="success"
            simple
            className={classes_tableBtn.actionButton}
            onClick={(e) => {
              do_addCouponToUse(prop, couponUsageRecords);
            }}
          >
            <ICON_ADD className={classes_tableBtn.icon} />
          </Button>,
        ];
      });
      setData_customerCouponTableList(list);
    } else {
      setData_customerCouponTableList([]);
    }
  }

  function do_addCouponToUse(couponBalanceRecord, couponUsageRecords) {
    const couponBalanceID = couponBalanceRecord.id;
    var newList = couponUsageRecords;

    // check if exist
    var indexOfRecord = -1;
    newList.map((item, key) => {
      console.log("checking id: ", item.id, " vs ", couponBalanceID);
      if (item.id === couponBalanceID) {
        indexOfRecord = key;
      }
    });

    console.log("indexOfRecord: ", indexOfRecord);

    // balance record exist
    if (indexOfRecord != -1) {
      const rec = newList[indexOfRecord];
      rec.use =
        rec.use + 1 > couponBalanceRecord.qty
          ? couponBalanceRecord.qty
          : rec.use + 1;
      rec.currentDuration = rec.duration * rec.use;
    }
    // balance record not exist
    else {
      // get exchange_item
      var exchange_itemID = "";
      var exchange_itemName = "";
      var exchange_commission = 0.0;
      var exchange_salesPrice =
        parseFloat(couponBalanceRecord.price) /
        (parseFloat(couponBalanceRecord.max_qty) - parseFloat((couponBalanceRecord.free_qty && couponBalanceRecord.free_qty !== null) ? couponBalanceRecord.free_qty : 0));
      // console.log(`couponBalanceRecord.free_qty  ${couponBalanceRecord.free_qty}  `)
      var duration = 0;
      data_dataListArray["Product-Coupon"].map((c, k_c) => {
        if (c.id === couponBalanceRecord.couponID) {
          exchange_itemID = c.exchange_itemID;
          data_dataListArray["Product"].map((i, k_i) => {
            if (i.id === exchange_itemID) {
              exchange_itemName = i.itemName;
              exchange_commission = i.commission;
              // exchange_salesPrice = i.price;

              duration = i.duration ? i.duration : 0;
            }
          });
        }
      });

      console.log("going to push new item");

      var itemToAdd = {
        id: couponBalanceID,
        couponID: couponBalanceRecord.couponID,
        qty: couponBalanceRecord.qty,
        use: 1,
        exchange_itemID: exchange_itemID,
        exchange_itemName: exchange_itemName,
        exchange_commission: parseFloat(exchange_commission),
        exchange_salesPrice: parseFloat(exchange_salesPrice),
        duration: parseFloat(duration),
        currentDuration: parseFloat(duration),
        isExchange: false,
      };
      newList.push(itemToAdd);
    }

    // console.log("do_addCouponToUse newList: ", newList);
    setData_couponToUse(newList);
    process_couponToUseTableList(newList);
  }

  function process_couponToUseTableList(rawData_couponToUseList) {
    // console.log("rawData_couponToUseList: ", rawData_couponToUseList);
    const list = rawData_couponToUseList.map((item, key_couponToUse) => {
      const couponID = item.couponID;
      const itemID = item.id;
      var itemName = "-";
      var isDeleted = false;
      const useQty = item.use;
      const duration = item.duration;
      const currentDuration = item.currentDuration;
      // console.log('hasDuration???????   ',hasDuration)
      // NOTE Is Exchange
      const isExchange = item.isExchange;
      data_dataListArray["Product-Coupon"].map((coupon, key) => {
        if (coupon.id === couponID) {
          itemName = coupon.itemName;
          isDeleted = false;
          if (coupon.isDeleted !== null) {
            if (coupon.isOld !== null) {
              isDeleted = coupon.isDeleted && !coupon.isOld;
            } else {
              isDeleted = coupon.isDeleted;
            }
          }
          itemName += isDeleted ? "(" + Loc.deleted + ")" : "";
        }
      });

      return [
        <span>{key_couponToUse + 1}</span>,
        isDeleted ? <Danger>{itemName}</Danger> : itemName,
        <CustomTextField
          label={Loc.quantity_short}
          id="quantity_short"
          // formControlProps={{
          //   fullWidth: true,
          // }}
          inputProps={{
            value: useQty,
            onChange: (event) => {
              if (event.target.value === "") {
                event.target.value = 0;
              }
              var newList = rawData_couponToUseList;
              var newQty = parseFloat(event.target.value);

              if (newQty > item.qty) {
                newQty = item.qty;
              } else {
                newList[key_couponToUse].use = newQty;
                newList[key_couponToUse].currentDuration = duration * newQty;
              }
              setData_couponToUse(newList);
              process_couponToUseTableList(newList);
            },
            onBlur: (event) => {
              var newList = rawData_couponToUseList;
              var newQty = parseFloat(event.target.value);
              if (newQty <= 0) {
                newList.splice(key_couponToUse);
              }
              setData_couponToUse(newList);
              process_couponToUseTableList(newList);
            },
            step: 0.5,
            type: "number",
          }}
        />,
        hasDuration && (
          <CustomInput
            labelText={Loc.duration_min}
            id="duration_min"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: currentDuration,

              onChange: (event) => {
                var newList = rawData_couponToUseList;
                var newMin = parseInt(event.target.value);

                if (newMin <= 0) {
                  newList[key_couponToUse].currentDuration = 0;
                } else {
                  newList[key_couponToUse].currentDuration = newMin;
                }

                setData_couponToUse(newList);
                process_couponToUseTableList(newList);
              },
              type: "number",
            }}
          />
        ),
        <FormControlLabel
          control={
            <Switch
              checked={isExchange || false}
              onChange={(event) => {
                var newList = rawData_couponToUseList;
                var checked = event.target.checked
                warningExchangeAlert(checked, newList,key_couponToUse);
              }}
              // value="checkedA"
              classes={{
                switchBase: classes_switch.switchBase,
                checked: classes_switch.switchChecked,
                thumb: classes_switch.switchIcon,
                track: classes_switch.switchBar,
              }}
            />
          }
          classes={{
            label: classes_switch.label,
          }}
          // label={Loc.exchange}
        />,
        <Button
          color="danger"
          simple
          className={classes_tableBtn.actionButton}
          onClick={(e) => {
            var newList = rawData_couponToUseList;
            newList.splice(key_couponToUse, 1);
            setData_couponToUse(newList);
            process_couponToUseTableList(newList);
          }}
        >
          <Close className={classes_tableBtn.icon} />
        </Button>,
      ];
    });

    setData_couponToUseTableList(list);
  }

  function process_itemsInPackageTableList(rawData_itemsInPackage) {
    // console.log("process_itemsInPackageTableList called");
    const list = rawData_itemsInPackage.map((prop, key_itemInPackage) => {
      const itemID = prop.id;
      var itemName = "";
      const qty = prop.qty;
      var isDeleted = false;
      var price = prop.price;
      var discount =
        rawData_itemsInPackage[key_itemInPackage].discount == null
          ? 0
          : rawData_itemsInPackage[key_itemInPackage].discount;

      // get item name
      if (prop.type === "item") {
        data_dataListArray["Product"].map((item, key) => {
          if (item.id === itemID) {
            itemName = item.itemName;
            isDeleted = item.isDeleted != null ? item.isDeleted : false;
            itemName += isDeleted ? "(" + Loc.deleted + ")" : "";
          }
        });
      }
      // get coupon name
      else if (prop.type === "coupon") {
        data_dataListArray["Product-Coupon"].map((coupon, key) => {
          if (coupon.id === itemID) {
            itemName = coupon.itemName;
            isDeleted = coupon.isDeleted != null ? coupon.isDeleted : false;
            itemName += isDeleted ? "(" + Loc.deleted + ")" : "";
          }
        });
      } else if (
        prop.type === "package" ||
        prop.type === "package_coupon" ||
        prop.type === "package_product"
      ) {
        data_dataListArray["Product-Package"].map((item, key) => {
          if (item.id === itemID) {
            itemName = item.itemName;
            isDeleted = item.isDeleted != null ? item.isDeleted : false;
            itemName += isDeleted ? "(" + Loc.deleted + ")" : "";
          }
        });
      } else {
        console.log("item type not match");
      }

      return [
        <span>{key_itemInPackage + 1}</span>,
        isDeleted ? <Danger>{itemName}</Danger> : itemName,

        <CustomTextField
          label={Loc.quantity_short}
          id="quantity_short"
          inputProps={{
            value: rawData_itemsInPackage[key_itemInPackage].qty,
            onChange: (event) => {
              var newItemsInPackage = rawData_itemsInPackage;
              if (event.target.value === "") {
                event.target.value = 0;
              }

              const newQty =
                event.target.value === "" ? 0 : parseFloat(event.target.value);
              newItemsInPackage[key_itemInPackage].qty = newQty;
              newItemsInPackage[key_itemInPackage].isNeedToUpdate = true;
              setData_itemsInPackage(newItemsInPackage);
              process_itemsInPackageTableList(newItemsInPackage);

              calculateAmounts(newItemsInPackage);
            },
            onBlur: (event) => {
              var newItemsInPackage = rawData_itemsInPackage;

              const newQty = parseFloat(event.target.value);
              if (newQty <= 0) {
                newItemsInPackage.splice(key_itemInPackage, 1);

                // showConfirmRemoveFromPackageAlert(
                //   key_itemInPackage,
                //   rawData_itemsInPackage
                // );
              } else {
                newItemsInPackage[key_itemInPackage].qty = newQty;
              }
              newItemsInPackage[key_itemInPackage].isNeedToUpdate = true;
              setData_itemsInPackage(newItemsInPackage);
              process_itemsInPackageTableList(newItemsInPackage);

              calculateAmounts(newItemsInPackage);
            },
            // step: 0.5,
            type: "number",
          }}
        />,

        <CustomInput
          labelText={Loc.sales_amount_discount}
          id="sales_amount_discount"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: rawData_itemsInPackage[key_itemInPackage].discount,
            onChange: (event) => {
              if (event.target.value !== "") {
                var newItemsInPackage = rawData_itemsInPackage;
                var newDiscount = parseFloat(event.target.value);
                if (newDiscount <= 0) {
                  newDiscount = 0;
                }

                newItemsInPackage[key_itemInPackage].discount = newDiscount;
                setData_itemsInPackage(newItemsInPackage);
                process_itemsInPackageTableList(newItemsInPackage);
                calculateAmounts(newItemsInPackage);
              }
            },
            type: "number",
          }}
        />,
        <span>{parseFloat(price * qty - discount).toLocaleString()}</span>,
        <Button
          color="danger"
          simple
          className={classes_tableBtn.actionButton}
          onClick={(e) => {
            showConfirmRemoveFromPackageAlert(
              key_itemInPackage,
              rawData_itemsInPackage
            );
          }}
        >
          <Close className={classes_tableBtn.icon} />
        </Button>,
      ];
    });

    setData_itemsInPackageTableList(list);
  }

  const getCustomerName = (targetID) => {
    var targetname = "";
    if (data_dataListArray["Customer"] != null) {
      data_dataListArray["Customer"].map((customer, key) => {
        if (customer.id === targetID) {
          targetname = customer.itemName;
        }
      });
    }

    return targetname;
  };

  function roundValue(value){
    return Math.floor(value * 100) / 100;
  }
  function calculateAmounts(targetData) {
    if (targetData.length != 0) {
      // console.log("calculateAmounts called");
      var total = 0;
      var discount = 0;
      var receive = 0;
      var exchange = 0;

      // console.log(`calculateAmounts  ${JSON.stringify(data_couponToUse)}`)

      data_couponToUse.map((item) => {
        const exchange_salesPrice =
        roundValue(item.exchange_salesPrice)//round down with 2 dic
          
        if (item.isExchange) {
          exchange += item.use * exchange_salesPrice;
        }
      });
      // console.log("exchange sale  ", exchange);

      targetData.map((prop, key) => {
        var price = prop.price;
        var dis_each = prop.discount;
        const itemID = prop.id;
        const qty = prop.qty;


        total += price * qty;
        discount += dis_each;
      });

      total = total - exchange;
      receive = total - discount;

      setData_totalAmount(total);
      setData_totalDiscount(discount);
      setData_receive(receive);
    } else {
      setData_totalAmount(0);
      setData_totalDiscount(0);
      setData_receive(0);
    }
  }

  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    if (fieldList && fieldList.length > 0) {
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    // console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownIDList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldIDList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldIDList.push(item.id);
      });
    }

    return fieldIDList;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  function getCustomerList(key = "", keyword = "", isDone) {
    var body = {
      page: data_page,
      pageCount: data_pageCount,
    };

    if (key !== "" && keyword !== "") {
      body = { ...body, searchKeyword: keyword, searchKey: key };
    }
    fetchAPI.do_fetch("post", "admin/list-customer", body).then(
      (res) => {
        set_isLoading(false);

        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
        if (key !== "" && keyword !== "") {
          isDone(res.data.data_list);
        }
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function _renderSearchBar() {

    const searchFieldList = [
      { itemName: Loc.code_old, key:["code"], id: "code", type: "text",isPreloadField:false },
      { itemName: Loc.customer_name, key: ["itemName"], id: "itemName", type: "text",isPreloadField:false },
      { itemName: Loc.customer_phone, key: ["phone"], id: "phone", type: "text",isPreloadField:false },
    ];

    // const searchFieldNameList = [];
    // const searchIDList = [];

    // searchFieldList.map((item) => {
    //   searchFieldNameList.push(item.itemName);
    //   searchIDList.push(item.id);
    // });

    return (
      <SearchBar
        searchFieldList={searchFieldList}
        // searchFieldNameList={searchFieldNameList}
        // searchIDList={searchIDList}
        apiPath={"admin/list-customer"}
        loadDataList={() => {
          // set_isLoading(true);
          getCustomerList();
        }}
        searchResultDataList={(dataList) => {
          const m = dataList.filter((v) => v.isDeleted !== true);
          console.log(`m ${JSON.stringify(m)}`)
          if (m.length === 1)
          {
            const prop = Array.from(m)[0];
            
            
            do_selectCustomer(prop, []);
            
          }
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        // didSelectedID={(id) => {
        //   setData_searchID(id);
        // }}
        showDelete={false}
        locationState={locationState}
      />
    );
  }

  function getUseCouponHeader() {
    var header = [];
    if (hasDuration) {
      header = [
        "#",
        Loc.coupon_name,
        Loc.quantity_short,
        Loc.duration_min,
        Loc.exchange,
        Loc.remove,
      ];
    } else {
      header = [
        "#",
        Loc.coupon_name,
        Loc.quantity_short,
        " ",
        Loc.exchange,
        Loc.remove,
      ];
    }

    return header;
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_save}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.sales_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_storeID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_storeName(
                          getStringToShow(
                            Loc.store_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Store"],
                            "Store",
                            selectedID
                          )
                        );
                        setData_storeID(selectedID);
                        console.log("selectedID  " + selectedID);
                        console.log("setData_storeName  " + data_storeName);
                      }}
                      inputProps={{
                        name: data_storeName,
                        id: data_storeID,
                      }}
                    >
                      {getSelectMenuItem("Store")}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem sm={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_staffID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_staffID(selectedID);
                        setData_staffName(
                          getStringToShow(
                            Loc.staff_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Staff"],
                            "Staff",
                            selectedID
                          )
                        );
                      }}
                      inputProps={{
                        name: data_staffName,
                        id: data_staffID,
                      }}
                    >
                      {getSelectMenuItem("Staff")}
                      {/* {} */}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem sm={4}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={true}
                      defaultValue={data_date}
                      value={data_date == null ? new Date() : moment(data_date)}
                      inputProps={{
                        placeholder: Loc.sales_date,
                      }}
                      onChange={(m) => {
                        if (moment.isMoment(m)) {
                          setdateState("success");
                          setData_date(m.toDate());
                        } else {
                          setdateState("error");
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <hr />
              <GridItem xs={12}>
                {/* <h5>{Loc.sales_customer}</h5> */}
                <GridContainer>
                  <GridItem xs={12}>
                    <Card>
                      <CardHeader color="info" text>
                        <CardText color="info">
                          <h4 className={classes.cardTitle}>{Loc.customer_info}</h4>
                        </CardText>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={4} align="left">
                            <small>{Loc.customer_id}</small>
                          </GridItem>
                          <GridItem xs={8} align="right">
                            {data_selectedCustomerData == null
                              ? "-"
                              : data_selectedCustomerData.id}
                          </GridItem>
                          <GridItem xs={4} align="left">
                            <small>{Loc.code_old}</small>
                          </GridItem>
                          <GridItem xs={8} align="right">
                            {data_selectedCustomerData == null
                              ? "-"
                              : data_selectedCustomerData.code}
                          </GridItem>

                          <GridItem xs={4} align="left">
                            <small>{Loc.customer_name}</small>
                          </GridItem>
                          <GridItem xs={8} align="right">
                            {data_selectedCustomerData == null
                              ? "-"
                              : data_selectedCustomerData.itemName}
                          </GridItem>

                          <GridItem xs={4} align="left">
                            <small>{Loc.customer_phone}</small>
                          </GridItem>
                          <GridItem xs={8} align="right">
                            {data_selectedCustomerData == null ||
                            data_selectedCustomerData.phone === ""
                              ? "-"
                              : data_selectedCustomerData.phone}
                          </GridItem>

                          <GridItem xs={4} align="left">
                            <small>{Loc.receivable_balance}</small>
                          </GridItem>
                          <GridItem xs={8} align="right">
                            {data_selectedCustomerData == null
                              ? "-"
                              : getReceivableBalanceContent(data_selectedCustomerDataReceivableBalance)}
                          </GridItem>

                          <GridItem xs={4} align="left">
                            <small>{Loc.remark}</small>
                          </GridItem>
                          <GridItem xs={8} align="right">
                            {data_selectedCustomerData == null ||
                            data_selectedCustomerData.remark === ""
                              ? "-"
                              : data_selectedCustomerData.remark}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>

                <Card>
                  <CardHeader color="info" text>
                    <CardText color="info">
                      <h4 className={classes.cardTitle}>{Loc.coupon_on_hand}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    {data_customerCouponTableList.length == 0 ? (
                      div_coupon_empty()
                    ) : (
                      <Table
                        tableHead={[
                          "#",
                          Loc.coupon_name,
                          Loc.coupon_balance_qty,
                          Loc.coupon_balance_use,
                        ]}
                        tableData={data_customerCouponTableList}
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.right,
                        ]}
                        customClassesForCells={[0, 5, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.right,
                          classes.right,
                        ]}
                        customHeadClassesForCells={[0, 5, 5]}
                      />
                    )}
                  </CardBody>
                </Card>
              </GridItem>
              <hr />
              <GridItem xs={12}>
                {/* <h5>{Loc.sales_item_list}</h5> */}
                <Card>
                  <CardHeader color="primary" text>
                    <CardText color="primary">
                      <h4 className={classes.cardTitle}>{Loc.sales_item_list}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    {data_itemsInPackage.length == 0 ? (
                      div_list_empty()
                    ) : (
                      <Table
                        tableHead={[
                          "#",
                          Loc.product,
                          Loc.quantity_short,
                          Loc.sales_amount_discount,
                          Loc.sales_item_subTotal,
                          Loc.remove,
                        ]}
                        tableData={data_itemsInPackageTableList}
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.right,
                        ]}
                        customClassesForCells={[0, 5, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.right,
                          classes.right,
                        ]}
                        customHeadClassesForCells={[0, 5, 5]}
                      />
                    )}
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader color="primary" text>
                    <CardText color="primary">
                      <h4 className={classes.cardTitle}>{Loc.coupon_balance_use}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    {data_couponToUseTableList.length == 0 ? (
                      div_couponToUse_empty()
                    ) : (
                      <Table
                        tableHead={getUseCouponHeader()}
                        tableData={data_couponToUseTableList}
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.right,
                        ]}
                        customClassesForCells={[0, 5, 5, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.right,
                          classes.right,
                          classes.right,
                        ]}
                        customHeadClassesForCells={[0, 5, 5, 5]}
                      />
                    )}
                  </CardBody>
                </Card>
                <hr />
                <GridContainer>
                  <GridItem xs={6} align="center">
                    <CustomDropdown
                      buttonProps={{
                        round: true,
                        color: "info",
                      }}
                      buttonText={
                        <span>
                          {getStringToShow(
                            Loc.payment_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Payment"],
                            "Payment",
                            data_paymentID
                          )}
                        </span>
                      }
                      dropdownList={getDropDownNameList("Payment")}
                      itemIDList={getDropDownIDList("Payment")}
                      onClick={(selectedID) => {
                        setData_paymentID(selectedID);
                        setData_paymentName(
                          getStringToShow(
                            Loc.payment_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Payment"],
                            "Payment",
                            selectedID
                          )
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>{Loc.sales_amount_total}</GridItem>
                      <GridItem xs={6} align="right">
                        {parseFloat(data_totalAmount).toFixed(2)}
                      </GridItem>

                      <GridItem xs={6}>{Loc.sales_amount_discount}</GridItem>
                      <GridItem xs={6} align="right">
                        {data_totalDiscount != 0 ? (
                          <Danger>
                            {parseFloat(-data_totalDiscount).toFixed(2)}
                          </Danger>
                        ) : (
                          "/"
                        )}
                      </GridItem>
                    </GridContainer>
                    <hr />
                    <GridContainer>
                      <GridItem xs={6}>
                        <Success>
                          <h4>{Loc.sales_amount_receive}</h4>
                        </Success>
                      </GridItem>
                      <GridItem xs={6} align="right">
                        <Success>
                          <h4>{parseFloat(data_receive).toFixed(2)}</h4>
                        </Success>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <hr />
              {/* <GridItem xs={12}>
                <CustomInput
                    labelText={Loc.remark}
                    id="remark"
                    formControlProps={{
                    fullWidth: true
                    }}
                    inputProps={{
                        value: data_remark,
                        onChange: event => {
                            setData_remark(event.target.value);
                        },
                        type: "text",
                    }}
                />
                </GridItem>  */}

              <GridItem xs={12}>
                <CustomInput
                  labelText={Loc.sales_amount_net_receive}
                  id="sales_amount_net_receive"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: data_receive_net,
                    onChange: (event) => {
                      setData_receive_net(parseFloat(event.target.value));
                    },
                    type: "number",
                  }}
                />
              </GridItem>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={data_receive < 0 ? warningAlert : doneClicked}
                    disabled={
                      (fetchingStatus === "fetching" ? true : false) ||
                      (data_itemsInPackage.length == 0 &&
                        data_couponToUse.length == 0)
                    }
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.search}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* <hr/> */}
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: Loc.customer_list,
                    tabContent:
                      customerData == null ? (
                        div_list_empty()
                      ) : (
                        <>
                          {_renderSearchBar()}
                          <PagingTable
                            tableHead={[
                              "#",
                              Loc.customer_name,
                              Loc.customer_phone,
                              Loc.select,
                            ]}
                            tableData={process_customerTableList(data_dataList)}
                            customCellClasses={[
                              classes_tableBtn.center,
                              classes_tableBtn.center,
                              classes_tableBtn.right,
                            ]}
                            customClassesForCells={[0, 0, 5]}
                            customHeadCellClasses={[
                              classes_tableBtn.center,
                              classes_tableBtn.center,
                              classes_tableBtn.right,
                            ]}
                            customHeadClassesForCells={[0, 0, 5]}
                            gotoPageClicked={(targetPage) => {
                              set_page(targetPage);
                              set_isLoaded(false);
                            }}
                            changedPageCount={(newPageCount) => {
                              set_page(0);
                              set_pageCount(newPageCount);
                              set_isLoaded(false);
                            }}
                            totalDataCount={data_dataListCount}
                            page={data_page}
                            pageCount={data_pageCount}
                          />
                        </>
                      ),
                  },
                  {
                    tabButton: Loc.product,
                    tabContent:
                      data_dataListArray == null ||
                      data_dataListArray["Product"] == null ||
                      process_itemTableList(data_dataListArray["Product"])
                        .length == 0 ? (
                        div_list_empty()
                      ) : (
                        <ReactTable
                          columns={[
                            {
                              Header: Loc.product_name,
                              accessor: "itemName",
                            },
                            {
                              Header: Loc.product_price,
                              accessor: "price",
                            },
                            {
                              Header: Loc.add,
                              accessor: "actions",
                            },
                          ]}
                          data={
                            data_dataListArray["Product"] == null
                              ? []
                              : process_itemTableList(
                                  data_dataListArray["Product"]
                                )
                          }
                        />
                      ),
                  },
                  {
                    tabButton: Loc.coupon,
                    tabContent:
                      data_dataListArray == null ||
                      data_dataListArray["Product-Coupon"] == null ||
                      process_couponTableList(
                        data_dataListArray["Product-Coupon"]
                      ).length == 0 ? (
                        div_list_empty()
                      ) : (
                        <ReactTable
                          columns={[
                            {
                              Header: Loc.coupon_name,
                              accessor: "itemName",
                            },
                            {
                              Header: Loc.coupon_price,
                              accessor: "price",
                            },
                            {
                              Header: Loc.add,
                              accessor: "actions",
                            },
                          ]}
                          data={
                            data_dataListArray["Product-Coupon"] == null
                              ? []
                              : process_couponTableList(
                                  data_dataListArray["Product-Coupon"]
                                )
                          }
                        />
                      ),
                  },
                  {
                    tabButton: Loc.package,
                    tabContent:
                      data_dataListArray == null ||
                      data_dataListArray["Product-Package"] == null ||
                      process_packageTableList(
                        data_dataListArray["Product-Package"]
                      ).length == 0 ? (
                        div_list_empty()
                      ) : (
                        <ReactTable
                          columns={[
                            {
                              Header: Loc.package_name,
                              accessor: "itemName",
                            },
                            {
                              Header: Loc.package_price,
                              accessor: "price",
                            },
                            {
                              Header: Loc.add,
                              accessor: "actions",
                            },
                          ]}
                          data={
                            data_dataListArray["Product-Package"] == null
                              ? []
                              : process_packageTableList(
                                  data_dataListArray["Product-Package"]
                                )
                          }
                        />
                      ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
