import React from "react";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function StoreDropdown(props) {

    const classes = useStyles();
    const [data_storeData, setData_storeData] = React.useState(null);
    const [data_storeID, setData_storeID] = React.useState("");
    const [data_storeList, setData_storeList] = React.useState([]);
    const [data_storeIDList, setData_storeIDList] = React.useState([]);
    const data_targetCollection = "Store";

    React.useEffect(() => {  
        // pre load data for edit
        var p_loadStore = do_loadStoreList();

        if (props.storeID != null && props.storeID != "")
        {
            setData_storeID(props.storeID);
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            p_loadStore = null;
        };
    }, [props.storeID]);

    function do_loadStoreList()
    {
        if (data_storeData == null)
        {
            const body = {
                "data_targetCollection":data_targetCollection,
            }
            fetchAPI.do_fetch('post', 'admin/list-item', body)
            .then((res) => {
                console.log("success: ", res.data);
                setData_storeData(res.data);
                processData(res.data);
            }, error => {
                console.log("failed: ", error);
            })
        }
        else
        {
            processData(data_storeData);
        }
    }

    function processData(targetData)
    {
        var newStoreList = [];
        var newStoreIDList = [];
        targetData.filter(v => v.isDeleted != true).map((prop, key) => {
            const item_id = prop.id;
            const itemName = prop.itemName;

            const selection_store = (
                <span>{itemName}</span>
            );

            newStoreList.push(selection_store);
            newStoreIDList.push(item_id);
        });

        if (newStoreList.length == 0)
        {
            newStoreList.push(<span>{Loc.store_default}</span>);
            newStoreIDList.push("");
        }

        setData_storeList(newStoreList);
        setData_storeIDList(newStoreIDList);
        if (props.storeID === "")
        {
            console.log("props.storeID is empty!!!!!!!!!!!!!!!!");
            props.didSelectedStore(newStoreIDList[0]);
        }
    }

    const getStoreStringToShow = () => {
        var strToShow = Loc.store_default;
        if (data_storeData != null)
        {
            strToShow = data_storeList[0];
            data_storeData.map((prop, key) => {
                if (prop.id === data_storeID)
                {
                    strToShow = prop.itemName;
                }
            });
        }

        return strToShow;
    }

    return(
        <CustomDropdown
            buttonProps={{
                round: true,
                color: "info",
            }}
            buttonText={<span>{getStoreStringToShow()}</span>}
            dropdownList={data_storeList}
            itemIDList={data_storeIDList}
            onClick={(selectedID) => {
                setData_storeID(selectedID);
                props.didSelectedStore(selectedID);
            }}
        />
    );
}