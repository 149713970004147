import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import moment from "moment";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_ADD from "@material-ui/icons/Add";
import Danger from "components/Typography/Danger";

import { saveAs } from "file-saver";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

export default function Product_list(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  };
  const [pathToRedirect, setRedirect] = React.useState("");
  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);

  const [data_itemList, setData_itemList] = React.useState(null);
  const [data_couponList, setData_couponList] = React.useState(null);
  const [
    data_inventoryBalanceList,
    setData_inventoryBalanceList,
  ] = React.useState(null);

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [locationState, setLocationState] = React.useState(null);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);
  const [showDeletedData, setShowDeletedData] = React.useState(false);

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());

  React.useEffect(() => {
    // do_preLoadData().then((balanceData) => {

    // });

    // if (data_itemList != null && data_couponList != null && data_inventoryBalanceList != null)
    // {
    //   processDataWithActions(data_inventoryBalanceList);
    // }
    if (locationState == null) {
      // setLocationState(props.location.state);
      var preloadListStr = localStorage.getItem("preloadDataList")
      var preloadList = JSON.parse(preloadListStr);
      // console.log("preloadList  "+JSON.stringify(item))
      setLocationState(preloadList);
      // setData_dataListArray(preloadList);
    } else {
      if (data_dataList != null) {
        processDataWithActions();
      }

      if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
        // set_isLoaded(true);
        // do_loadDataList();
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [
    data_pageCount,
    data_page,
    data_dataList,
    locationState,
    showDeletedData,
  ]);

  var xls = require("exceljs");

  async function operation() {
    var workbook = new xls.Workbook();

    workbook.created = new Date();

    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("sheet");

    //calcualte part

    // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
    // //merge cell
    // worksheet.mergeCells("A1:"+headerEndColumn+"1");
    // worksheet.mergeCells("A2:"+headerEndColumn+"2");

    worksheet.columns = getExcelColumns();

    const tableHeaderList = tableHeader.map((item) => {
      if (item !== Loc.actions) {
        return item;
      }
    });
    //Fixed Items
    const rows = [tableHeaderList];
    // //columns
    const titleRows = worksheet.getRow(1);

    // // // report_itemName
    titleRows.values = tableHeaderList;
    titleRows.alignment = { vertical: "middle", horizontal: "center" };

    // console.log("getExcelData()   "+JSON.stringify(getExcelData()))
    // worksheet.addRows(rows);
    //add data part
    worksheet.addRows(getExcelData());

    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    const fileName = `${pageName}_${moment(data_startDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}~${moment(data_endDate).format("YYYY-MM-DD-hh-mm-ss")}`;
    saveAs(blob, fileName + fileExtension);
  }

  function getExcelColumns() {
    var columns = [];
    tableHeader.map((item) => {
      if (item != Loc.actions)
        columns.push({ header: "", key: item, width: 20 });
    });

    return columns;
  }

  function getExcelData() {
    const newData = data_dataList.map((prop, key) => {
      const itemName = prop.itemName;
      const typeStr = getItemTypeStr(prop.type);
      const inventory = parseFloat(prop.qty).toLocaleString();
      const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");

      return [key + 1, itemName, typeStr, date, inventory];
    });

    return newData;
  }

  function do_loadDataList() {
    set_isLoading(true);
    const body = {
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: showDeletedData,
    };
    fetchAPI.do_fetch("post", "admin/list-inventory-balance", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
      }
    );
  }

  function getItemTypeStr(type) {
    var strToReturn = "";

    if (type === "item") {
      strToReturn = Loc.type_product;
    } else if (type === "coupon") {
      strToReturn = Loc.type_coupon;
    } else {
      strToReturn = "/";
    }

    return strToReturn;
  }

  function processDataWithActions() {
    const newData = data_dataList
      // .filter(v => v.isDeleted != true)
      .map((prop, key) => {
        const product_id = prop.id;
        const itemName = prop.itemName;
        const typeStr = getItemTypeStr(prop.type);
        const inventory = parseFloat(prop.qty).toLocaleString();
        // const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
        var onlyDateStr = moment(prop.last_update._seconds * 1000).format('DD MMM YYYY');
        var onlyTimeStr = moment(prop.last_update._seconds * 1000).format('h:mm:ss a');

        return [
          key + 1,
          <span>{itemName}</span>,
          typeStr,
          <div className="actions-right">{(<div>
            {onlyDateStr}
            <br />
            {onlyTimeStr}

          </div>)}</div>,
          inventory <= 0 ? <Danger>{inventory}</Danger> : inventory,
        ];
      });

    setData(newData);
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={pathToRedirect} />;
    } else {
      return null;
    }
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const _renderSearchBar = () => {
    const searchFieldList = [
      {
        itemName: Loc.product_name,
        id: "itemName",
        key: ["itemName"],
        type: "text",
        preloadCollectionName:["Product"],
        preloadDefaultName:[Loc.select_product],
        isPreloadField: true,
      },
      // { itemName: 'Localization Key', id: "localizationKey", key: ["localizationKey"], type: "text" },
      // { itemName: "", id: "divider", key: [""], type: "" },
      // { itemName: Loc.search_by_date, id: "date", key: ["createDate"], type: "date" },
    ];

    const searchFieldNameList = [];
    const searchIDList = [];

    searchFieldList.map((item) => {
      if (item.id == "divider") {
        searchFieldNameList.push({ divider: true });
        searchIDList.push("");
      } else {
        searchFieldNameList.push(item.itemName);
        searchIDList.push(item.id);
      }
    });

    if (locationState && locationState !== null) {
      return (
        <SearchBar
          searchFieldList={searchFieldList}
          apiPath={"admin/list-inventory-balance"}
          loadDataList={() => do_loadDataList()}
          searchResultDataList={(dataList) => {
            setData_dataList(dataList);
          }}
          searchResultDataCount={(count) => {
            setData_dataListCount(count);
          }}
          showDeletedData={showDeletedData}
          locationState={locationState}
          didSelectedShowDeletedData={(_showDeletedData) => {
            setShowDeletedData(_showDeletedData);
            set_isLoaded(false);
          }}
          showExtendedTable={(bool) => {
            setShowExtendedTable(bool);
          }}
          searchPeriod={(from, to) => {
            setData_startDate(from);
            setData_endDate(to);
          }}
        />
      );
    }
  };
  const tableHeader = [
    "#",
    Loc.product_name,
    Loc.type,
    Loc.inventory_last_in_date,
    Loc.inventory,
  ];
  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={tableHeader}
        tableData={data}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4]}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4]}
      />
    );
  }

  const pageName = Loc.inventory_balance;

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{pageName}</h4>
            </CardHeader>
            <CardBody>
              <Button
                color="success"
                round
                onClick={() => {
                  operation();
                }}
              >
                {Loc.download_excel}
              </Button>
              {_renderSearchBar()}
              {showExtendedTable
                ? _renderExtendedTable()
                : _renderPagingTable()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
