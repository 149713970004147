import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Loc from "localization";
import firebase from 'connectionHandler/firebase';
import fetchAPI from "connectionHandler/FetchAPI.js";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

export default function StaffLoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const [data_username, setUsername] = React.useState("")
  const [data_password, setPassword] = React.useState("")
  const [confirmResult, setConfirmResult] = React.useState(null)
  const [isEditablePhone, setIsEditablePhone] = React.useState(false)

  const [isLoading, set_isLoading] = React.useState(false);

  // alert
  const [alert,setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  }

  function handle_login(e)
  {
    e.preventDefault();
    set_isLoading(true);
    console.log("data_username: ", data_username);
    console.log("data_password: ", data_password);
    if (data_username !== "")
    {
      if (data_password !== "")
      {
        confirmResult.confirm(data_password).then(result => {
          var user = result.user;
          // set_isLoading(false);
        }). catch (error => {
          showAlert_fail(Loc.loginFail_title, Loc.staffLoginFaild_title);
          
          var errorMessage = error.message;
          console.log("phone login fail, errorMessage: ", errorMessage);
          set_isLoading(false);
        })

        // firebase.auth().signInWithEmailAndPassword(data_username, data_password).catch(function(error) {
        //   // Handle Errors here.
        //   var errorCode = error.code;
        //   var errorMessage = error.message;
        //   console.log("login fail, errorCode: ", errorCode);
        //   console.log("login fail, errorMessage: ", errorMessage);
          
        // });
      }
      else
      {
        showAlert_fail(Loc.loginFail_title, Loc.errorMsg_code_empty);
        set_isLoading(false);
      }
    }
    else
    {
      showAlert_fail(Loc.loginFail_title, Loc.errorMsg_phone_empty);
      set_isLoading(false);
    }
  }

  function handle_getCode(e)
  {
    e.preventDefault();
    setIsEditablePhone(true)

    if (data_username !== "")
    {
      do_checkPhoneExist().then(isExist => {
        if (isExist)
        {
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

          const appVerifier = window.recaptchaVerifier;
          firebase.auth().signInWithPhoneNumber("+852" + data_username, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // window.confirmationResult = confirmationResult;
            setConfirmResult(confirmationResult);
            console.log("Recaptcha verify success");
          }).catch(function (error) {
            console.log("Recaptcha verify onSignInSubmitError: ", error.code);
            setIsEditablePhone(false);
            showAlert_fail(Loc.getCodeFaild_title, Loc.errorMsg_phone_invalid);
            // Error; SMS not sent
            // ...
          });
        }
        else
        {
          setIsEditablePhone(false);
          showAlert_fail(Loc.loginFail_title, Loc.staffLoginFaild_title);
        }
      })
    }
    else
    {
      setIsEditablePhone(false);
      showAlert_fail(Loc.getCodeFaild_title, Loc.errorMsg_phone_empty);
    }
  }

  function do_checkPhoneExist()
  {
    return new Promise((onDone) => {
      const body = {
          "data_phone": data_username
      }
      fetchAPI.do_fetch_withoutTokenID('post', 'staff/phone-check', body)
      .then((res) => {
          console.log("success: ", res);
          // setFetchingStatus("");
          onDone(true);
      }, error => {
          console.log("failed: ", error);
          // setFetchingStatus("");
          onDone(false);
      })
    });
  }

  function showAlert_fail(title, content){
    setAlert(
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        confirmBtnText={Loc.confirm}
        btnSize="lg"
        // timeout={2000}
      >
        {content}
      </SweetAlert>
    );
  }

  function showHideRecaptcha()
  {
    if (confirmResult === null)
    {
      return (
        <div id="recaptcha-container" style={{display: 'flex', justifyContent: 'center'}}></div>
      )
    }
    else
    {
      return null;
    }
  }

  function showHide_input_verifyCode()
  {
    if(confirmResult)
    {
      return (
        <CustomInput
          labelText={Loc.verify_code}
          id="verifyCode"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "text",
            autoComplete: "off",
            onChange: event => {
              setPassword(event.target.value);
            }
          }}
        />
      )
    }
    else
    {
      return null;
    }
  }

  function showHide_btn_getCode()
  {
    if(!confirmResult)
    {
      return (
        <Button 
          color="info"
          round
          block
          onClick={handle_getCode}>
            {Loc.get_verify_code}
        </Button>
      )
    }
    else
    {
      return null;
    }
  }

  function showHide_btn_login()
  {
    if(confirmResult)
    {
      return (
        <Button color="info" id="sign-in-button" round block onClick={handle_login} type="submit" disabled={isLoading}>
          {Loc.login}
        </Button>
      )
    }
    else
    {
      return null;
    }
  }

  const showloading = () => {
    if (isLoading)
    {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      )
    }
    else
    {
      return null;
    }
  }

  return (
    <div className={classes.container}>
      {showloading()}
      {alert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={5}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>{Loc.staff_login}</h4>
              </CardHeader>
              <CardBody>
              <GridContainer>
                <GridItem xs={12}>

                <CustomInput
                  labelText={Loc.staff_phone}
                  id="staffPhone"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: isEditablePhone,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Phone className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type:"tel",
                    onChange: event => {
                      setUsername(event.target.value);
                    }
                  }}
                />

                {showHide_input_verifyCode()}

                {showHide_btn_getCode()}

                {showHideRecaptcha()}
                
                </GridItem>
              </GridContainer>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {showHide_btn_login()}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
