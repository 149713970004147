import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Danger from "components/Typography/Danger.js"
import Success from "components/Typography/Success.js"
import Muted from "components/Typography/Muted.js"
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_table from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loc from "localization";

const newStyles = {
  ...styles_table,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "center"
  }
};

const useStyles_table = makeStyles(newStyles);
const useStyles = makeStyles(styles);

export default function PagingTable(props) {
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
    gotoPageClicked,
    changedPageCount,
    totalDataCount,
    page,
    pageCount
  } = props;

  React.useEffect(() => 
  {   
    setNumberOfRows(pageCount);
    handlePageSelect(page);
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, []);

  const classes_table = useStyles_table();

  let numberOfRowsData = [25, 50, 100, 200, 500, 9999];

  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);

  const pageSelections = () => {
    var selectionList = [];

    const numOfPageSelection = Math.ceil(totalDataCount / numberOfRows);

    for (var i=0; i<numOfPageSelection; i++)
    {
      const item = (
        <MenuItem
          key={i}
          classes={{
            root: classes_table.selectMenuItem,
            selected: classes_table.selectMenuItemSelected
          }}
          value={i}
        >
          {Loc.page} {i + 1}
        </MenuItem>
      )
      selectionList.push(item);
    }
    return selectionList;
  }

  const div_list_empty = () => {
    const theDiv =  <GridContainer><GridItem xs={12} align="center"><Muted><h4>{Loc.search_hints}</h4></Muted></GridItem></GridContainer>
    return theDiv;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        {tableData.length === 0 ? div_list_empty() : 
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={4}>
                <FormControl fullWidth>
                  <Button color="info" disabled={pageSelect === 0 ? true : false} onClick={() => {
                    gotoPageClicked(pageSelect-1);
                    handlePageSelect(pageSelect-1);
                  }}>
                    {Loc.previous_page}
                  </Button>
                </FormControl>
              </GridItem>

              <GridItem xs={4}>
                <GridContainer>
                  <GridItem xs={6}>
                    <FormControl
                      fullWidth
                      className={
                        classes_table.selectFormControl +
                        " " +
                        classes_table.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes_table.selectMenu
                        }}
                        classes={{
                          select: classes_table.select
                        }}
                        value={pageSelect}
                        onChange={event => {
                          gotoPageClicked(event.target.value);
                          handlePageSelect(event.target.value);
                        }}
                        inputProps={{
                          name: "pageSelect",
                          id: "page-select"
                        }}
                      >
                        {pageSelections()}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={6}>
                  <FormControl
                    fullWidth
                    className={
                      classes_table.selectFormControl +
                      " " +
                      classes_table.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes_table.selectMenu
                      }}
                      classes={{
                        select: classes_table.select
                      }}
                      value={numberOfRows}
                      onChange={event => {
                        changedPageCount(event.target.value);
                        handlePageSelect(0);
                        setNumberOfRows(event.target.value);
                      }}
                      inputProps={{
                        name: "numberOfRows",
                        id: "number-of-rows"
                      }}
                    >
                      {numberOfRowsData.map(prop => {
                        return (
                          <MenuItem
                            key={prop}
                            classes={{
                              root: classes_table.selectMenuItem,
                              selected: classes_table.selectMenuItemSelected
                            }}
                            value={prop}
                          >
                            {prop} {Loc.rows}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  </GridItem>
                </GridContainer>

              </GridItem>
                
              <GridItem xs={4}>
                <FormControl fullWidth>
                  <Button color="info" disabled={pageSelect === (pageSelections().length - 1) ? true : false} onClick={() => {
                    gotoPageClicked(pageSelect+1);
                    handlePageSelect(pageSelect+1);
                  }}>
                    {Loc.next_page}
                  </Button>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <Table
              tableHead={tableHead}
              tableData={tableData}
              customCellClasses={customCellClasses}
              customClassesForCells={customClassesForCells}
              customHeadCellClasses={customHeadCellClasses}
              customHeadClassesForCells={customHeadClassesForCells}
            />
          </GridItem>
        </GridContainer>
        }
      </GridItem>
    </GridContainer>
  );
}