import React from "react";
import {Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import moment from 'moment';
import { dataTable } from "variables/general.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js"
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import Recover from '@material-ui/icons/Replay';
import Lock from '@material-ui/icons/NoEncryption';

import { saveAs } from "file-saver";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);
export default function Expenditure_list(props) {

  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  }
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [data_isDataListLoaded, setData_isDataListLoaded] = React.useState(false);

  const data_targetCollection = "Expenditure";

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);

  const [data_adminInfo,setData_adminInfo] = React.useState(null)

  const [data_searchID, setData_searchID] = React.useState("");
  const [locationState,setLocationState] = React.useState(null)

  const [showExtendedTable, setShowExtendedTable] = React.useState(false);
  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const classes_switch = useStyles_switch();
   
  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());
  

  React.useEffect(() => {  
    
    
    if (!locationState || locationState == null){
      var preloadListStr = localStorage.getItem("preloadDataList")
      var preloadList = JSON.parse(preloadListStr);
      // console.log("preloadList  "+JSON.stringify(preloadList))
      setLocationState(props.location.state);
      // setData_dataListArray(preloadList);
      // console.log("admin Info " + JSON.stringify(props.location.state.adminInfo))
      setData_adminInfo(props.location.state.adminInfo)
    }else{

    if (data_dataList != null && data_adminInfo != null)
    {
      processDataWithActions();
    }

    if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0)
    {
      // set_isLoaded(true);
      // do_loadDataList();
    }

  }
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [data_pageCount, data_page, data_dataList,showDeletedData,locationState,data_adminInfo]);

  var xls = require("exceljs");

async function operation() {
  var workbook = new xls.Workbook();

  workbook.created = new Date();

  workbook.modified = new Date();

  const worksheet = workbook.addWorksheet("sheet");

  //calcualte part

  // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
  // //merge cell
  // worksheet.mergeCells("A1:"+headerEndColumn+"1");
  // worksheet.mergeCells("A2:"+headerEndColumn+"2");

  worksheet.columns = getExcelColumns()

  const tableHeaderList =  tableHeader.map(item=>{
    if(item !== Loc.actions){
      return item
    }
  })
  //Fixed Items
  const rows = [
    tableHeaderList,
  ];
  // //columns
  const titleRows = worksheet.getRow(1);

  // // // report_itemName
  titleRows.values = tableHeaderList;
  titleRows.alignment = { vertical: "middle", horizontal: "center" };

// console.log("getExcelData()   "+JSON.stringify(getExcelData()))
  // worksheet.addRows(rows);
  //add data part
  worksheet.addRows(getExcelData());

  //export excel
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";

  const blob = new Blob([buffer], { type: fileType });
 
  const fileName = `${Loc.expenditure_records}_${moment(data_startDate).format(
    "YYYY-MM-DD-hh-mm-ss"
  )}~${moment(data_endDate).format(
    "YYYY-MM-DD-hh-mm-ss"
  )}`;
  saveAs(blob, fileName + fileExtension);
}

  function getExcelColumns(){
    var columns = []
    tableHeader.map((item)=>{
      if (item != Loc.actions)
      columns.push({ header: "", key: item, width: 20 })           
        })
    
    return columns
  }

  function getExcelData(){

    const newData = data_dataList.map((prop, key)=>{
      const item_id = prop.id;
      const itemName = prop.itemName;
      var storeName = prop.storeName
      const remark = (prop.remark === "" || prop.remark == null) ? "/" : prop.remark;
      const isConfidential = prop.isConfidential ?? false
      var isAdmin = false
      
      if(data_adminInfo){
        // console.log(data_adminInfo)
        if (data_adminInfo.bossPermissions){
          isAdmin = data_adminInfo.bossPermissions.isEnabled
        }else if (data_adminInfo.itemName == 'Super Admin'){
          isAdmin = true
        }
      }


      var amount = isConfidential ? "****":parseFloat(prop.amount).toLocaleString(); 
      if (isAdmin){
        amount = parseFloat(prop.amount).toLocaleString() //if admin is above boss level, must show the amount 
      }


      var typeStr = "-";
      if (prop.type === "inventory-in")
      {
        typeStr = Loc.purchases_expenditure;
      }
      else if (prop.type === "commission")
      {
        typeStr = Loc.commission_expenditure;
      }else if (prop.type === "discount_allowed_prepaid"){
        typeStr = Loc.discount_allowed_prepaid
      }else if (prop.type === "discount_allowed_sales"){
        typeStr = Loc.discount_allowed_sales
      }
      else{
        typeStr = Loc.other_expenditure;
      }

      // if (storeName == ""){
      //   storeName = Loc.store_default
      // }

      const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");

      return [
        date,
        itemName,
        storeName,
        typeStr,
        amount
      ];

 
    })

    return newData

  } 


  function do_loadDataList(dataList)
  {
    set_isLoading(true);
    const body = {
      "page":data_page,
      "pageCount":data_pageCount,
      showDeletedData:showDeletedData
    }
    fetchAPI.do_fetch('post', 'admin/list-expenditure', body)
    .then((res) => {
      set_isLoading(false);
      console.log("success: ", res.data);
      setData_dataList(res.data.data_list);
      setData_dataListCount(res.data.count);
    }, error => {
      set_isLoading(false);
      console.log("failed: ", error);
      showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function do_deleteData(targetID)
  {
    return new Promise((onDone) => {
      const body = {
        "idList": [targetID],
        "targetCollection":'Expenditure',
        "targetID":targetID
      }
      const path = showDeletedData ? 'admin/recover-deleted':'admin/del-expenditure'
      fetchAPI.do_fetch('post', path, body)
        .then((res) => {
            console.log("success: ", res.data);
            onDone(true);
        }, error => {
            console.log("failed: ", error);
            onDone(false);
            
        })
    });
  }

  function processDataWithActions()
  {
    var total_amount = parseFloat(0)
    var newData = data_dataList
    // .filter(v => v.isDeleted != true)
    .map((prop, key) => {
      const item_id = prop.id;
      const itemName = prop.itemName;
      var storeName = prop.storeName
      const remark = (prop.remark === "" || prop.remark == null) ? "/" : prop.remark;
      const isConfidential = prop.isConfidential ?? false
      const isSuperAdmin = props.location.state.adminInfo.itemName ? (props.location.state.adminInfo.itemName == "Super Admin"):false;
      var isAdmin = false
      total_amount += parseFloat(prop.amount)
      
      if(data_adminInfo){
        // console.log(data_adminInfo)
        if (data_adminInfo.bossPermissions)
        {
          isAdmin = data_adminInfo.bossPermissions.isEnabled;
        }
        else if (data_adminInfo.admin_level == '-1'){
          isAdmin = true;
        }
        else
        {
          isAdmin = false;
        }
      }


      var amount = isConfidential ? "****":parseFloat(prop.amount).toLocaleString(); 
      if (isAdmin || isSuperAdmin){
        amount = parseFloat(prop.amount).toLocaleString() //if admin is above boss level, must show the amount 
      }

      const isAutoCreate = prop.isAutoCreate != undefined ? prop.isAutoCreate:false;
      console.log('props.location.state.adminInfo.itemName  '+isAdmin)
      var typeStr = "-";
      if (prop.type === "inventory-in")
      {
        typeStr = Loc.purchases_expenditure;
      }
      else if (prop.type === "commission")
      {
        typeStr = Loc.commission_expenditure;
      }else if (prop.type === "discount_allowed_prepaid"){
        typeStr = Loc.discount_allowed_prepaid
      }else if (prop.type === "discount_allowed_sales"){
        typeStr = Loc.discount_allowed_sales
      }
      else{
        typeStr = Loc.other_expenditure;
      }

      // if (storeName == ""){
      //   storeName = Loc.store_default
      // }

      // const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
      var onlyDateStr = moment(prop.date).format('DD MMM YYYY');
      var onlyTimeStr = moment(prop.date).format('h:mm:ss a');
      const actionButtons = () =>{

        if(isAutoCreate){
          if(!isSuperAdmin){
            return (
              <div className="actions-right">
  
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  
                }}
                color="danger"
                className="remove"
              >
                <Lock />
              </Button>{" "}
            </div>
            )
          }else{
            return (
              <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  var state = {
                    data: prop,
                    config: locationState
                  }
                  setItemToEdit(state);
                  setRedirect("/admin/expenditure-new");
                }}
                color="warning"
                className="edit"
              >
                <Dvr />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  showConfirmDeleteAlert(item_id);
                }}
                color="danger"
                className="remove"
              >
                {showDeletedData ? <Recover />:<Close />}
              </Button>{" "}
            </div>
            )

          }

        }else{
          if (isSuperAdmin || isAdmin || !isConfidential)
        {
          return (
            <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                var state = {
                  data: prop,
                  config: locationState
                }
                setItemToEdit(state);
                setRedirect("/admin/expenditure-new");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(item_id);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover />:<Close />}
            </Button>{" "}
          </div>
          )
        }
        else
        {
          return (
            <div className="actions-right">

            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                
              }}
              color="danger"
              className="remove"
            >
              <Lock />
            </Button>{" "}
          </div>
          )
        }
        }
        

      }

      return [
        (<div>
          {onlyDateStr}
          <br />
          {onlyTimeStr}

        </div>),
        (
          <p>
            {/* <img src={prop.imageUrl} alt="..." /> */}
            {itemName}
          </p>
        ),
        storeName,
        typeStr,
        amount,
        // remark,
        actionButtons()
      ];
    })
    const totalAmountRow = showExtendedTable ? [
      "",
      "",
      "",
      "",
      <b>
        <h5>{Loc.expenditure_total_sales_amount +
          ": " +
          total_amount.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }
          )}</h5>
      </b>,
      
      "",
    ]:[]
    if(showExtendedTable){
      newData.unshift(totalAmountRow);
      newData.push(totalAmountRow);
    }
    
    setData(newData);
  }

  function showConfirmDeleteAlert(targetID){
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={showDeletedData ? Loc.confirm_recover_title:Loc.confirm_delete_title}
        onConfirm={() => {
          hideAlert();
          do_deleteData(targetID, data_targetCollection).then(isDeleted => {
            if (isDeleted)
            {
              do_loadDataList();
              showAlert(true, Loc.success, showDeletedData ? Loc.data_recover_success:Loc.data_delete_success);
            }
            else{
              showAlert(false, Loc.failed, showDeletedData ? Loc.data_recover_failed:Loc.data_delete_failed);
            }
          });
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={showDeletedData ? Loc.confirm_recover_confirm:Loc.confirm_delete_confirm}
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

const check_redirect = () => {
  if (pathToRedirect != "")
  {
    if (itemToEdit != null)
    {
      return (<Redirect to={{
        pathname: pathToRedirect,
        state: itemToEdit
      }} />);
    }
    else
    {
      return (<Redirect to={pathToRedirect} />);
    }
  }
  else
  {
      return null;
  }
}

const showloading = () => {
  if (isLoading)
  {
    return (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.loading}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
        showConfirm={false}
        showCancel={false}
      >
        {Loc.please_wait}
      </SweetAlert>
    )
  }
  else
  {
    return null;
  }
}

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();

  const _renderSearchBar = () => {
      const customPreloadList = {[Loc.expenditure_type]:
      [{id:"inventory-in",itemName:Loc.purchases_expenditure},
      {id:"commission",itemName:Loc.commission_expenditure},
      {id:"discount_allowed_prepaid",itemName:Loc.discount_allowed_prepaid},
      {id:"discount_allowed_sales",itemName:Loc.discount_allowed_sales},
      {id:"other",itemName:Loc.other_expenditure}]}
      
    
      // console.log(`customPreloadList  ${JSON.stringify(customPreloadList)}`)

    const searchFieldList = [
      { itemName: Loc.expenditure_name, id: "itemName", key: ["itemName"], type: "text",isPreloadField:false },
      { itemName: Loc.store_name, id: "storeName", key: ["storeID"], type: "text",preloadCollectionName:["Store"],preloadDefaultName:[Loc.select_store],isPreloadField:true },
      { itemName: Loc.expenditure_type, id: "type", key: ["type"], type: "text", isPreloadField:true },
      { itemName: "", id: "divider", key: [""], type: "",isPreloadField:false },
      { itemName: Loc.search_by_date, id: "date", key: ["createDate"], type: "date",isPreloadField:false },
      {
        itemName: Loc.multi_search_store,
        id: "date_store",
        key: ["createDate", "storeID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Store"],
        preloadDefaultName:[Loc.select_store],
        isPreloadField: true,
      },
      {
        itemName: Loc.multi_search_store_exp,
        id: "date_store_exp",
        key: ["createDate", "storeID","expenditureID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Store","Expenditure"],
        preloadDefaultName:[Loc.select_store,Loc.select_exp],
        isPreloadField: true,
      },
    ];

    const searchFieldNameList = [];
    const searchIDList = [];

    searchFieldList.map((item) => {
      if (item.id == "divider") {
        searchFieldNameList.push({ divider: true });
        searchIDList.push("");
      } else {
        searchFieldNameList.push(item.itemName);
        searchIDList.push(item.id);
      }
    });
      // console.log("expenditure list  "+JSON.stringify(locationState))
    if (locationState && locationState !== null) {
    return (
      <SearchBar
        searchFieldList={searchFieldList}
        apiPath={"admin/list-expenditure"}
        loadDataList={() => do_loadDataList()}
        searchResultDataList={(dataList) => {
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        showDeletedData={showDeletedData}
        locationState={locationState}
        customPreloadList={customPreloadList}
        didSelectedShowDeletedData= {(_showDeletedData)=>{
        setShowDeletedData(_showDeletedData)
        set_isLoaded(false);
      }}
        showExtendedTable={(bool)=>{
          setShowExtendedTable(bool)
      }}
      searchPeriod={(from,to)=>{
        setData_startDate(from)
        setData_endDate(to)
      }}
      />
    );}
  };
  const tableHeader = [
    Loc.expenditure_date,
    Loc.expenditure_name,
    Loc.store_name,
    Loc.expenditure_type,
    Loc.expenditure_amount,
    // Loc.remark,
    Loc.actions,
  ]
  function _renderPagingTable(){
    return (
        <PagingTable
        tableHead={tableHeader}
            tableData={data}
            customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.right, classes_tableBtn.right]}
            customClassesForCells={[0,1,2,3, 4, 5]}
            customHeadCellClasses={[
              classes_tableBtn.left,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.right,
              classes_tableBtn.right
            ]}
            customHeadClassesForCells={[0,1,2,3, 4, 5]}
            gotoPageClicked={(targetPage) => {
              set_page(targetPage);
              set_isLoaded(false);
            }}
            changedPageCount={(newPageCount) => {
              set_page(0);
              set_pageCount(newPageCount);
              set_isLoaded(false);
            }}
            totalDataCount={data_dataListCount}
            page={data_page}
            pageCount={data_pageCount}
          />
    )

  }


  function _renderExtendedTable(){
    return(
      <Table
      tableHead={tableHeader}
              tableData={data}
              // customCellClasses={[classes.center, classes.right, classes.right]}
              customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center, classes_tableBtn.right]}
            customClassesForCells={[0,1,2,3, 4, 5]}
            customHeadCellClasses={[
              classes_tableBtn.left,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.right
            ]}
            customHeadClassesForCells={[0,1,2,3, 4, 5]}
            />

    )

  }

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{Loc.expenditure_records}</h4>
          </CardHeader>
          <CardBody>
          <GridContainer>
          <GridItem xs={12} md={12}>
          <Button color="success" round onClick={() => {
            var state = {
              data: null,
              config: locationState
            }
            setItemToEdit(state);
            setRedirect("/admin/expenditure-new");
            }}>
              <ICON_ADD />
              {Loc.expenditure_add}
          </Button>
          <Button color="success" round onClick={() => {
          operation()
          }}>
            {Loc.download_excel}
        </Button>
          </GridItem>
          {_renderSearchBar()}
          </GridContainer>
          {showExtendedTable ? _renderExtendedTable():_renderPagingTable()}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
