import React from "react";
import { Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import moment from 'moment';
import NavPills from "components/NavPills/NavPills.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js"
import Table from "components/Table/Table.js";

import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Recover from '@material-ui/icons/Replay';
import { saveAs } from "file-saver";

import Lock from '@material-ui/icons/NoEncryption';


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);



export default function Commission_list(props) {

  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  }
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [data_headerAmount, setHeaderAmount] = React.useState([]);

  const [data_balanceTableList, setData_balanceTableList] = React.useState([]);

  const [data_isDataListLoaded, setData_isDataListLoaded] = React.useState(false);
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const collectionsToLoad = ["Staff", "Store"];

  const data_targetCollection = "Commission";


  const classes_tableBtn = useStyles_tableBtn();
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_searchID, setData_searchID] = React.useState("");
  const [locationState,setLocationState] = React.useState(null)

  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());

  const tableHeader = [
    // "#",
    Loc.date,
    Loc.store_name,
    Loc.staff_name,
    Loc.type,
    Loc.commisson_amount,
    Loc.actions,
  ]

  React.useEffect(() => {  
    var preloadListStr = localStorage.getItem("preloadDataList")
    var preloadList = JSON.parse(preloadListStr);

    if (!locationState || locationState == null) {
      preloadList.adminInfo = props.location.state.adminInfo
      setLocationState(preloadList);
      // console.log("props.location.state.adminInfo  :"+JSON.stringify(props.location.state))
      // locationState.adminInfo = props.location.state.adminInfo
    } else{
    if (data_dataList != null)
    {
      processDataWithActions();
    }

    if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0)
    {
      // set_isLoaded(true);
      // do_loadDataList();
    }
  }
    return function cleanup() {
      
    };
  }, [data_dataListArray, data_pageCount, data_page, data_dataList,showDeletedData,locationState]);


//Excel
var xls = require("exceljs");

async function operation() {
  var workbook = new xls.Workbook();

  workbook.created = new Date();

  workbook.modified = new Date();

  const worksheet = workbook.addWorksheet("sheet");

  //calcualte part

  // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
  // //merge cell
  // worksheet.mergeCells("A1:"+headerEndColumn+"1");
  // worksheet.mergeCells("A2:"+headerEndColumn+"2");

  worksheet.columns = getExcelColumns()

  const tableHeaderList =  tableHeader.map(item=>{
    if(item !== Loc.actions){
      return item
    }
  })
  //Fixed Items
  const rows = [
    tableHeaderList,
  ];
  // //columns
  const titleRows = worksheet.getRow(1);

  // // // report_itemName
  titleRows.values = tableHeaderList;
  titleRows.alignment = { vertical: "middle", horizontal: "center" };

// console.log("getExcelData()   "+JSON.stringify(getExcelData()))
  // worksheet.addRows(rows);
  //add data part
  worksheet.addRows(getExcelData());

  //export excel
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";

  const blob = new Blob([buffer], { type: fileType });
 
  const fileName = `${Loc.commission_record}_${moment(data_startDate).format(
    "YYYY-MM-DD-hh-mm-ss"
  )}~${moment(data_endDate).format(
    "YYYY-MM-DD-hh-mm-ss"
  )}`;
  saveAs(blob, fileName + fileExtension);
}

  function getExcelColumns(){
    var columns = []
    tableHeader.map((item)=>{
      if (item != Loc.actions)
      columns.push({ header: "", key: item, width: 20 })           
        })
    
    return columns
  }

  function getExcelData(){
    var totalCommission = 0

    const newData = data_dataList.map((prop, key)=>{
      const amountVal = (prop.type === "pay_commission") ? parseFloat(-prop.amount).toFixed(2).toLocaleString() : parseFloat(prop.amount).toFixed(2).toLocaleString();
      const store = (prop.storeName === "") ? Loc.store_default : prop.storeName;//getStoreStringToShow(prop.storeID);
      const staff = (prop.staffName === "") ? Loc.staff_default : prop.staffName;//getStaffStringToShow(prop.staffID);
      const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
      var typeStr = Loc.unknown;
      if (prop.type === "sales_commission")
      {
        typeStr = Loc.sales_commission
      }
      else if (prop.type === "redeem_commission")
      {
        typeStr = Loc.redeem_commission
      }
      else if (prop.type === "adjust_commission")
      {
        typeStr = Loc.adjust_commission
      }
      else if (prop.type === "pay_commission")
      {
        typeStr = Loc.pay_commission
      }
      totalCommission += prop.amount
    // console.log("customItemsList    "+JSON.stringify(customItemsList))
    return [
      date,
      store,
      staff,
      typeStr,
      amountVal
    ]
    })
    const totalAmountRow = showExtendedTable ? ["","","","",Loc.sales_amount_total+": "+parseFloat(totalCommission).toFixed(2),""]:[]
    newData.push(totalAmountRow)
    return newData

  } 



  function do_loadDataList()
  {
    set_isLoading(true);
    const body = {
      "page":data_page,
      "pageCount":data_pageCount,
      showDeletedData:showDeletedData
    }
    fetchAPI.do_fetch('post', 'admin/list-commission', body)
    .then((res) => {
        set_isLoading(false);
        // console.log("success: ", JSON.stringify(res.data));
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
    }, error => {
      set_isLoading(false);
      console.log("failed: ", error);
      showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function do_deleteRecord(record)
  {
    // delete exp
    do_deleteItemExpenditure(record.expenditureID, "Expenditure").then((deletedExpenditure) => {
      if (deletedExpenditure)
      {
        // delete item
        do_deleteItemRecord(record).then((deletedRecord) => {
          if (deletedRecord)
          {
            do_loadDataList();
            showAlert(true, Loc.success, showDeletedData ? Loc.data_recover_success:Loc.data_delete_success);
          }
          else
          {
            showAlert(false, Loc.failed, showDeletedData ? Loc.data_recover_failed:Loc.data_delete_failed);
          }
        });
      }
      else
      { 
        showAlert(false, Loc.failed, showDeletedData ? Loc.data_recover_failed:Loc.data_delete_failed);
      }
    });
  }

  function do_deleteItemExpenditure(targetID, targetCollection)
  {
    return new Promise((onDone) => {
      if (targetID === "")
      {
        onDone(true);
      }
      else if (targetID != "")
      {
        const body = {
          "idList": [targetID],
        }
    
        fetchAPI.do_fetch('post', 'admin/del-expenditure', body)
        .then((res) => {
            console.log("success: ", res.data);
            onDone(true);
        }, error => {
            console.log("failed: ", error);
            onDone(false);
        })
      }
      else
      {
        onDone(false);
        }
    })
  }

  function do_deleteItemRecord(prop)
  {
    return new Promise((onDone) => {
      if (prop.id === "")
      {
        onDone(true);
      }
      else if (prop.id != "")
      {
        const item = {
          id: prop.id,
          amount: prop.amount,
          type: prop.type,
          staffID: prop.staffID,
          staffName: prop.staffName,
        }
        const body = {
          "itemList": [item],
        }
    
        fetchAPI.do_fetch('post', 'admin/del-commission', body)
        .then((res) => {
            console.log("success: ", res.data);
            onDone(true);
        }, error => {
            console.log("failed: ", error);
            onDone(false);
        })
      }
      else
      {
        onDone(false);
      }
    });
  }
  

  function processDataWithActions()
  {
    var totalCommission = 0

    const newData = data_dataList
    // .filter(v => (v.isDeleted != true && v.amount !== 0))
    .map((prop, key) => {
      const item_id = prop.id;
      const remark = (prop.remark === "") ? "/" : prop.remark;
      const amountVal = (prop.type === "pay_commission") ? parseFloat(-prop.amount).toFixed(2).toLocaleString() : parseFloat(prop.amount).toFixed(2).toLocaleString();
      const store = (prop.storeName === "") ? Loc.store_default : prop.storeName;//getStoreStringToShow(prop.storeID);
      const staff = (prop.staffName === "") ? Loc.staff_default : prop.staffName;//getStaffStringToShow(prop.staffID);
      // const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
      var onlyDateStr = moment(prop.date).format('DD MMM YYYY');
      var onlyTimeStr = moment(prop.date).format('h:mm:ss a');
      const isAutoCreate = prop.isAutoCreate != undefined ? prop.isAutoCreate:false;
      // console.log("adminName? "+JSON.stringify(props.location.state.config.adminInfo.itemName));
      const isSuperAdmin = props.location.state.adminInfo.itemName ? (props.location.state.adminInfo.itemName == "Super Admin"):false;
      // console.log("adminName? "+adminInfo.itemName);

      var typeStr = Loc.unknown;
      if (prop.type === "sales_commission")
      {
        typeStr = <Success>{Loc.sales_commission}</Success>;
      }
      else if (prop.type === "redeem_commission")
      {
        typeStr = <Success>{Loc.redeem_commission}</Success>;
      }
      else if (prop.type === "adjust_commission")
      {
        typeStr = <Success>{Loc.adjust_commission}</Success>;
      }
      else if (prop.type === "pay_commission")
      {
        typeStr = <Danger>{Loc.pay_commission}</Danger>;
      }
      totalCommission += parseFloat(prop.amount)
      return [
        // key+1,
        (<div>
          {onlyDateStr}
          <br />
          {onlyTimeStr}

        </div>),
        store,
        staff,
        typeStr,
        (
        (amountVal > 0) ? <Success>{amountVal}</Success> : <Danger>{amountVal}</Danger>
        ),
        // remark: remark,
        (isAutoCreate && !isSuperAdmin) ? (<div className="actions-right">

        {/* use this button to remove the data row */}
        <Button
          justIcon
          round
          simple
          onClick={() => {
            
          }}
          color="danger"
          className="remove"
        >
          <Lock />
        </Button>{" "}
      </div>):(
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                var state = {
                  data: prop,
                  config: locationState
                }
                setItemToEdit(state);
                setRedirect("/admin/commission-new");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(prop);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover />:<Close />}
            </Button>{" "}
          </div>
        )
      ];
    })
    // console.log("Staff Amount  =="+totalCommission)
    const totalAmountRow = showExtendedTable ? ["","","","",<b>{Loc.sales_amount_total+": "+parseFloat(totalCommission).toFixed(2)}</b>,""]:[]
    if(showExtendedTable){
      newData.push(totalAmountRow)
      newData.unshift(totalAmountRow)
    }
    

    setHeaderAmount(totalAmountRow)
    setData(newData);

  }

  function showConfirmDeleteAlert(record){
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={showDeletedData ? Loc.confirm_recover_title:Loc.confirm_delete_title}
        onConfirm={() => {
          hideAlert();
          do_deleteRecord(record);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={showDeletedData ? Loc.confirm_recover_confirm:Loc.confirm_delete_confirm}
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "")
    {
      if (itemToEdit != null)
      {
        return (<Redirect to={{
          pathname: pathToRedirect,
          state: itemToEdit
        }} />);
      }
      else
      {
        return (<Redirect to={{
          pathname: pathToRedirect,
          state: null
        }} />);
      }
    }
    else
    {
        return null;
    }
  }

  function _renderPagingTable(){
    return (
        <PagingTable
            tableHead={tableHeader}
            tableData={data}
            customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center, classes_tableBtn.right]}
            customClassesForCells={[0,1,2,3, 4, 5]}
            customHeadCellClasses={[
              classes_tableBtn.left,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.right
            ]}
            customHeadClassesForCells={[0,1,2,3, 4, 5]}
            gotoPageClicked={(targetPage) => {
              set_page(targetPage);
              set_isLoaded(false);
            }}
            changedPageCount={(newPageCount) => {
              set_page(0);
              set_pageCount(newPageCount);
              set_isLoaded(false);
            }}
            totalDataCount={data_dataListCount}
            page={data_page}
            pageCount={data_pageCount}
          />
    )

  }


  function _renderExtendedTable(){
    return(
      <>
      {/* <Table
      tableHead={["","","","","",""]}
              tableData={[data_headerAmount]}
              // customCellClasses={[classes.center, classes.right, classes.right]}
              customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center, classes_tableBtn.right]}
            customClassesForCells={[0,1,2,3, 4, 5]}
            customHeadCellClasses={[
              classes_tableBtn.left,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.right
            ]}
            customHeadClassesForCells={[0,1,2,3, 4, 5]}
            /> */}
      <Table
      tableHead={tableHeader}
              tableData={data}
              // customCellClasses={[classes.center, classes.right, classes.right]}
              customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center, classes_tableBtn.right]}
            customClassesForCells={[0,1,2,3, 4, 5]}
            customHeadCellClasses={[
              classes_tableBtn.left,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.right
            ]}
            customHeadClassesForCells={[0,1,2,3, 4, 5]}
            />
            </>

    )

  }

  const _renderSearchBar = () => {

    const searchFieldList = [
      { itemName: Loc.store_name, id:"storeName",key: ["storeID"], type: "text",preloadCollectionName:["Store"],preloadDefaultName:[Loc.select_store],isPreloadField:true},
      { itemName: Loc.staff_name, id: "staffName", key: ["staffID"], type: "text",preloadCollectionName:["Staff"],preloadDefaultName:[Loc.select_staff],isPreloadField:true},
      { itemName: "",id:"divider",key: ["divider"], type: "none" ,isPreloadField:false},
      { itemName: Loc.search_by_date,id:"date",key: ["createDate"], type: "date",isPreloadField:false },
      { itemName: Loc.multi_search_staff,id:"date_staff",key: ["createDate", "staffID"], keyToShow:"itemName",type: "multi",preloadCollectionName:["Staff"],preloadDefaultName:[Loc.select_staff],isPreloadField:true },
    ];
    if (locationState && locationState !== null) {
    return (
      <SearchBar
        searchFieldList={searchFieldList}
        apiPath={"admin/list-commission"}
        loadDataList={() => do_loadDataList()}
        searchResultDataList={(dataList) => {
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        didSelectedID={(id) => {
          setData_searchID(id);
        }}
        showDeletedData={showDeletedData}
        locationState={locationState}
        didSelectedShowDeletedData= {(_showDeletedData)=>{
        setShowDeletedData(_showDeletedData)
        set_isLoaded(false);
      }}
      showExtendedTable={(bool)=>{
        setShowExtendedTable(bool)
      }
      }
      searchPeriod={(from,to)=>{
        setData_startDate(from)
        setData_endDate(to)
      }}
      />
    );}
  };

  const showloading = () => {
    if (isLoading)
    {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      )
    }
    else
    {
      return null;
    }
  }

  
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_switch = useStyles_switch();
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
      <GridItem xs={12}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{Loc.commission_record}</h4>
        </CardHeader>
        <CardBody>
        <GridContainer>
        <GridItem xs={12} md={12}>
        <Button color="success" round onClick={() => {
          setItemToEdit({
            data: null,
            config: locationState
          });
          setRedirect("/admin/commission-new");
          }}>
            <ICON_ADD />
            {Loc.commission_add}
        </Button>

        <Button color="success" round onClick={() => {
          operation()
          }}>
            {Loc.download_excel}
        </Button>
        </GridItem>
    
        {_renderSearchBar()}
        </GridContainer>
       
          {showExtendedTable ? _renderExtendedTable():_renderPagingTable()}
        </CardBody>
      </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
