/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
// import ProductCategoryDropdown from 'views/CommonUseComponents/ProductCategoryDropdown.js';
import Dropdown from 'views/CommonUseComponents/Dropdown.js';
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import NavPills from "components/NavPills/NavPills.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Danger from "components/Typography/Danger.js"

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";


export default function Product_add(props) {

    const data_targetCollection = "Product-Package";
      // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_imageUrl, setData_imageUrl] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_categoryID, setData_categoryID] = React.useState("");
  const [data_categoryName, setData_categoryName] = React.useState("");
  const [data_price, setData_price] = React.useState(0.0);
  const [data_commission, setData_commission] = React.useState(0.0);
  const [data_remark, setData_remark] = React.useState("");
  const [data_itemsInPackage, setData_itemsInPackage] = React.useState([]);

  const [data_itemsInPackageTableList, setData_itemsInPackageTableList] = React.useState([]);
  const [pathToRedirect, setRedirect] = React.useState("");
  const [productNameState, setproductNameState] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [isLoadedEditData, setIsLoadedEditData] = React.useState(false);

  const collectionsToLoad = ["Product", "Product-Coupon", "Category"];
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [historyState,setHistoryState] = React.useState(null)
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
    setConfirmAlert(null);
  }

  const div_list_empty = () => {
    const theDiv =  <GridItem xs={12} align="center"><Danger>{Loc.list_empty}</Danger></GridItem>
    return theDiv;
  }

  React.useEffect(() => {  
    setHistoryState(props.history.location.state)
    var promise_preLoad = null;

    if (!isPreLoadedData)
    {
        setIsPreLoadedData(true);

        promise_preLoad = do_loadListOfCollection(collectionsToLoad, {}).then(returnList => {
            console.log("returnList: ", returnList);
            setData_dataListArray(returnList);
        })
    }
    // pre load data for edit
    if (data_dataListArray !== null && props.location.state.data !== null && !isLoadedEditData)
    {
        var dataToEdit = props.location.state.data;
        
        setIsLoadedEditData(true);
        setIsEdit(true);
        setData_id(dataToEdit.id);
        setData_imageUrl(dataToEdit.imageUrl);
        setData_itemName(dataToEdit.itemName);
        setData_categoryID(dataToEdit.categoryID);
        setData_categoryName(dataToEdit.categoryName);
        setData_price(dataToEdit.price);
        setData_commission(dataToEdit.commission);
        setData_remark(dataToEdit.remark);
        setData_itemsInPackage(dataToEdit.itemsInPackage);
        process_itemsInPackageTableList(dataToEdit.itemsInPackage);
    }


    // if (data_dataListArray != null)
    // {
    //     console.log("data_itemsInPackage: ", data_itemsInPackage);
    //     process_itemsInPackageTableList(data_itemsInPackage);
    // }
    // else
    // {
    //     console.log("data_dataListArray null");
    // }
    
    // Specify how to clean up after this effect:
    return function cleanup() {
        promise_preLoad = null;
    };
  }, [data_dataListArray]);

  function do_loadListOfCollection(collectionsList, onHoldList)
  {
      var newList = collectionsList;
      var newOnHoldList = onHoldList;

      const targetCollection = newList[0];
      return new Promise((onDone) => {
          const body = {
              "data_targetCollection":targetCollection
          }
          fetchAPI.do_fetch('post', 'admin/list-all', body)
          .then((res) => {
            newOnHoldList[targetCollection] = res.data.data_list;
            newList.splice(0, 1);

            if (newList.length > 0)
            {
                do_loadListOfCollection(newList, newOnHoldList).then(finalOnHoldList => {
                    onDone(finalOnHoldList);
                })
            }
            else
            {
                onDone(newOnHoldList);
            }
            //   onDone(res.data.data_list);
          }, error => {
              console.log("failed: ", error);
              onDone(null);
          })
      });
  }

  function process_itemTableList(targetData)
  {
    // console.log("process_itemTableList targetData: ", targetData);
    if (targetData != null)
    {
        const newData = targetData.filter(v => v.isDeleted != true).map((prop, key) => {
            const product_id = prop.id;
            const itemName = prop.itemName;
            const price = prop.price;
            
            return {
              no: key+1,
              id: key,
              product_id: product_id,
              itemName: (
              <span>
                {/* <img src={prop.imageUrl} alt="..." /> */}
                {itemName}
              </span>
              ),
              product_id: product_id,
              price: "$ " + price,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                    <Button color="success" 
                    simple 
                    className={classes_tableBtn.actionButton}
                    onClick={() => {
                        do_addItemIntoPackage(prop, "item");
                    }}>
                        <ICON_ADD className={classes_tableBtn.icon}/>
                    </Button>
                </div>
              )
            };
          })
      
        return newData;
    }
    else
    {
        return [];
    }
    //   setData_itemTableList(newData);
  }

  function process_couponTableList(targetData)
  {
    // console.log("process_couponTableList targetData: ", targetData);
    if (targetData != null)
    {
        const newData = targetData.filter(v => v.isDeleted != true).map((prop, key) => {
            const product_id = prop.id;
            const itemName = prop.itemName;
            const price = prop.price;
            
            return {
              no: key+1,
              id: key,
              product_id: product_id,
              itemName: (
              <span>
                {/* <img src={prop.imageUrl} alt="..." /> */}
                {itemName}
              </span>
              ),
              product_id: product_id,
              price: "$ " + price,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                    <Button color="success" 
                    simple 
                    className={classes_tableBtn.actionButton}
                    onClick={() => {
                        do_addItemIntoPackage(prop, "coupon");
                    }}>
                        <ICON_ADD className={classes_tableBtn.icon}/>
                    </Button>
                </div>
              )
            };
          })
      
        return newData;
    }
    else
    {
        return [];
    }
    //   setData_couponTableList(newData);
  }

  function do_addItemIntoPackage(targetData, targetType)
  {
    const targetID = targetData.id;
    const targetName = targetData.itemName;
    var newItemList = data_itemsInPackage;
    var itemIndexKey = -1;
    var hasCoupon = false;
    var hasProduct = false;
    newItemList.map((item, key) => {
        if (item.id === targetID)
        {
            itemIndexKey = key;
        }

        if (item.type === "coupon")
        {
            hasCoupon = true;
        }
        if (item.type == "item")
        {
            hasProduct = true
        }
    });

    if (hasProduct && targetType != "item")
    {
        showAlert(false, Loc.failed, Loc.errorMsg_package_content_error);
    }
    else if (hasCoupon && targetType != "coupon")
    {
        showAlert(false, Loc.failed, Loc.errorMsg_package_content_error);
    }
    else
    {
        if (itemIndexKey == -1)
        {
            const newItem = {
                id: targetID,
                qty: 1.0,
                qty_free: 0.0,
                type: targetType,
                itemName: targetName,
            }
            newItemList.push(newItem);
        }
        else
        {
            var newQty = parseFloat(newItemList[itemIndexKey].qty);
            newQty += 1.0;
            newItemList[itemIndexKey].qty = newQty;
        }
        setData_itemsInPackage(newItemList);
        process_itemsInPackageTableList(newItemList);

        console.log("data_itemsInPackage: ", data_itemsInPackage);
    }

  }

  const validCheck = () => {
    var result = true;
    if (data_name == "")
    {
        result = false;
    }
    return result;
  }

  const backClicked = () => {
    setRedirect("/admin/product-package-list");
  }

  const doneClicked =() => {
    setFetchingStatus("fetching");
    if (validCheck())
    {
        var hasCoupon = false;
        var hasProduct = false;
        data_itemsInPackage.map((i, k) => {
            if (i.type === "item")
            {
                hasProduct = true;
            }
            if (i.type === "coupon")
            {
                hasCoupon = true;
            }
        })

        var packageType = ""
        if (hasCoupon && hasProduct)
        {
            packageType = "mix";
        }
        else if (hasCoupon && !hasProduct)
        {
            packageType = "coupon";
        }
        else if (!hasCoupon && hasProduct)
        {
            packageType = "product";
        }

        console.log("data_itemsInPackage: ", data_itemsInPackage);
        const idToPass = isEdit ? data_id : null;
        const body = {
            "id":idToPass,
            "content":{
                "itemName":data_name,
                "categoryID":data_categoryID,
                "categoryName":data_categoryName,
                "price":data_price,
                "commission":data_commission,
                "remark":data_remark,
                "imageUrl":data_imageUrl,
                "itemsInPackage":data_itemsInPackage,
                "type":packageType,
            }
        }
        fetchAPI.do_fetch('post', 'admin/create-package', body)
        .then((res) => {
            // console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
        }, error => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
        })
    }
    else
    {
        setproductNameState("error");
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  }

  function showAlert(issuccess, title, content){
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
            if (issuccess)
            {
                // hideAlert()
                setRedirect("/admin/product-package-list");
            }
            else
            {
                hideAlert()
            }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            if (issuccess)
            {
                setData_itemName("");
                setData_imageUrl("");
                setData_categoryID("");
                setData_categoryName("");
                setData_price(0);
                setData_commission(0);
                setData_remark("");
                setData_itemsInPackage([]);
                process_itemsInPackageTableList([]);
                // window.location.reload(false);
                hideAlert()
            }
            else
            {
                hideAlert()
            }
            
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }
  function showConfirmRemoveFromPackageAlert(itemKey){
    setConfirmAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_delete_title}
        onConfirm={() => {
            var newItemsInPackage = data_itemsInPackage;
            newItemsInPackage.splice(itemKey, 1);
            setData_itemsInPackage(newItemsInPackage);
            process_itemsInPackageTableList(newItemsInPackage);
            hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  const handleChange_gender = event => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect  to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }} />);
      }
      else
      {
          return null;
      }
  }

  const imageDidUpload = (pathInStorage) => {
      if (pathInStorage != null)
      {
        setData_imageUrl(pathInStorage);
        console.log("imageDidUpload called, path: ", pathInStorage);
      }
  }

  function process_itemsInPackageTableList(rawData_itemsInPackage) {
      console.log("process_itemsInPackageTableList: ", rawData_itemsInPackage);

    const list = rawData_itemsInPackage.map((prop, key_itemInPackage) => {

        const itemID = prop.id;
        var itemName = "";
        var isDeleted = false;

        // get item name
        if (prop.type === "item")
        {
            data_dataListArray["Product"].map((item, key) => {
                if (item.id === itemID)
                {
                    itemName = item.itemName;
                    isDeleted = (item.isDeleted != null) ? item.isDeleted : false;
                    itemName += (isDeleted) ? ("(" + Loc.deleted + ")") : "";
                }
            })
        }
        // get coupon name
        else if (prop.type === "coupon")
        {
            data_dataListArray["Product-Coupon"].map((coupon, key) => {
                if (coupon.id === itemID)
                {
                    itemName = coupon.itemName;
                    isDeleted = (coupon.isDeleted != null) ? coupon.isDeleted : false;
                    itemName += (isDeleted) ? ("(" + Loc.deleted + ")") : "";
                }
            })
        }
        else
        {
            console.log("item type not match");
        }

        return [
            (
                isDeleted ? <Danger>{itemName}</Danger> : itemName
            ),
            (<CustomInput
                labelText={Loc.quantity_short}
                id="quantity_short"
                formControlProps={{
                fullWidth: true
                }}
                inputProps={{
                    value: rawData_itemsInPackage[key_itemInPackage].qty,
                    onChange: event => {
                        var newItemsInPackage = rawData_itemsInPackage;
                        const newQty = parseFloat(event.target.value);

                        if (newQty <= 0)
                        {
                            showConfirmRemoveFromPackageAlert(key_itemInPackage);
                        }
                        else
                        {
                            newItemsInPackage[key_itemInPackage].qty = newQty;
                        }
                        setData_itemsInPackage(newItemsInPackage);
                        process_itemsInPackageTableList(newItemsInPackage);
                    },
                    type: "number",
                }}
            />),
            (<CustomInput
                labelText={Loc.quantity_short}
                id="quantity_free_short"
                formControlProps={{
                fullWidth: true
                }}
                inputProps={{
                    value: (rawData_itemsInPackage[key_itemInPackage].qty_free && rawData_itemsInPackage[key_itemInPackage].qty_free !== null) ? rawData_itemsInPackage[key_itemInPackage].qty_free : 0,
                    onChange: event => {
                        var newItemsInPackage = rawData_itemsInPackage;
                        var newQty = parseFloat(event.target.value);

                        if (newQty <= 0)
                        {
                            newQty = 0;
                            // showConfirmRemoveFromPackageAlert(key_itemInPackage);
                        }
                        else
                        {
                            newItemsInPackage[key_itemInPackage].qty_free = newQty;
                        }
                        setData_itemsInPackage(newItemsInPackage);
                        process_itemsInPackageTableList(newItemsInPackage);
                    },
                    type: "number",
                }}
            />),
            (<Button color="danger" simple className={classes_tableBtn.actionButton} onClick={(e) => {
                showConfirmRemoveFromPackageAlert(key_itemInPackage);
            }}>
                <Close className={classes_tableBtn.icon}/>
            </Button>)
        ]
    });
    console.log("list: ", list);
    setData_itemsInPackageTableList(list);
  }
  
    const classes = useStyles();
    const classes_alert = useStyles_alert();
    const classes_tableBtn = useStyles_tableBtn();

    return (
    <div>
        {check_redirect()}
        {alert_save}
        {alert_confirm}
        <GridContainer>
        <GridItem xs={6}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.package_info}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <GridContainer>
                <GridItem xs={6} align="center">
                    <ImageUpload imageDidUpload={(res) => imageDidUpload(res)} imageUrl={data_imageUrl} targetFolder="Product"/>
                    
                    <GridItem xs={12} align="center">
                        <Dropdown
                            currentID={data_categoryID}
                            listData={data_dataListArray === null ? [] : data_dataListArray["Category"]}
                            defaultSelectionString={Loc.category_default}
                            didSelectItem={(selectedItem) => {
                                if (selectedItem === null)
                                {
                                    setData_categoryName("");
                                    setData_categoryID("");
                                }
                                else
                                {
                                    const selectedID = selectedItem.id;
                                    const categoryName = selectedItem.itemName;
                                    setData_categoryName(categoryName);
                                    setData_categoryID(selectedID);
                                }
                                
                            }}
                        />
                </GridItem>
                </GridItem>

                <GridItem xs={6}>
                    <GridItem xs={12}>
                        <CustomInput
                            // success={requiredState === "success"}
                            error={productNameState === "error"}
                            labelText={Loc.package_name+"*"}
                            id="package_name"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: data_name,
                                onChange: event => {
                                    setproductNameState("success");
                                    setData_itemName(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>                    

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.package_commission}
                        id="package_commission"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_commission,
                            onChange: event => {
                                setData_commission(event.target.value);
                            },
                            type: "number",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.package_price}
                        id="package_price"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_price,
                            onChange: event => {
                                setData_price(event.target.value);
                            },
                            type: "number",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.remark}
                        id="remark"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_remark,
                            onChange: event => {
                                setData_remark(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>
                </GridItem>

                <GridItem xs={12}>
                    <Card>
                    <CardHeader color="info" text>
                        <CardText color="info">
                            <h5 className={classes.cardTitle}>{Loc.package_content}</h5>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                    {
                        data_itemsInPackage.length == 0 ? 
                        div_list_empty()
                        : 
                        <Table
                        tableHead={[
                            // "#",
                            Loc.product,
                            Loc.quantity_short,
                            Loc.quantity_short_free,
                            Loc.remove
                        ]}
                        tableData={data_itemsInPackageTableList}
                        customCellClasses={[classes.left, classes.right]}
                        customClassesForCells={[0, 3]}
                        customHeadCellClasses={[
                            classes.left,
                            classes.right,
                        ]}
                        customHeadClassesForCells={[0, 3]}
                    />
                    }
                    </CardBody>
                    </Card>
                </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer spacing={2}
                direction="row"
                justify="center"
                alignItems="center">
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button color="rose" onClick={doneClicked} disabled={fetchingStatus==="fetching" ? true : false}>
                        <ICON_DONE />
                        {isEdit ? Loc.save : Loc.create}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>

        <GridItem xs={6}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.search}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                
                {/* <hr/> */}
                <NavPills
                color="info"
                tabs={[
                  
                  {
                    tabButton: Loc.product,
                    tabContent: (
                        (data_dataListArray == null || data_dataListArray["Product"] == null || process_itemTableList(data_dataListArray["Product"]).length == 0) ? 
                        div_list_empty()
                        :
                        <ReactTable
                            columns={[
                                {
                                Header: Loc.product_name,
                                accessor: "itemName"
                                },
                                {
                                Header: Loc.product_price,
                                accessor: "price"
                                },
                                {
                                Header: Loc.add,
                                accessor: "actions"
                                }
                            ]}
                            data={data_dataListArray == null ? [] : process_itemTableList(data_dataListArray["Product"])}
                        />
                    )
                  },
                  {
                    tabButton: Loc.coupon,
                    tabContent: (
                        (data_dataListArray == null || data_dataListArray["Product-Coupon"] == null || process_couponTableList(data_dataListArray["Product-Coupon"]).length == 0) ? 
                        div_list_empty()
                        :
                        <ReactTable
                            columns={[
                                {
                                Header: Loc.coupon_name,
                                accessor: "itemName"
                                },
                                {
                                Header: Loc.coupon_price,
                                accessor: "price"
                                },
                                {
                                Header: Loc.add,
                                accessor: "actions"
                                }
                            ]}
                            data={data_dataListArray == null ? [] : process_couponTableList(data_dataListArray["Product-Coupon"])}
                        />
                    )
                  }
                ]}
              />
            </CardBody>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}