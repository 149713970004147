import React from "react";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Dropdown(props) {

    const classes = useStyles();
    const [data_listData, setData_listData] = React.useState([]);
    const [data_currentID, setData_currentID] = React.useState("");
    const [data_itemList, setData_itemList] = React.useState([]);
    const [data_itemIDList, setData_itemIDList] = React.useState([]);

    React.useEffect(() => {  
        if (props.listData != null)
        {
            setData_listData(props.listData);
            processData(props.listData);
        }

        if (props.currentID != null && props.currentID != data_currentID)
        {
            setData_currentID(props.currentID);
            // props.didSelectItem(props.currentID);
        }

        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    }, [props.currentID, props.listData]);

    

    function processData(targetData)
    {
        var newList = [<span>{props.defaultSelectionString}</span>];
        var newIDList = [""];
        targetData.filter(v => v.isDeleted != true).map((prop, key) => {
            const item_id = prop.id;
            const itemName = prop.itemName;

            const selection_item = (
                <span>{itemName}</span>
            );

            newList.push(selection_item);
            newIDList.push(item_id);
        });

        setData_itemList(newList);
        setData_itemIDList(newIDList);
    }

    const getStoreStringToShow = () => {
        var strToShow = data_itemList[0];
        data_listData.map((prop, key) => {
            if (prop.id === data_currentID)
            {
                strToShow = prop.itemName;
            }
        });

        // console.log("getStoreStringToShow called, strToShow: ", strToShow);

        return strToShow;
    }

    return(
        <CustomDropdown
            buttonProps={{
                round: true,
                color: "info",
            }}
            buttonText={<span>{getStoreStringToShow()}</span>}
            dropdownList={data_itemList}
            itemIDList={data_itemIDList}
            onClick={(selectedID) => {
                setData_currentID(selectedID);

                var itemToReturn = null;
                data_listData.map((i, k) => {
                    if (i.id === selectedID)
                    {
                        itemToReturn = i;
                    }
                })
                props.didSelectItem(itemToReturn);
            }}
        />
    );
}