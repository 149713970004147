// import react, react-markdown-editor-lite, and a markdown parser you like
import React, { forwardRef,useEffect, useState,useImperativeHandle } from "react";
import * as ReactDOM from "react-dom";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
// import style manually
import "react-markdown-editor-lite/lib/index.css";
import { Select } from "@material-ui/core";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

// Register plugins if required
// MdEditor.use(YOUR_PLUGINS_HERE);
const MDApp = forwardRef((props, ref) =>
{

  const {Input,value,DidAcceptedInput } = props;
  const [data_value,setData_value] = useState("");
  const [isLoadedData,setIsLoadedData] = useState(true);
  // Initialize a markdown parser
  const mdParser = new MarkdownIt({html: true, canView:{fullScreen:false}});

  if(isLoadedData){
    if (value){
      setData_value(value);
      setIsLoadedData(false);

    }
  }

  React.useEffect(() => {  
    // if(value && value.length > 0){
    // console.log("_ren md "+value);
    
    // console.log("_ren data_value "+data_value);

    // }
    return function cleanup() {};

  },[data_value,isLoadedData]);
  // Finish!
  function handleEditorChange({ html, text }) {
    
    DidAcceptedInput(text);
    
  }

  useImperativeHandle(ref, () => ({

    update_text(e,text) {
    // console.log("useImperativeHandle e "+ e );
    // console.log("useImperativeHandle text "+ text );
      setData_value(text);
    }

  }));

  return (
    <div>
      <MdEditor
        style={{ height: "500px" }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
        value={data_value}
        canView={{fullScreen:false}}
      />
    </div>
  );
});

export default MDApp;
