import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js"

import NavPills from "components/NavPills/NavPills.js";
import Danger from "components/Typography/Danger.js"
import Success from "components/Typography/Success.js"
import Info from "components/Typography/Info.js"
import Warning from "components/Typography/Warning.js"
import FormControl from "@material-ui/core/FormControl";
import Table from "components/Table/Table.js";
import moment from 'moment';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_DONE from "@material-ui/icons/Done";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);


export default function ActionLog_list() {

  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setConfirmAlert(null);
  }

  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  
  

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const classes_tableBtn = useStyles_tableBtn();
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(100);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  

  React.useEffect(() => {  

    if (data_dataList != null)
    {
      processDataWithActions();
    }

    if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0)
    {
      set_isLoaded(true);
      do_loadDataList();
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [data_pageCount, data_page, data_dataList]);

  function do_loadDataList()
  {
    set_isLoading(true);
    const body = {
      "page":data_page,
      "pageCount":data_pageCount,
    }
    fetchAPI.do_fetch('post', 'admin/list-log', body)
    .then((res) => {
      set_isLoading(false);
        // console.log("success: ", res.data);
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
    }, error => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function do_deleteData(targetID)
  {
    const body = {
      "idList": [targetID],
    }

    fetchAPI.do_fetch('post', 'admin/del-customer', body)
    .then((res) => {
        console.log("success: ", res.data);
        do_loadDataList();
        showAlert(true, Loc.success, Loc.data_delete_success);
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_delete_failed);
    })
  }

  function processDataWithActions()
  {
    const newData = data_dataList.filter(v => v.isDeleted != true).map((prop, key) => {
    
      const dateObj = new Date(prop.createDate);
      const momentObj = moment(dateObj);
      // console.log("momentObj: ", momentObj);
      const datetimeStr = momentObj.format("YYYY-MM-DD, HH:mm:ss");
      const adminName = prop.adminName;
      var actionDiv = null;
      switch (prop.actionType)
      {
        case "create":
          actionDiv = <Success>{Loc.action_type_create}</Success>;
          break;
        case "edit":
          actionDiv = <Info>{Loc.action_type_edit}</Info>;
          break;
        case "delete":
          actionDiv = <Warning>{Loc.action_type_delete}</Warning>;
          break;

        default:
          actionDiv = <Danger>{Loc.action_type_unknown}</Danger>;
          break;
      }


      var actionArea = Loc.action_area_unknown;
      switch (prop.actionArea)
      {
        case "Admin":
          actionArea = Loc.action_area_admin;
          break;

          case "AdminLevel":
          actionArea = Loc.action_area_adminLevel;
          break;

          case "Category":
          actionArea = Loc.action_area_category;
          break;

          case "Commission":
          actionArea = Loc.action_area_commission;
          break;

          case "Commission-Balance":
          actionArea = Loc.action_area_commissionBalance;
          break;

          case "Coupon-Balance":
          actionArea = Loc.action_area_couponBalance;
          break;

          case "Customer":
          actionArea = Loc.action_area_customer;
          break;

          case "Expenditure":
          actionArea = Loc.action_area_expenditure;
          break;

          case "Inventory-Balance":
          actionArea = Loc.action_area_inventoryBalance;
          break;

          case "Inventory-In":
          actionArea = Loc.action_area_inventoryIn;
          break;

          case "Payment":
          actionArea = Loc.action_area_payment;
          break;

          case "Product":
          actionArea = Loc.action_area_product;
          break;

          case "Product-Coupon":
          actionArea = Loc.action_area_productCoupon;
          break;

          case "Product-Package":
          actionArea = Loc.action_area_productPackage;
          break;

          case "Receivable":
          actionArea = Loc.action_area_receivable;
          break;

          case "Receivable-Balance":
          actionArea = Loc.action_area_receivableBalance;
          break;

          case "Sales":
          actionArea = Loc.action_area_sales;
          break;

          case "Staff":
          actionArea = Loc.action_area_staff;
          break;

          case "Store":
          actionArea = Loc.action_area_store;
          break;

        default:
          break;
      }

      const relatedID = prop.targetID;

      return [
        datetimeStr,
        adminName,
        actionDiv,
        actionArea,
        relatedID,
      ];
    })
    setData(newData);
  }

  function showConfirmDeleteAlert(customerID){
    setConfirmAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_delete_title}
        onConfirm={() => {
          hideAlert();
          do_deleteData(customerID);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={Loc.confirm_delete_confirm}
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

const check_redirect = () => {
  if (pathToRedirect != "")
  {
    if (itemToEdit != null)
    {
      return (<Redirect to={{
        pathname: pathToRedirect,
        state: itemToEdit
      }} />);
    }
    else
    {
      return (<Redirect to={{
        pathname: pathToRedirect,
        state: null,
      }} />);
    }
  }
  else
  {
      return null;
  }
}

const showloading = () => {
  if (isLoading)
  {
    return (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.loading}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
        showConfirm={false}
        showCancel={false}
      >
        {Loc.please_wait}
      </SweetAlert>
    )
  }
  else
  {
    return null;
  }
}
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <PagingTable
              tableHead={[
                Loc.action_date,
                Loc.action_adminName,
                Loc.action_actionType,
                Loc.action_actionArea,
                Loc.action_relatedID
              ]}
              tableData={data}
              // customCellClasses={[classes_tableBtn.center, classes_tableBtn.center, classes_tableBtn.right]}
              // customClassesForCells={[0, 0, 5]}
              // customHeadCellClasses={[
              //   classes_tableBtn.center,
              //   classes_tableBtn.center,
              //   classes_tableBtn.right
              // ]}
              // customHeadClassesForCells={[0, 0, 5]}
              gotoPageClicked={(targetPage) => {
                set_page(targetPage);
                set_isLoaded(false);
              }}
              changedPageCount={(newPageCount) => {
                set_page(0);
                set_pageCount(newPageCount);
                set_isLoaded(false);
              }}
              totalDataCount={data_dataListCount}
              page={data_page}
              pageCount={data_pageCount}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
