import React from "react";
import { Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import moment from "moment";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";


import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_ADD from "@material-ui/icons/Add";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Recover from "@material-ui/icons/Replay";

import { saveAs } from "file-saver";
import SuccessText from "components/Typography/Success.js";
import InfoText from "components/Typography/Info.js";
import MutedText from "components/Typography/Muted.js";
import DangerText from "components/Typography/Danger.js";
import Info from "components/Typography/Info";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

export default function Sales_list(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  };
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [data_isDataListLoaded, setData_isDataListLoaded] = React.useState(
    false
  );
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);

  const classes_tableBtn = useStyles_tableBtn();
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState((!localStorage.getItem("searchData") || localStorage.getItem("searchData") == null) ? false:true);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [locationState, setLocationState] = React.useState(null);

  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);
  const data_targetCollection = "Sales-Record";

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());
  const [firstEnter,setFirstEnter] = React.useState(true);
  React.useEffect(() => {

    if(!locationState || locationState == null ){
      
      var preloadListStr = localStorage.getItem("preloadDataList")
      var preloadList = JSON.parse(preloadListStr);
      // console.log("preloadList  "+JSON.stringify(item))
      setLocationState(preloadList);
      setData_dataListArray(preloadList);
    }else{
      if (data_dataList != null) {
        processDataWithActions();
      }
  
      if((!localStorage.getItem("searchData") || localStorage.getItem("searchData") == null)){
        if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
          // set_isLoaded(true);
          // // console.log("do_loadDataList(); in start place")
          // do_loadDataList();
        }
      }
    }
      
    //For customer paging part
      
    
    
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [data_pageCount, data_page, data_dataList, showDeletedData,firstEnter,locationState]);
  var xls = require("exceljs");

  async function operation() {
    var workbook = new xls.Workbook();

    workbook.created = new Date();

    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("sheet");

    //calcualte part

    // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
    // //merge cell
    // worksheet.mergeCells("A1:"+headerEndColumn+"1");
    // worksheet.mergeCells("A2:"+headerEndColumn+"2");

    worksheet.columns = getExcelColumns();

    const tableHeaderList = tableHeader.map((item) => {
      if (item !== Loc.actions) {
        return item;
      }
    });
    //Fixed Items
    const rows = [tableHeaderList];
    // //columns
    const titleRows = worksheet.getRow(1);

    // // // report_itemName
    titleRows.values = tableHeaderList;
    titleRows.alignment = { vertical: "middle", horizontal: "center" };

    // console.log("getExcelData()   "+JSON.stringify(getExcelData()))
    // worksheet.addRows(rows);
    //add data part
    worksheet.addRows(getExcelData());
    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    const fileName = `${pageName}_${moment(data_startDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}~${moment(data_endDate).format("YYYY-MM-DD-hh-mm-ss")}`;
    saveAs(blob, fileName + fileExtension);
  }

  function getExcelColumns() {
    var columns = [];
    tableHeader.map((item) => {
      if (item != Loc.actions)
        columns.push({ header: "", key: item, width: 20 });
    });

    return columns;
  }

  function getExcelData() {
    var _totalCommission = 0;
    var _totalStoreSales = 0;
    var _totalActualSales = 0;
    var _totalSalesCouponUsed = 0
    
    const newData = data_dataList.map((prop, key) => {
      const dateStr = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
      const code = prop.customerCode;
      const itemsInPackage = prop.itemsInPackage;
      const usedCouponList = prop.couponUsageList;
      const couponUsageList = prop.couponUsageList;
      const totalSales = get_actualSales(itemsInPackage, usedCouponList);
      const totalSalesContent = get_actualSalesContent(
        itemsInPackage,
        usedCouponList,
        true
      );

      const totalStoreSalesContent = get_actualStoreSalesContent(
        itemsInPackage,
        usedCouponList,
        true
      );
      const totalCommittion = get_commissionAmount(
        itemsInPackage,
        usedCouponList
      );
  
      _totalCommission += totalCommittion;
      _totalStoreSales += totalSales - totalCommittion;
      _totalActualSales += totalSales;

      const totalReceive = prop.totalReceive.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const totalReceive_net = parseFloat(prop.totalReceive_net).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );

      const storeStr =
        prop.storeName === "" ? Loc.store_default : prop.storeName; //(dataList, "Store", storeID, Loc.store_default);
      const customerName = prop.customerName; //getNameWithID(dataList, "Customer", customerID, "-");
      const paymentStr =
        prop.paymentName === "" ? Loc.payment_default : prop.paymentName; //getNameWithID(dataList, "Payment", paymentID, Loc.payment_default);

      var itemsListContentToShow = get_packageContent(
        itemsInPackage,
        usedCouponList,
        true
      );

      var itemsListCommissionContentToShow = get_commissionContent(
        itemsInPackage,
        usedCouponList,
        true
      );

      const itemsListContent =
        itemsListContentToShow != null ? itemsListContentToShow:get_useCouponContent(couponUsageList,true)
        var totalSalesCouponUsed = get_totalUsedCoupon(
          itemsInPackage,
          usedCouponList,
          true
        );
        _totalSalesCouponUsed += totalSalesCouponUsed
        var totalUsedCouponContent = get_totalUsedCouponContent(
          itemsInPackage,
          usedCouponList,
          true
        );
      const deleteButton = (
        <>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              showConfirmDeleteAlert(prop);
            }}
            color="danger"
            className="remove"
          >
            {showDeletedData ? <Recover /> : <Close />}
          </Button>{" "}
        </>
      );

      return [
        code,
        storeStr,
        customerName,
        dateStr,
        paymentStr,
        itemsListContent,
        totalUsedCouponContent,
        totalSalesContent,
        itemsListCommissionContentToShow,
        totalStoreSalesContent,
        totalReceive,
        totalReceive_net,
      ];
    });
    const totalAmountRow = showExtendedTable
      ? [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          Loc.sales_coupon_used_qty +
              ": " +
          parseFloat(_totalSalesCouponUsed).toFixed(1)
          ,
          Loc.sales_amount_total +
              ": " +
              parseFloat(_totalActualSales).toFixed(1),
          Loc.sales_amount_total +
              ": " +
              parseFloat(_totalCommission).toFixed(1),
          Loc.sales_amount_total +
              ": " +
              parseFloat(_totalStoreSales).toFixed(1),
          "",
          "",
          "",
          "",
        ]
      : [];
    newData.push(totalAmountRow);
    return newData;
  }

  function do_loadDataList() {
    set_isLoading(true);
    // console.log("do_loadDataList(); api")

    const body = {
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: showDeletedData,
    };

    fetchAPI.do_fetch("post", "admin/list-sales", body).then(
      (res) => {
        set_isLoading(false);
        // console.log("success: ", res.data);
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function do_deleteData(targetID) {
    return new Promise((onDone) => {
      if (targetID != null) {
        const body = {
          idList: [targetID],
          targetCollection: "Sales",
          targetID: targetID,
        };
        const path = showDeletedData
          ? "admin/recover-deleted"
          : "admin/del-sales";
        fetchAPI.do_fetch("post", path, body).then(
          (res) => {
            // console.log("success: ", res.data);
            onDone(true);
          },
          (error) => {
            console.log("failed: ", error);
            onDone(false);
          }
        );
      } else {
        onDone(true);
      }
    });
  }

  function do_addBackInventoryBalance(rec) {
    return new Promise((onDone) => {
      var newChangeList = [];
      rec.itemsInPackage.map((item, key) => {
        if (item.type === "item") {
          const newChange = {
            id: item.id,
            type: item.type,
            change_val: item.qty,
            itemName: item.itemName,
          };
          newChangeList.push(newChange);
        } else if (
          item.type === "package" ||
          item.type === "package_coupon" ||
          item.type === "package_product"
        ) {
          item.itemsInPackage.map((i, k) => {
            if (i.type === "item") {
              const newChange = {
                id: i.id,
                type: i.type,
                change_val: i.qty * item.qty,
                itemName: i.itemName,
              };
              newChangeList.push(newChange);
            }
          });
        }
      });

      // console.log("newChangeList: ", newChangeList);

      if (newChangeList.length != 0) {
        const target_balance = "Inventory-Balance";
        const body = {
          data_targetCollection: target_balance,
          changeList: newChangeList,
        };
        fetchAPI.do_fetch("post", "admin/edit-balance", body).then(
          (res) => {
            onDone(true);
          },
          (error) => {
            console.log("failed: ", error);
            onDone(false);
          }
        );
      } else {
        onDone(true);
      }
    });
  }

  function do_removeBoughtCouponBalance(itemsInPackage, customerID) {
    return new Promise((onDone) => {
      do_getCustomerCouponList(customerID).then((couponBalanceRecordList) => {
        if (couponBalanceRecordList !== null) {
          // get id of coupon balance record to delete
          var listToDelete = [];
          itemsInPackage.map((item, key) => {
            if (item.type === "coupon" || item.type === "package_coupon") {
              couponBalanceRecordList.map((rec, key) => {
                if (rec.cart_uuid === item.cart_uuid) {
                  listToDelete.push(rec.id);
                }
              });
            }
          });

          if (listToDelete.length != 0) {
            const body = {
              idList: listToDelete,
              data_targetCollection: "Coupon-Balance",
            };

            fetchAPI.do_fetch("post", "admin/del-item", body).then(
              (res) => {
                // console.log("success: ", res.data);
                onDone(true);
              },
              (error) => {
                console.log("failed: ", error);
                onDone(false);
              }
            );
          } else {
            console.log("no match cart-id for coupon-balance record found");
            onDone(true);
          }
        } else {
          console.log("couponBalanceRecordList is null");
          onDone(false);
        }
      });
    });
  }

  function do_addBackUsedCouponBalance(usageList, customerID) {
    return new Promise((onDone) => {
      if (usageList.length != 0) {
        do_getCustomerCouponList(customerID).then((couponBalanceRecordList) => {
          if (couponBalanceRecordList != null) {
            const data = couponBalanceRecordList.filter(
              (v) => v.isDeleted != true
            );
            var listToUpdate = [];

            usageList.map((usedItem, k_used) => {
              data.map((rec, k_rec) => {
                if (usedItem.id === rec.id) {
                  const changeItem = {
                    id: rec.id,
                    content: {
                      qty: rec.qty + usedItem.use,
                    },
                  };

                  listToUpdate.push(changeItem);
                }
              });
            });

            if (listToUpdate.length != 0) {
              const body = {
                customerID: customerID,
                changeList: listToUpdate,
              };

              fetchAPI
                .do_fetch("post", "admin/create-coupon-balance", body)
                .then(
                  (res) => {
                    // console.log("do_addBackUsedCouponBalance success: ", res);
                    onDone(res.data.idList);
                  },
                  (error) => {
                    console.log("failed: ", error);
                    onDone(null);
                  }
                );
            } else {
              onDone([]);
            }
          } else {
            console.log("couponBalanceRecordList is null");
            onDone([]);
          }
        });
      } else {
        onDone([]);
      }
    });
  }

  function do_getCustomerCouponList(targetCustomerID) {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Coupon-Balance",
        data_relatedIDName: "customerID",
        data_relatedID: targetCustomerID,
      };
      fetchAPI.do_fetch("post", "admin/list-related-records", body).then(
        (res) => {
          // console.log("coupon List success: ", res.data);
          onDone(res.data.data_List);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function do_loadList(targetCollection) {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: targetCollection,
      };
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          // console.log("item success: ", res.data);
          // setData_itemList(res.data);
          // const newDataList = {"ProductList": res.data}
          onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function getNameWithID(dataList, targetDataSetName, targetID, defaultName) {
    var nameStr = defaultName;
    if (dataList != null && dataList[targetDataSetName] != null) {
      dataList[targetDataSetName].map((item, key) => {
        if (item.id == targetID) {
          nameStr = item.itemName;
        }
      });
    }
    return nameStr;
  }

  function get_useCouponContent(couponUsed,stringOnly) {
    var content = null;

    if (couponUsed.length != 0) {
      content = couponUsed.map((prop, key_itemInPackage) => {
        const couponID = prop.couponID;
        var itemName = (prop.isExchange) ? (Loc.isExchangeMark + prop.itemName) : prop.itemName;
        const qty = prop.use;

        if(stringOnly){
          return itemName //+' x '+ qty
        }
        return (
          <GridItem xs={12} key={key_itemInPackage}>
            <p>
              {itemName}
            </p>
          </GridItem>
        );
      });
    }

    return content;
  }

  function get_packageContent(itemsInPackage, usedCoupon,stringOnly) {
    var content_sales = null;
    var content_redeem = null;

    if (itemsInPackage.length !== 0) {
      content_sales = itemsInPackage.map((prop, key_itemInPackage) => {
        const itemID = prop.id;
        var itemName = prop.itemName;
        const qty = prop.qty;
        const isCouponPackage = prop.type == "package_coupon";
        // var isDeleted = false;
        if(stringOnly){
          if (key_itemInPackage !== itemsInPackage.length -1){
            return itemName +' x '+ qty +'\n'
          }else{
            return itemName +' x '+ qty
          }
        }else{
          if (isCouponPackage)
          {
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {itemName} 
                </p>
              </GridItem>
            );
          }
          else
          {
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {itemName} 
                </p>
              </GridItem>
            );
          }
      }
      });
    }

    if (usedCoupon.length !== 0) {
      content_redeem = usedCoupon.map((prop, key) => {
        const itemID = prop.id;
        var itemName = (prop.isExchange) ? (Loc.isExchangeMark + prop.exchange_itemName) : prop.exchange_itemName;
        const qty = prop.use;

        // var isDeleted = false;
        if(stringOnly){
          if (key !== usedCoupon.length -1){
            return itemName +' x '+ qty +'\n'
          }else{
            return itemName +' x '+ qty
          }
        }else{
          var itemNameContent = (prop.isExchange) ? (<DangerText><p>{itemName}</p></DangerText>) : (<p>{itemName}</p>)
          return (
          <GridItem xs={12} key={itemID}>
            {/* <GridContainer> */}
              {/* <GridItem xs={12}> */}
                {itemNameContent}
              {/* </GridItem> */}
            {/* </GridContainer> */}
          </GridItem>
        );}
      });
    }
    if(stringOnly){
      var contentToReturn = "";
      if (content_sales !== null)
      {
        contentToReturn += content_sales;
        contentToReturn += '\n'
      }
      
      if (content_redeem !== null)
      {
        contentToReturn += content_redeem;
      }
      return contentToReturn
    }else{
      return (
        <div>
          {content_sales}
          {/* {content_redeem === null || content_sales === null ? null : <br />} */}
          {content_redeem}
        </div>
      );
    }
  }

  function getCategoryType(productID){

    if (data_dataListArray){
      
      const categoryList =  data_dataListArray["Category"]
      const productList = data_dataListArray["Product"]
      var type = ""
      if (productList && categoryList){
        productList.forEach(productData => {
          if(productData.id == productID){
            const catID = productData.categoryID
            // console.log("catID: " + catID);
            categoryList.forEach(categoryData => {
              if (categoryData.id == catID){
                // console.log("categoryData.uuid: " + categoryData.uuid);
                type = categoryData.uuid;
              }
            });
          }
        });

      }

    }
    // console.log("type: " + type);
    return type
  }

  function get_totalUsedCoupon(itemsInPackage, usedCoupon)
  {
    var total = parseFloat(0.0);

    if (itemsInPackage.length !== 0) {
      itemsInPackage.map((prop, key_itemInPackage) => {
        var qty = prop.qty;
        const type = prop.type;
        const categoryType = getCategoryType(prop.id);
        if (type == "item" && categoryType == "cat_service"){
         total += qty
        }

      });
    }

    if (usedCoupon.length !== 0) {
      usedCoupon.map((prop, key) => {
        const qty = (prop.isExchange) ? 0 : prop.use;
        const type = prop.type;
        const categoryType = getCategoryType(prop.exchange_itemID);
        // console.log("categoryType: " + categoryType);
        if (categoryType == "cat_service"){
            total+= qty
        }
      });
    }
    return total;
    
  }

  function get_totalUsedCouponContent(itemsInPackage, usedCoupon,stringOnly)
  {
    var content_sales = null;
    var content_redeem = null;

    if (itemsInPackage.length !== 0) {
      content_sales = itemsInPackage.map((prop, key_itemInPackage) => {
        const itemID = prop.id;
        var itemName = prop.itemName;
        var qty = prop.qty;
        const type = prop.type;
        const categoryType = getCategoryType(prop.id);
        if (type == "item"){
          if (categoryType == "cat_service"){
            if(stringOnly){
              if (key_itemInPackage !== itemsInPackage.length -1){
                return parseFloat(qty).toFixed(1) +'\n'
              }else{
                return parseFloat(qty).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(qty).toFixed(1)}
                </p>
              </GridItem>
            );}
          }else{
            if(stringOnly){
              if (key_itemInPackage !== itemsInPackage.length -1){
                return parseFloat(0).toFixed(1) +'\n'
              }else{
                return parseFloat(0).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(0).toFixed(1)}
                </p>
              </GridItem>
            );}
          }
        }else{
          return (
            <GridItem xs={12} key={itemID}>
              <p>
                {parseFloat(0).toFixed(1)}
              </p>
            </GridItem>
          );
        }
        // console.log("TOTALUSECOUPONCONTENT:  "+JSON.stringify(prop));
        
        // var isDeleted = false;
        
      
      });
    }

    if (usedCoupon.length !== 0) {
      content_redeem = usedCoupon.map((prop, key) => {
        const itemID = prop.id;
        var itemName = (prop.isExchange) ? (Loc.isExchangeMark + prop.exchange_itemName) : prop.exchange_itemName;
        const qty = (prop.isExchange) ? 0 : prop.use;
        const type = prop.type;
        const categoryType = getCategoryType(prop.exchange_itemID);
        // if (type == "item"){
          // console.log("itemName  "+itemName+"  "+ categoryType)
          if (categoryType == "cat_service"){
            if(stringOnly){
              if (key !== usedCoupon.length -1){
                return parseFloat(qty).toFixed(1) +'\n'
              }else{
                return parseFloat(qty).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(qty).toFixed(1)}
                </p>
              </GridItem>
            );}
          }else{
            if(stringOnly){
              if (key !== usedCoupon.length -1){
                return parseFloat(0).toFixed(1) +'\n'
              }else{
                return parseFloat(0).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(0).toFixed(1)}
                </p>
              </GridItem>
            );}
          }
        // }else{
        //   return (
        //     <GridItem xs={12} key={itemID}>
        //       <p>
        //         {0}
        //       </p>
        //     </GridItem>
        //   );
        // }
      });
    }
    if(stringOnly){
      var contentToReturn = "";
      if (content_sales !== null)
      {
        contentToReturn += content_sales;
        contentToReturn += '\n'
      }
      
      if (content_redeem !== null)
      {
        contentToReturn += content_redeem;
      }
      return contentToReturn
    }else{
      return (
        <div>
          {content_sales}
          {/* {content_redeem === null || content_sales === null ? null : <br />} */}
          {content_redeem}
        </div>
      );
    }
  }

  function get_totalSoldProduct(itemsInPackage, usedCoupon)
  {
    var total = parseFloat(0.0);

    if (itemsInPackage.length !== 0) {
      itemsInPackage.map((prop, key_itemInPackage) => {
        var qty = prop.qty;
        const type = prop.type;
        const categoryType = getCategoryType(prop.id);
        if (type == "item" && categoryType == "cat_product"){
         total += qty
        }

      });
    }

    if (usedCoupon.length !== 0) {
      usedCoupon.map((prop, key) => {
        const qty = (prop.isExchange) ? 0 : prop.use;
        const type = prop.type;
        const categoryType = getCategoryType(prop.exchange_itemID);
        // console.log("categoryType: " + categoryType);
        if (categoryType == "cat_product"){
            total+= qty
        }
      });
    }
    return total;
    
  }

  function get_totalSoldProductContent(itemsInPackage, usedCoupon,stringOnly)
  {
    var content_sales = null;
    var content_redeem = null;

    if (itemsInPackage.length !== 0) {
      content_sales = itemsInPackage.map((prop, key_itemInPackage) => {
        const itemID = prop.id;
        var itemName = prop.itemName;
        var qty = prop.qty;
        const type = prop.type;
        const categoryType = getCategoryType(prop.id);
        if (type == "item"){
          if (categoryType == "cat_product"){
            if(stringOnly){
              if (key_itemInPackage !== itemsInPackage.length -1){
                return parseFloat(qty).toFixed(1) +'\n'
              }else{
                return parseFloat(qty).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(qty).toFixed(1)}
                </p>
              </GridItem>
            );}
          }else{
            if(stringOnly){
              if (key_itemInPackage !== itemsInPackage.length -1){
                return parseFloat(0).toFixed(1) +'\n'
              }else{
                return parseFloat(0).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(0).toFixed(1)}
                </p>
              </GridItem>
            );}
          }
        }else{
          return (
            <GridItem xs={12} key={itemID}>
              <p>
                {parseFloat(0).toFixed(1)}
              </p>
            </GridItem>
          );
        }
        // console.log("TOTALUSECOUPONCONTENT:  "+JSON.stringify(prop));
        
        // var isDeleted = false;
        
      
      });
    }

    if (usedCoupon.length !== 0) {
      content_redeem = usedCoupon.map((prop, key) => {
        const itemID = prop.id;
        var itemName = (prop.isExchange) ? (Loc.isExchangeMark + prop.exchange_itemName) : prop.exchange_itemName;
        const qty = (prop.isExchange) ? 0 : prop.use;
        const type = prop.type;
        const categoryType = getCategoryType(prop.exchange_itemID);
        // if (type == "item"){
          // console.log("itemName  "+itemName+"  "+ categoryType)
          if (categoryType == "cat_product"){
            if(stringOnly){
              if (key !== usedCoupon.length -1){
                return parseFloat(qty).toFixed(1) +'\n'
              }else{
                return parseFloat(qty).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(qty).toFixed(1)}
                </p>
              </GridItem>
            );}
          }else{
            if(stringOnly){
              if (key !== usedCoupon.length -1){
                return parseFloat(0).toFixed(1) +'\n'
              }else{
                return parseFloat(0).toFixed(1)
              }
            }else{
            return (
              <GridItem xs={12} key={itemID}>
                <p>
                  {parseFloat(0).toFixed(1)}
                </p>
              </GridItem>
            );}
          }
        // }else{
        //   return (
        //     <GridItem xs={12} key={itemID}>
        //       <p>
        //         {0}
        //       </p>
        //     </GridItem>
        //   );
        // }
      });
    }
    if(stringOnly){
      var contentToReturn = "";
      if (content_sales !== null)
      {
        contentToReturn += content_sales;
        contentToReturn += '\n'
      }
      
      if (content_redeem !== null)
      {
        contentToReturn += content_redeem;
      }
      return contentToReturn
    }else{
      return (
        <div>
          {content_sales}
          {/* {content_redeem === null || content_sales === null ? null : <br />} */}
          {content_redeem}
        </div>
      );
    }
  }


  function get_actualSales(data_itemsInPackage, data_couponToUse) {
    var total = parseFloat(0.0);
    var subcount_item = 0;
    var subcount_coupon = 0;
    // calculate all "item" type product sales
    data_itemsInPackage.map((iip, k_iip) => {
      if (iip.type === "item" || iip.type === "package_product") {
        subcount_item += parseFloat((iip.price === null) ? 0 : iip.price) * parseFloat((iip.qty === null) ? 0 : iip.qty);
      }

    });

    // calculate all redeem item's sales price
    data_couponToUse.map((ctu, k_ctu) => {
      if (
        !ctu.isExchange ||
        ctu.isExchange === null ||
        ctu.isExchange === false
      ) {
        subcount_coupon += parseFloat((ctu.exchange_salesPrice === null) ? 0 : ctu.exchange_salesPrice) * parseFloat((ctu.use === null) ? 0 : ctu.use);
      }
    });
    // console.log("subcount_item: " + subcount_item);
    // console.log("subcount_coupon: " + subcount_coupon);

    total = subcount_item + subcount_coupon;

    return total;
  }

  

  function get_actualSalesContent(data_itemsInPackage, data_couponToUse,stringOnly) {
    var content_sales = null;
    var content_redeem = null;

    // calculate all "item" type product sales
    content_sales = data_itemsInPackage.map((iip, k_iip) => {
      const itemID = iip.id;
      const isCouponPackage = iip.type == "package_coupon";
      // console.log("get_actualSalesContent  "+ JSON.stringify(iip))
      var itemCommission =
        parseFloat(iip.price) * parseFloat(iip.qty);
      // console.log(`itemCommission iip.price  ${iip.price} iip.commission ${iip.commission}  prop.qty  ${iip.qty} `)
      if (iip.type === "item") {
        itemCommission = roundValue(itemCommission);
      } else {
        itemCommission = 0;
      }
      if(stringOnly){
        if (k_iip !== data_itemsInPackage.length -1){
          return parseFloat(itemCommission).toFixed(1) +'\n'
        }else{
          return parseFloat(itemCommission).toFixed(1)
        }
        // return itemCommission
      }else{
        if (isCouponPackage)
        {
          return (
            <GridItem xs={12} key={itemID}>
              <p>{parseFloat(itemCommission).toFixed(1)}</p>
            </GridItem>
          );
        }
        else
        {
          return (
            <GridItem xs={12} key={itemID}>
              <p>{parseFloat(itemCommission).toFixed(1)}</p>
            </GridItem>
          );
        }
        
      }
    });

    // calculate all redeem item's sales price
    content_redeem = data_couponToUse.map((ctu, k_ctu) => {
      const itemID = ctu.id;
      var exchange_commission = roundValue(
        parseFloat(ctu.exchange_salesPrice) *
          parseFloat(ctu.use)
      );

        if (ctu.isExchange)
        {
          exchange_commission = 0;
        }

      // console.log(`exchange_commission  ${ctu.exchange_salesPrice} prop.qty  ${ctu.exchange_commission} ctu.use ${ctu.use} `)
      if(stringOnly){
        if (k_ctu !== data_couponToUse.length -1){
          return parseFloat(exchange_commission).toFixed(1) +'\n'
        }else{
          return parseFloat(exchange_commission).toFixed(1)
        }
        // return exchange_commission
      }else{
      return (
        <GridItem xs={12} key={itemID}>
          <p>{parseFloat(exchange_commission).toFixed(1)}</p>
        </GridItem>
      );
      }
    });
    if(stringOnly){
      return content_sales+'\n'+content_redeem
    }else{
    return (
      <div>
        {content_sales}
        {/* {content_redeem === null || content_sales === null ? null : <br />} */}
        {content_redeem}
      </div>
    );
    }
  }

  function get_actualStoreSalesContent(data_itemsInPackage, data_couponToUse,stringOnly) {
    var content_sales = null;
    var content_redeem = null;

    // calculate all "item" type product sales
    content_sales = data_itemsInPackage.map((iip, k_iip) => {
      const itemID = iip.id;
      const isCouponPackage = iip.type == "package_coupon";
      // console.log("get_actualSalesContent  "+ JSON.stringify(iip))
      var itemCommission =
        parseFloat(iip.price - iip.commission) * parseFloat(iip.qty);
      // console.log(`itemCommission iip.price  ${iip.price} iip.commission ${iip.commission}  prop.qty  ${iip.qty} `)
      if (iip.type === "item") {
        itemCommission = roundValue(itemCommission);
      } else {
        itemCommission = 0;
      }
      if(stringOnly){
        if (k_iip !== data_itemsInPackage.length -1){
          return parseFloat(itemCommission).toFixed(1) +'\n'
        }else{
          return parseFloat(itemCommission).toFixed(1)
        }
        // return itemCommission
      }else{
        if (isCouponPackage)
        {
          return (
            <GridItem xs={12} key={itemID}>
              <p>{parseFloat(itemCommission).toFixed(1)}</p>
            </GridItem>
          );
        }
        else
        {
          return (
            <GridItem xs={12} key={itemID}>
              <p>{parseFloat(itemCommission).toFixed(1)}</p>
            </GridItem>
          );
        }
        
      }
    });

    // calculate all redeem item's sales price
    content_redeem = data_couponToUse.map((ctu, k_ctu) => {
      const itemID = ctu.id;
      var exchange_commission = roundValue(
        parseFloat(ctu.exchange_salesPrice - ctu.exchange_commission) *
          parseFloat(ctu.use)
      );

        if (ctu.isExchange)
        {
          exchange_commission = 0;
        }

      // console.log(`exchange_commission  ${ctu.exchange_salesPrice} prop.qty  ${ctu.exchange_commission} ctu.use ${ctu.use} `)
      if(stringOnly){
        if (k_ctu !== data_couponToUse.length -1){
          return parseFloat(exchange_commission).toFixed(1) +'\n'
        }else{
          return parseFloat(exchange_commission).toFixed(1)
        }
        // return exchange_commission
      }else{
      return (
        <GridItem xs={12} key={itemID}>
          <p>{parseFloat(exchange_commission).toFixed(1)}</p>
        </GridItem>
      );
      }
    });
    if(stringOnly){
      return content_sales+'\n'+content_redeem
    }else{
    return (
      <div>
        {content_sales}
        {/* {content_redeem === null || content_sales === null ? null : <br />} */}
        {content_redeem}
      </div>
    );
    }
  }

  function get_commissionContent(data_itemsInPackage, data_couponToUse,stringOnly) {
    var content_sales = null;
    var content_redeem = null;

    // console.log('get_commissionContent ')

    content_sales = data_itemsInPackage.map((prop,k_iip) => {
      const itemID = prop.id;
      const isCouponPackage = prop.type == "package_coupon";
      var itemCommission = parseFloat(prop.commission) * parseFloat(prop.qty);
      if (prop.type === "item") {
        itemCommission = roundValue(itemCommission);
      } else {
        itemCommission = 0;
      }
      if(stringOnly){
        if (k_iip !== data_itemsInPackage.length -1){
          return parseFloat(itemCommission).toFixed(1) +'\n'
        }else{
          return parseFloat(itemCommission).toFixed(1)
        }
      }else{
        if (isCouponPackage)
        {
          return (
            <GridItem xs={12} key={itemID}>
              <p>{parseFloat(itemCommission).toFixed(1)}</p>
            </GridItem>
          );
        }
        else
        {
          return (
            <GridItem xs={12} key={itemID}>
              <p>{parseFloat(itemCommission).toFixed(1)}</p>
            </GridItem>
          );
        }
      }
      
    });

    content_redeem = data_couponToUse.map((prop,k_ctu) => {
      const itemID = prop.id;
      var exchange_commission =
        parseFloat(prop.exchange_commission) * parseFloat(prop.use);
      if (
        !prop.isExchange ||
        prop.isExchange === null ||
        prop.isExchange === false
      ) {
        exchange_commission = roundValue(exchange_commission);
      } else {
        exchange_commission = 0;
      }
      if(stringOnly){
        if (k_ctu !== data_couponToUse.length -1){
          return parseFloat(exchange_commission).toFixed(1) +'\n'
        }else{
          return parseFloat(exchange_commission).toFixed(1)
        }
      }else
      {return (
        <GridItem xs={12} key={itemID}>
          <p>{parseFloat(exchange_commission).toFixed(1)}</p>
        </GridItem>
      );}
    });

    if(stringOnly){
      return content_sales+'\n'+content_redeem
    }else{
      return (
        <div>
          {content_sales}
          {/* {content_redeem === null || content_sales === null ? null : <br />} */}
          {content_redeem}
        </div>
      );
    }
    
  }

  function get_commissionAmount(data_itemsInPackage, data_couponToUse) {
    var total = parseFloat(0.0);

    data_itemsInPackage.map((iip, k_iip) => {
      if (iip.type === "item") {
        total +=
          (iip.commission ? parseFloat(iip.commission) : 0) *
          parseFloat((iip.qty === null) ? 0 : iip.qty);
      }
    });

    data_couponToUse.map((ctu, k_ctu) => {
      if (
        !ctu.isExchange ||
        ctu.isExchange === null ||
        ctu.isExchange === false
      ) {
        total += parseFloat((ctu.exchange_commission === null) ? 0 : ctu.exchange_commission) * parseFloat((ctu.use === null) ? 0 : ctu.use);
      }
    });

    return total;
  }

  function processDataWithActions() {
    var _totalCommission = 0;
    var _totalStoreSales = 0;
    var _totalReceive = 0;
    var _totalNetReceive = 0;
    var _totalActualSales = 0;
    var _totalSalesCouponUsed = 0;
    var _totalSoldProduct = 0;

    // var _check_sale_item = 0;
    // var _check_sale_coupon = 0;

    // console.log("Num of sales: " + data_dataList.length);
    const newData = data_dataList
      // .filter((v) => v.isDeleted != true)
      .map((prop, key) => {
        var onlyDateStr = moment(prop.date).format('DD MMM YYYY');
        var onlyTimeStr = moment(prop.date).format('h:mm:ss a');
        // var dateStr = `${onlyDateStr}\n${onlyTimeStr}`;

        // console.log("prop.date: "+prop.date+" Str:"+dateStr)
        const code = prop.customerCode;
        const itemsInPackage = prop.itemsInPackage;
        const usedCouponList = prop.couponUsageList;
        const couponUsageList = prop.couponUsageList;
        const totalSales = get_actualSales(itemsInPackage, usedCouponList);
        // _check_sale_item += get_actualSales_count(itemsInPackage, []);
        // _check_sale_coupon += get_actualSales_count([], usedCouponList);

        // console.log("check val: " + _check_sale_item);
        // console.log("count_income_item: " + _check_sale_item);
        const totalSalesContent = get_actualSalesContent(
          itemsInPackage,
          usedCouponList
        );
        const totalStoreSalesContent = get_actualStoreSalesContent(
          itemsInPackage,
          usedCouponList
        );
        const totalCommittion = get_commissionAmount(
          itemsInPackage,
          usedCouponList
        );
        // const str_totalCommittion = totalCommittion.toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2,
        // });
        _totalCommission += totalCommittion;
        _totalStoreSales += totalSales - totalCommittion;
        _totalReceive += prop.totalReceive;
        _totalActualSales += totalSales;
        _totalNetReceive += prop.totalReceive_net;

        // const str_totalStoreSales = (
        //   totalSales - totalCommittion
        // ).toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2,
        // });

        const totalReceive = parseFloat(prop.totalReceive).toFixed(1);
        const totalReceive_net = parseFloat(prop.totalReceive_net).toFixed(1);
        

        const storeStr =
          prop.storeName === "" ? Loc.store_default : prop.storeName; //(dataList, "Store", storeID, Loc.store_default);
        const customerName = prop.customerName; //getNameWithID(dataList, "Customer", customerID, "-");
        const staffName = prop.staffName;
        const paymentStr =
          prop.paymentName === "" ? Loc.payment_default : prop.paymentName; //getNameWithID(dataList, "Payment", paymentID, Loc.payment_default);

        var itemsListContentToShow = get_packageContent(
          itemsInPackage,
          usedCouponList
        );

        var totalSalesCouponUsed = get_totalUsedCoupon(
          itemsInPackage,
          usedCouponList
        );
        _totalSalesCouponUsed += totalSalesCouponUsed
        var totalUsedCouponContent = get_totalUsedCouponContent(
          itemsInPackage,
          usedCouponList
        );

        var totalSoldProduct = get_totalSoldProduct(
          itemsInPackage,
          usedCouponList
        );
        _totalSoldProduct += totalSoldProduct;
        var totalSoldProductContent = get_totalSoldProductContent(
          itemsInPackage,
          usedCouponList
        )

        var itemsListCommissionContentToShow = get_commissionContent(
          itemsInPackage,
          usedCouponList
        );

        const itemsListContent =
          itemsListContentToShow != null ? (
            <GridContainer>{itemsListContentToShow}</GridContainer>
          ) : (
            <GridContainer>
              {get_useCouponContent(couponUsageList)}
            </GridContainer>
          );

        const deleteButton = (
          <>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(prop);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover /> : <Close />}
            </Button>{" "}
          </>
        );

        return [
          code,
          storeStr,
          customerName,
          staffName,
          (<div>
            {onlyDateStr}
            <br />
            {onlyTimeStr}

          </div>),
          paymentStr,
          itemsListContent,
          totalUsedCouponContent,
          totalSoldProductContent,
          totalSalesContent,
          itemsListCommissionContentToShow,
          totalStoreSalesContent,
          totalReceive,
          totalReceive_net,
          // we've added some custom button actions

          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                locationState.adminInfo = props.location.state.adminInfo;
                var state = {
                  data: prop,
                  config: locationState,
                };
                setItemToEdit(state);
                setRedirect("/admin/sales-new");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            {showDeletedData ? null : deleteButton}
          </div>,
        ];
      });

      // console.log("_check_sale_item: " + _check_sale_item);
      // console.log("_check_sale_coupon: " + _check_sale_coupon);
      
    const totalAmountRow = showExtendedTable
      ? [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          <b>
            {Loc.sales_coupon_used_qty +
              ": " +
              parseFloat(_totalSalesCouponUsed).toFixed(1)}
          </b>,
          <b>
          {Loc.sales_product_qty +
            ": " +
            parseFloat(_totalSoldProduct).toFixed(1)}
        </b>,
          <b>
            {Loc.sales_list_total_sales +
              ": " +
              parseFloat(_totalActualSales).toFixed(1)}
          </b>,
          <b>
            {Loc.sales_list_commission +
              ": " +
              parseFloat(_totalCommission).toFixed(1)}
          </b>,
          <b>
            {Loc.sales_list_storeSales +
              ": " +
              parseFloat(_totalStoreSales).toFixed(1)}
          </b>,
          
          <b>
            {Loc.sales_list_total_receive +
              ": " +
              parseFloat(_totalReceive).toFixed(1)}
          </b>,
          <b>
            {Loc.sales_list_total_net_receive +
              ": " +
              parseFloat(_totalNetReceive).toFixed(1)}
          </b>,
          "",
          "",
        ]
      : [];
      if(showExtendedTable){
        newData.unshift(totalAmountRow);
        newData.push(totalAmountRow);
      }
    
    // setHeaderAmount(totalAmountRow);
    setData(newData);
  }

  function showConfirmDeleteAlert(listItem) {
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={
          showDeletedData ? Loc.confirm_recover_title : Loc.confirm_delete_title
        }
        closeOnClickOutside={false}
        onConfirm={() => {
          hideAlert();
          set_isLoading(true);
          return do_deleteSalesRecord(listItem.id).then((isDeleted) => {
            if (isDeleted) {
              do_loadDataList();
              set_isLoading(false);
              showAlert(
                true,
                Loc.success,
                showDeletedData
                  ? Loc.data_recover_success
                  : Loc.data_delete_success
              );
            } else {
              set_isLoading(false);
              showAlert(
                false,
                Loc.failed,
                showDeletedData
                  ? Loc.data_recover_failed
                  : Loc.data_delete_failed
              );
            }
          });
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={
          showDeletedData
            ? Loc.confirm_recover_confirm
            : Loc.confirm_delete_confirm
        }
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function do_deleteSalesRecord(salesRecordID) {
    return new Promise((onDone) => {
      const body = {
        idList: [salesRecordID],
      };

      fetchAPI.do_fetch("post", "admin/del-sales", body).then(
        (res) => {
          console.log("success: ", res.data);
          onDone(true);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(false);
        }
      );
    });
  }

  function roundValue(value) {
    return Math.floor(value * 100) / 100;
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: null,
            }}
          />
        );
      }
    } else {
      return null;
    }
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const [data, setData] = React.useState([]);
  const [data_headerAmount, setHeaderAmount] = React.useState([]);

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const _renderSearchBar = () => {
    const searchFieldList = [
      {
        itemName: Loc.code_old,
        id: "customerCode",
        key: ["customerCode"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.store_name,
        id: "storeName",
        key: ["storeID"],
        type: "text",
        preloadCollectionName:["Store"],
        preloadDefaultName:[Loc.select_store],
        isPreloadField: true,
      },
      {
        itemName: Loc.customer_name,
        id: "customerName",
        key: ["customerName"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.staff_name,
        id: "staffName",
        key: ["staffID"],
        type: "text",
        preloadCollectionName:["Staff"],
        preloadDefaultName:[Loc.select_staff],
        isPreloadField: true,
      },
      {
        itemName: "",
        id: "divider",
        key: [""],
        type: "",
        isPreloadField: false,
      },
      {
        itemName: Loc.search_by_date,
        id: "date",
        key: ["createDate"],
        type: "date",
        isPreloadField: false,
      },
      {
        itemName: Loc.multi_search_staff,
        id: "date_staff",
        key: ["createDate", "staffID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Staff"],
        preloadDefaultName:[Loc.select_staff],
        isPreloadField: true,
      },
      {
        itemName: Loc.multi_search_store,
        id: "date_store",
        key: ["createDate", "storeID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Store"],
        preloadDefaultName:[Loc.select_store],
        isPreloadField: true,
      },
      {
        itemName: Loc.multi_search_payment,
        id: "date_payment",
        key: ["createDate", "paymentID","storeID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Payment","Store"],
        preloadDefaultName:[Loc.select_payment,Loc.select_store],
        isPreloadField: true,
      },
      {
        itemName: Loc.multi_search_staff_product_service,
        id: "date_staff_product_service",
        key: ["createDate", "staffID","productID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Staff","Product","Product-Package"],
        preloadDefaultName:[Loc.select_staff,Loc.select_product],
        isPreloadField: true,
      },
      {
        itemName: Loc.multi_search_store_product_service,
        id: "date_store_product_service",
        key: ["createDate", "storeID","productID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Store","Product","Product-Package"],
        preloadDefaultName:[Loc.select_store,Loc.select_product],
        isPreloadField: true,
      },
      {
        itemName: Loc.multi_search_staff_store,
        id: "date_staff_store",
        key: ["createDate", "staffID","storeID"],
        keyToShow:"itemName",
        type: "multi",
        preloadCollectionName:["Staff","Store"],
        preloadDefaultName:[Loc.select_staff,Loc.select_store],
        isPreloadField: true,
      },
    ];

    const searchFieldNameList = [];
    const searchIDList = [];

    searchFieldList.map((item) => {
      if (item.id == "divider") {
        searchFieldNameList.push({ divider: true });
        searchIDList.push("");
      } else {
        searchFieldNameList.push(item.itemName);
        searchIDList.push(item.id);
      }
    });

    // console.log(`searchFieldNameList  ${JSON.stringify(data_dataListArray)}  || searchIDList  ${searchIDList} data_searchID : ${searchIDList[0]}`)
    if (locationState && locationState !== null && data_dataListArray && data_dataListArray !== null ) {
      return (
        <SearchBar
          searchFieldList={searchFieldList}
          apiPath={"admin/list-sales"}
          loadDataList={() => do_loadDataList()}
          searchResultDataList={(dataList) => {
            setData_dataList(dataList);
          }}
          searchResultDataCount={(count) => {
            setData_dataListCount(count);
          }}
          showDeletedData={showDeletedData}
          locationState={data_dataListArray}
          didSelectedShowDeletedData={(_showDeletedData) => {
            setShowDeletedData(_showDeletedData);
            set_isLoaded(false);
          }}
          showExtendedTable={(bool) => {
            setShowExtendedTable(bool);
          }}
          searchPeriod={(from, to) => {
            setData_startDate(from);
            setData_endDate(to);
          }}
        />
      );
    }
  };
  const tableHeader = [
    Loc.code_old,
    Loc.store_name,
    Loc.customer_name,
    Loc.staff_name,
    Loc.sales_date,
    Loc.payment_name,
    Loc.sales_item_list,
    Loc.sales_coupon_used_qty,
    Loc.sales_product_qty,
    Loc.actual_sale,
    Loc.sales_list_commission,
    Loc.sales_list_storeSales,
    Loc.sales_list_total_receive,
    Loc.sales_list_total_net_receive,
    Loc.actions,
  ];

  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  function _renderExtendedTable() {
    return (
      <>
      {/* <Table
        tableHead={["","","","","","","","","","","","",""]}
        tableData={[data_headerAmount]}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
      /> */}
    
      <Table
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
      />
      </>
    );
  }
  const pageName = Loc.sales_records;
  // console.log(`table data::::: ${JSON.stringify(data)}`);
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{pageName}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  <Button
                    color="success"
                    round
                    onClick={() => {
                      locationState.adminInfo = props.location.state.adminInfo;
                      setItemToEdit({
                        data: null,
                        config: locationState,
                      });
                      setRedirect("/admin/sales-new");
                    }}
                  >
                    <ICON_ADD />
                    {Loc.sales_add}
                  </Button>
                  <Button
                    color="success"
                    round
                    onClick={() => {
                      operation();
                    }}
                  >
                    {Loc.download_excel}
                  </Button>
                </GridItem>

                {_renderSearchBar()}
              </GridContainer>

              {/* <ReactTable
              columns={[
                {
                  Header: "#",
                  accessor: "no"
                },
                {
                  Header: Loc.store_name,
                  accessor: "store_name"
                },
                {
                  Header: Loc.customer_name,
                  accessor: "customer_name"
                },
                {
                  Header: Loc.sales_date,
                  accessor: "sales_date"
                },
                {
                  Header: Loc.sales_item_list,
                  accessor: "sales_item_list"
                },
                {
                  Header: Loc.payment_name,
                  accessor: "payment_name"
                },
                {
                  Header: Loc.sales_amount_total,
                  accessor: "sales_amount_total"
                },
                {
                  Header: Loc.actions,
                  accessor: "actions"
                }
              ]}
              data={data}
            /> */}

              {showExtendedTable
                ? _renderExtendedTable()
                : _renderPagingTable()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
