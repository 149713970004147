import React from "react";
import {useDropzone} from 'react-dropzone';import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { v4 as uuidv4 } from 'uuid';
import Button from "components/CustomButtons/Button.js"; // modified
import { idText } from "typescript";
import { database } from "firebase";
import ICON_Clear from "@material-ui/icons/Clear";
import { Icon } from "@material-ui/core";


// ===========================================================
// 
// Task: Move the Submit Button to Dashboard
// Referenece: https://stackoverflow.com/questions/37949981/call-child-method-from-parent
// 
// ===========================================================
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const{ forwardRef, useRef, useImperativeHandle } = React;
const StyledDropzone = forwardRef((props, ref) =>
{

  const {fileArray, DidAcceptedFiles, DidAcceptedImage} = props;
  const [temp_fileArray, setTemp_fileArray] = React.useState([]);

  // ======================================
  // testing

  React.useEffect(() => {  
    if(temp_fileArray.length > 0)
    {
      mergeFileArray(temp_fileArray);
    }

    return function cleanup() {};

  },[temp_fileArray]);

  function mergeFileArray(targetFileArray){

    const mergedFileArray = [...fileArray,...targetFileArray];
    setTemp_fileArray([]);
    
    DidAcceptedFiles(mergedFileArray);
    // console.log(`DidAcceptedFiles newArray: ${JSON.stringify(newArray)}`)
  }

  function delete_File(file){
    console.log("===========================");
    console.log(temp_fileArray,fileArray);
    console.log("wanna delete: ",file);

    let newArray = fileArray.filter(data => data!= file);
    setTemp_fileArray([]);
    DidAcceptedFiles(newArray);

    console.log("===========================");
    console.log(newArray);
    console.log(temp_fileArray,fileArray);
    console.log("this works");
  }
    
  const fileListToShow = 
  fileArray.map((file) => {
    // {<ICON_Clear/>};
    // React.createElement('li', {key: file.name}, `${file.name} - ${file.size} bytes`);
    return (
      <div key={file.name}>
        <li >
          <Button color="rose" size="sm" round onClick={() => delete_File(file)}>
            <ICON_Clear/>
          </Button>
          &emsp;{file.name} - {file.size} bytes
        </li>
        
      </div>
    )
  });

  const onDrop = React.useCallback(acceptedFiles => {
    // DidAcceptedFiles(acceptedFiles);
    setTemp_fileArray(acceptedFiles);
  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop});

  const style = React.useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  function do_recordallFile(data){
    // create body to call API
    const body = {
      "collectionName": "data_files",
      "content": {
        "item_list":[
          // {
          //   "id": null,
          //   "file_path": pathInStorage,
          //   "download_URL": imageUrl,
          // }
        ]
      }
    };

    for (var i = 0; i < data.length; i++){
      body.content.item_list.push(data[i]);
    }
    console.log("body: ", body);


    // make an array of url
    let image_Array = [];
    body.content.item_list.map((data)=>image_Array.push(data.dowload_URL));
    DidAcceptedImage(image_Array);

    //call API to make records
    fetchAPI.do_fetch("post", "admin/create-custom-multi", body).then(
      (res) => {
        console.log("success: ", res);

        // setFetchingStatus("");
        // showAlert(true, Loc.success, Loc.data_save_success);
        // setData_itemName("");

      },
      (error) => {
        console.log("failed: ", error);

        // setFetchingStatus("");
        // showAlert(false, Loc.failed, Loc.data_save_failed);
        
      }
    );
  }


  function do_uploadallFile(e, uploaded_Array){
    if (e.length == 0){
      return;
    }
    fileArray.map(
      file => console.log(file.name)
    );
    
    // e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
    let newArray = e;
    let newFile = e[0];
    var new_uuid = uuidv4();
    var fileName_org_array = newFile.name.split(".");
    var fileExtention = fileName_org_array[fileName_org_array.length - 1];
    var fileName = new_uuid + "." + fileExtention;
    var fileType = newFile.type;
    var targetFolder = props.targetFolder;

    return new Promise((onDone) => {
      fetchAPI.do_uploadToCloud(newFile, targetFolder, fileName, fileType)
    .then((downloadUrl) => 
    {
      console.log("upload success, downloadUrl: ", downloadUrl);
      let obj = {
        "id": null,
        "file_Path": targetFolder,
        "dowload_URL": downloadUrl,
      };
      uploaded_Array.push(obj);
      newArray.splice(0, 1);
      
      // props.imageDidUpload(downloadUrl);
      
      
      if (newArray.length > 0){
        console.log("newArray ",newArray);
        do_uploadallFile(newArray, uploaded_Array).then((finalList)=>{
          onDone(finalList)
        })
      }else{
        console.log("uploaded array:", uploaded_Array);
        do_recordallFile(uploaded_Array);
        onDone(null);
      }
    }, error => 
    {
        console.log("upload failed: ", error);
        // props.imageDidUpload(null);
        onDone(null);
    })

    // Empty the local temp_fileArray
    DidAcceptedFiles(temp_fileArray);
    
    // console.log("This button does work");
    // console.log("========After clear=========")
    // if (temp_fileArray.length == 0){
    //   console.log('nothing here!')
    // }
    // else{
    //   temp_fileArray.map(
    //     file => console.log(file)
    //     );
    //   }
      
    //   if (fileArray.length == 0){
    //   console.log('nothing here!')
    // }
    // else{
    //   fileArray.map(
    //   file => console.log(file)
    // );
    // }
    }
    )
  };

  useImperativeHandle(ref, () => ({

    submit_image(e, uploaded_Array) {
      do_uploadallFile(e, uploaded_Array);
    }

  }));

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>{Loc.drag_drop_hints}</p>
      </div>
      <aside>
        <h4>{Loc.files_list}</h4>
        <div>{(fileArray)?<ul>{fileListToShow}</ul>:<p></p>}</div>
      </aside>
      {/* <Button color="success" round onClick={() => do_uploadallFile(fileArray,[])}>
        submit
      </Button> */}
    </div>
  );
});

export default StyledDropzone;