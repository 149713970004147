import Loc from "localization";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_SALES_LIST from '@material-ui/icons/Receipt';

const bossPermissionData = [
    {
      name: Loc.check_commission_value,
      isEnabled:false
    },
    // receivable_repayment
    // 
];

export default bossPermissionData;