/*eslint-disable*/
import React from "react";
import {  Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dropdown from "views/CommonUseComponents/Dropdown.js";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Info from "components/Typography/Info.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import NavPills from "components/NavPills/NavPills.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Danger from "components/Typography/Danger.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import moment from "moment";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_SELECT from "@material-ui/icons/CheckCircleOutline";
import ICON_SELECTED from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import Lock from '@material-ui/icons/NoEncryption';


export default function Customer_coupon_management(props) {
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();
  const [pathToRedirect, setRedirect] = React.useState("");
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setConfirmAlert(null);
  };

  const [data_customerID, setData_customerID] = React.useState("");
  const [
    data_selectedCustomerData,
    setData_selectedCustomerData,
  ] = React.useState(null);
  const [data_itemsInPackage, setData_itemsInPackage] = React.useState([]);
  const [
    data_itemsInPackageTableList,
    setData_itemsInPackageTableList,
  ] = React.useState([]);
  const [data_itemsToDelete, setData_itemsToDelete] = React.useState([]);
  const [showSaveStatus, setShowSaveStatus] = React.useState(false);

  const collectionsToLoad = ["Customer", "Product-Coupon"];
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [isLoadedEditData, setIsLoadedEditData] = React.useState(false);
  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_searchID, setData_searchID] = React.useState("");

  const [isLoading, set_isLoading] = React.useState(false);
  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(25);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [showZeroCoupon,setShowZeroCoupon] = React.useState(false);
  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();

  React.useEffect(() => {
    var preloadListStr = localStorage.getItem("preloadDataList");
    var preloadList = JSON.parse(preloadListStr);
    // console.log("Coupon preloadList: "+preloadListStr)

    if (!locationState || locationState == null) {
      setLocationState(props.location.state);
      setHistoryState(props.history.location.state);
      // console.log(" set locationState  ");
      if (data_dataListArray === null) {
        setData_dataListArray(preloadList);

      }
    } else {
      if (data_dataListArray === null) {
        // const stateConfig = locationState.config;
        // console.log("stateConfig  " + JSON.stringify(stateConfig))

        // if (stateConfig["Store"].length > 0) {
        //   setData_storeID(stateConfig["Store"][0].id);
        //   setData_storeName(stateConfig["Store"][0].itemName);
        // }

        // if (stateConfig["Staff"].length > 0) {
        //   setData_staffID(stateConfig["Staff"][0].id);
        //   setData_staffName(stateConfig["Staff"][0].itemName);
        // }
        // console.log("Coupon preloadList: " + preloadListStr);
        setData_dataListArray(preloadList);
      } else {
        if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
          set_isLoaded(true);
          // set_isLoading(true);
          // getCustomerList();
        }
      }
    }

    return function cleanup() {};
  }, [
    data_dataListArray,
    locationState,
    data_dataList,
    data_pageCount,
    data_page,
    data_itemsInPackage,
  ]);

  function getCustomerList() {
    set_isLoading(true);
    var body = {
      page: data_page,
      pageCount: data_pageCount,
    };

    fetchAPI.do_fetch("post", "admin/list-customer", body).then(
      (res) => {
        set_isLoading(false);
        // console.log('getCustomerList  ' + JSON.stringify(res.data.data_list))
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function _renderSearchBar() {
    const searchFieldList = [
      {
        itemName: Loc.customer_phone,
        id: "phone",
        key: ["phone"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.code_old,
        id: "code",
        key: ["code"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.customer_name,
        id: "itemName",
        key: ["itemName"],
        type: "text",
        isPreloadField: false,
      },
    ];

    const searchFieldNameList = [];
    const searchIDList = [];

    searchFieldList.map((item) => {
      searchFieldNameList.push(item.itemName);
      searchIDList.push(item.id);
    });

    return (
      <SearchBar
        currentSearchID={data_searchID == "" ? searchIDList[0] : data_searchID}
        searchFieldList={searchFieldList}
        searchFieldNameList={searchFieldNameList}
        searchIDList={searchIDList}
        apiPath={"admin/list-customer"}
        loadDataList={() => {
          getCustomerList();
        }}
        searchResultDataList={(dataList) => {
          const m = dataList.filter((v) => v.isDeleted !== true);
          console.log(`m ${JSON.stringify(m)}`);
          if (m.length === 1) {
            const prop = Array.from(m)[0];
            do_selectCustomer(prop,showZeroCoupon);
          }
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        didSelectedID={(id) => {
          setData_searchID(id);
        }}
      />
    );
  }

  const doneClicked = () => {
    setConfirmAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_update}
        onConfirm={() => {
          setFetchingStatus("fetching");

          const couponBalanceChangeList = get_couponBalanceChangeList();
          const couponToDeleteList = data_itemsToDelete;
          do_submit(couponToDeleteList, couponBalanceChangeList).then(
            (isDone) => {
              if (isDone) {
                showAlert(true, Loc.success, Loc.data_save_success);
                setFetchingStatus("");
                setShowSaveStatus(false);
              } else {
                showAlert(false, Loc.failed, Loc.data_save_failed);
                setFetchingStatus("");
              }
            }
          );

          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.default}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      ></SweetAlert>
    );
  };

  function do_addItemIntoPackage(targetItem,showZeroCoupon) {
    const targetID = targetItem.id;
    setShowSaveStatus(true);
    var newItemList = data_itemsInPackage;

    const newItem = {
      id: null,
      couponID: targetID,
      max_qty: 1.0,
      qty: 1.0,
      price: 0.0,
      needUpdate: true,
      couponName: targetItem.itemName,
    };
    newItemList.push(newItem);

    setData_itemsInPackage(newItemList);
    process_itemsInPackageTableList(newItemList,showZeroCoupon);

    // console.log("targetItem: ", JSON.stringify(targetItem));
  }

  function showConfirmRemoveFromPackageAlert(itemKey, dataSet) {
    setConfirmAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_delete_title}
        onConfirm={() => {
          setShowSaveStatus(true);
          var newItemsInPackage = dataSet;

          if (newItemsInPackage[itemKey].id != null) {
            var newListOfItemToDelete = data_itemsToDelete;

            const itemToDelete = {
              targetCollection: "Coupon-Balance",
              targetID: newItemsInPackage[itemKey].id,
            };

            newListOfItemToDelete.push(itemToDelete);
            setData_itemsToDelete(newListOfItemToDelete);
          }

          newItemsInPackage.splice(itemKey, 1);
          setData_itemsInPackage(newItemsInPackage);
          process_itemsInPackageTableList(newItemsInPackage,showZeroCoupon);
          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function process_itemsInPackageTableList(rawData_itemsInPackage,showZeroCoupon = false) {
    // var rawData = rawData_itemsInPackage
    // if (hiddenZeroCoupon){
    //   rawData.filter((v) => { return v.qty > 0})
    // }
    // console.log("list iip: " + JSON.stringify(rawData))
    var data = []
    const list = rawData_itemsInPackage.map((prop, key_itemInPackage) => {
      const itemID = prop.couponID;
      var itemName = prop.couponName;
      const qty = prop.qty;
      const max_qty = prop.max_qty;
      var isDeleted = false;
      var price = prop.price;
      var purchaseDate;
      if (prop.purchase_date){
        var purchase_date_timestamp = prop.purchase_date._seconds * 1000;
        console.log("purchaseDate  "+purchaseDate)
        purchaseDate = moment(purchase_date_timestamp);
      }
      
      const purchaseDateStr = prop.purchase_date ? purchaseDate.format("YYYY-MM-DD"):"/";

      const isAutoCreate = prop.isAutoCreate != undefined ? prop.isAutoCreate:false;
        console.log("adminName? "+JSON.stringify(props.location.state.config.adminInfo.itemName));
        const isSuperAdmin = props.location.state.config.adminInfo.itemName ? (props.location.state.config.adminInfo.itemName == "Super Admin"):false;

      //sort data -> current = 0
      if(showZeroCoupon){
      // console.log("coupon name "+ itemName + " qty: "+qty)
        data.push([
          // <span>{key_itemInPackage + 1}</span>,
          isDeleted ? <Danger>{itemName}</Danger> : itemName,
          purchaseDateStr,
          <CustomInput
            labelText={Loc.max_quantity_short}
            id="max_quantity_short"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: max_qty,
              onChange: (event) => {
                setShowSaveStatus(true);
                var newItemsInPackage = rawData_itemsInPackage;
                newItemsInPackage[key_itemInPackage].needUpdate = true;
                var newQty = parseFloat(event.target.value);
  
                if (newQty <= 0) {
                  newQty = 0;
                }
                if (newQty < newItemsInPackage[key_itemInPackage].qty) {
                  newItemsInPackage[key_itemInPackage].qty = newQty;
                }
  
                newItemsInPackage[key_itemInPackage].max_qty = newQty;
                setData_itemsInPackage(newItemsInPackage);
                process_itemsInPackageTableList(newItemsInPackage,showZeroCoupon);
              },
              type: "number",
            }}
          />,
          <CustomInput
            labelText={Loc.quantity_short}
            id="quantity_short"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: qty,
              onChange: (event) => {
                setShowSaveStatus(true);
                var newItemsInPackage = rawData_itemsInPackage;
                newItemsInPackage[key_itemInPackage].needUpdate = true;
                var newQty = parseFloat(event.target.value);
  
                if (newQty <= 0) {
                  newQty = 0;
                }
                if (newQty > newItemsInPackage[key_itemInPackage].max_qty) {
                  newItemsInPackage[key_itemInPackage].max_qty = newQty;
                }
                newItemsInPackage[key_itemInPackage].qty = newQty;
                setData_itemsInPackage(newItemsInPackage);
                process_itemsInPackageTableList(newItemsInPackage,showZeroCoupon);
              },
              type: "number",
            }}
          />,
          <CustomInput
            labelText={"$"} //Loc.sales_amount_total}
            id="sales_amount_total"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: price,
              onChange: (event) => {
                setShowSaveStatus(true);
                var newItemsInPackage = rawData_itemsInPackage;
                newItemsInPackage[key_itemInPackage].needUpdate = true;
                const newPrice = parseFloat(event.target.value);
  
                if (newPrice < 0) {
                  newPrice = 0;
                }
  
                newItemsInPackage[key_itemInPackage].price = newPrice;
                setData_itemsInPackage(newItemsInPackage);
                process_itemsInPackageTableList(newItemsInPackage,showZeroCoupon);
              },
              type: "number",
            }}
          />,
          (isAutoCreate && !isSuperAdmin) ? (<div className="actions-right">

        {/* use this button to remove the data row */}
        <Button
          justIcon
          round
          simple
          onClick={() => {
            
          }}
          color="danger"
          className="remove"
        >
          <Lock />
        </Button>{" "}
      </div>):(<Button
            color="danger"
            simple
            className={classes_tableBtn.actionButton}
            onClick={(e) => {
              showConfirmRemoveFromPackageAlert(
                key_itemInPackage,
                rawData_itemsInPackage
              );
            }}
          >
            <Close className={classes_tableBtn.icon} />
          </Button>)])
        // ];
      }else{
        if(qty > 0){
          data.push([
            // <span>{key_itemInPackage + 1}</span>,
            isDeleted ? <Danger>{itemName}</Danger> : itemName,
            purchaseDateStr,
            <CustomInput
              labelText={Loc.max_quantity_short}
              id="max_quantity_short"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: max_qty,
                onChange: (event) => {
                  setShowSaveStatus(true);
                  var newItemsInPackage = rawData_itemsInPackage;
                  newItemsInPackage[key_itemInPackage].needUpdate = true;
                  var newQty = parseFloat(event.target.value);
    
                  if (newQty <= 0) {
                    newQty = 0;
                  }
                  if (newQty < newItemsInPackage[key_itemInPackage].qty) {
                    newItemsInPackage[key_itemInPackage].qty = newQty;
                  }
    
                  newItemsInPackage[key_itemInPackage].max_qty = newQty;
                  setData_itemsInPackage(newItemsInPackage);
                  process_itemsInPackageTableList(newItemsInPackage,true);
                },
                type: "number",
              }}
            />,
            <CustomInput
              labelText={Loc.quantity_short}
              id="quantity_short"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: qty,
                onChange: (event) => {
                  setShowSaveStatus(true);
                  var newItemsInPackage = rawData_itemsInPackage;
                  newItemsInPackage[key_itemInPackage].needUpdate = true;
                  var newQty = parseFloat(event.target.value);
    
                  if (newQty <= 0) {
                    newQty = 0;
                  }
                  if (newQty > newItemsInPackage[key_itemInPackage].max_qty) {
                    newItemsInPackage[key_itemInPackage].max_qty = newQty;
                  }
                  newItemsInPackage[key_itemInPackage].qty = newQty;
                  setData_itemsInPackage(newItemsInPackage);
                  process_itemsInPackageTableList(newItemsInPackage,true);
                },
                type: "number",
              }}
            />,
            <CustomInput
              labelText={"$"} //Loc.sales_amount_total}
              id="sales_amount_total"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: price,
                onChange: (event) => {
                  setShowSaveStatus(true);
                  var newItemsInPackage = rawData_itemsInPackage;
                  newItemsInPackage[key_itemInPackage].needUpdate = true;
                  const newPrice = parseFloat(event.target.value);
    
                  if (newPrice < 0) {
                    newPrice = 0;
                  }
    
                  newItemsInPackage[key_itemInPackage].price = newPrice;
                  setData_itemsInPackage(newItemsInPackage);
                  process_itemsInPackageTableList(newItemsInPackage,true);
                },
                type: "number",
              }}
            />,
            (isAutoCreate && !isSuperAdmin) ? (<div className="actions-right">

            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                
              }}
              color="danger"
              className="remove"
            >
              <Lock />
            </Button>{" "}
          </div>) : (<Button
              color="danger"
              simple
              className={classes_tableBtn.actionButton}
              onClick={(e) => {
                showConfirmRemoveFromPackageAlert(
                  key_itemInPackage,
                  rawData_itemsInPackage
                );
              }}
            >
              <Close className={classes_tableBtn.icon} />
            </Button>)]
          );
        }
      }
      
    });
    setData_itemsInPackageTableList(data);
  }

  function process_couponTableList(targetData) {
    const newData = targetData
      .filter((v) => v.isDeleted != true)
      .map((prop, key) => {
        const product_id = prop.id;
        const itemName = prop.itemName;
        const price = prop.price;

        return {
          no: key + 1,
          id: key,
          product_id: product_id,
          itemName: (
            <span>
              {/* <img src={prop.imageUrl} alt="..." /> */}
              {itemName}
            </span>
          ),
          product_id: product_id,
          price: "$ " + parseFloat(price).toLocaleString(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                color="success"
                simple
                className={classes_tableBtn.actionButton}
                onClick={() => {
                  do_addItemIntoPackage(prop);
                }}
              >
                <ICON_ADD className={classes_tableBtn.icon} />
              </Button>
            </div>
          ),
        };
      });

    return newData;
    //   setData_couponTableList(newData);
  }

  function do_submit(couponToDeleteList, couponBalanceChangeList) {
    return new Promise((onDone) => {
      const body = {
        customerID: data_customerID,
        changeList: couponBalanceChangeList,
        couponBalanceRecordToDelete: couponToDeleteList,
      };
      // console.log("body: ", JSON.stringify(body));

      fetchAPI.do_fetch("post", "admin/create-coupon-balance", body).then(
        (res) => {
          console.log("do_handleCouponBalance success: ", res);
          onDone(res.data.idList);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function get_couponBalanceChangeList() {
    // filter list to update
    const listToUpdate = data_itemsInPackage.filter(
      (v) => v.needUpdate === true
    );
    var contentToUpdate = [];
    if (listToUpdate.length != 0) {
    // console.log("get_couponBalanceChangeList  item.itemName "+JSON.stringify(listToUpdate))

      listToUpdate.map((item, key) => {
        const changeItem = {
          id: item.id,
          content: {
            qty: item.qty,
            max_qty: item.max_qty,
            price: item.price,
            couponID: item.couponID,
            couponName: item.couponName,
            customerID: data_customerID,
          },
        };
        
        contentToUpdate.push(changeItem);
      });
    }
    return contentToUpdate;
  }

  function process_customerTableList(targetData) {
    if (targetData === null) {
      return [];
    }

    const newData = targetData
      .filter((v) => v.isDeleted != true)
      .map((prop, key) => {
        const customer_id = prop.id;
        const itemName = prop.itemName;
        const phone = prop.phone === "" ? "/" : prop.phone;
        const code = prop.code;
        const isSelf = customer_id === data_customerID;

        const data_tabContent = [
          //  key+1,
          // key,
          // targetData,
          code,
          itemName,
          phone,
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              color="success"
              simple
              className={classes_tableBtn.actionButton}
              onClick={() => {
                do_selectCustomer(prop,showZeroCoupon);
              }}
            >
              {isSelf ? (
                <ICON_SELECTED className={classes_tableBtn.icon} />
              ) : (
                <ICON_SELECT className={classes_tableBtn.icon} />
              )}
            </Button>
          </div>,
        ];

        if (isSelf) {
          return {
            color: "success",
            data: data_tabContent,
          };
        } else {
          return data_tabContent;
        }
      });
    // console.log("process_customerTableList  " +newData)
    return newData;
  }

  function do_selectCustomer(customerData,showZeroCoupon) {
    set_isLoading(true);
    setShowSaveStatus(false);
    setData_customerID(customerData.id);
    setData_selectedCustomerData(customerData);
    console.log("isShowZeroCoupon ? : "+showZeroCoupon);

    // clear
    setData_itemsInPackage([]);
    process_itemsInPackageTableList([],showZeroCoupon);

    do_getCustomerCouponBalance(customerData.id).then((listOfBalanceRecord) => {
      if (listOfBalanceRecord != null) {
        setData_itemsInPackage(
          listOfBalanceRecord.filter((v) => v.isDeleted != true)
        );
        process_itemsInPackageTableList(
          listOfBalanceRecord.filter((v) => v.isDeleted != true),showZeroCoupon
        );
      } else {
        setData_itemsInPackage([]);
        process_itemsInPackageTableList([],showZeroCoupon);
      }

      set_isLoading(false);
    });
  }

  function do_getCustomerCouponBalance(targetCustomerID) {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Coupon-Balance",
        data_relatedIDName: "customerID",
        data_relatedID: targetCustomerID,
      };

      fetchAPI.do_fetch("post", "admin/list-related-records", body).then(
        (res) => {
          console.log("do_getCustomerCouponBalance success: ", res);
          onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }
  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/customer-list");
          } else {
            hideAlert();
          }
        }}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{ pathname: pathToRedirect, state: historyState.config }}
        />
      );
    } else {
      return null;
    }
  };
  const div_coupon_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Info>{Loc.list_coupon_empty}</Info>
      </GridItem>
    );
    return theDiv;
  };
  const div_list_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Danger>{Loc.list_empty}</Danger>
      </GridItem>
    );
    return theDiv;
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.check_customer}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <h4 className={classes.cardTitle}>
                          {Loc.customer_info}
                        </h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4} align="left">
                          <small>{Loc.customer_id}</small>
                        </GridItem>
                        <GridItem xs={8} align="right">
                          {data_selectedCustomerData == null
                            ? "-"
                            : data_selectedCustomerData.id}
                        </GridItem>
                        <GridItem xs={4} align="left">
                          <small>{Loc.code_old}</small>
                        </GridItem>
                        <GridItem xs={8} align="right">
                          {data_selectedCustomerData == null
                            ? "-"
                            : data_selectedCustomerData.code}
                        </GridItem>
                        <GridItem xs={4} align="left">
                          <small>{Loc.customer_name}</small>
                        </GridItem>
                        <GridItem xs={8} align="right">
                          {data_selectedCustomerData == null
                            ? "-"
                            : data_selectedCustomerData.itemName}
                        </GridItem>

                        <GridItem xs={4} align="left">
                          <small>{Loc.customer_phone}</small>
                        </GridItem>
                        <GridItem xs={8} align="right">
                          {data_selectedCustomerData == null ||
                          data_selectedCustomerData.phone === ""
                            ? "-"
                            : data_selectedCustomerData.phone}
                        </GridItem>
                        <GridItem xs={4} align="left">
                          <small>{Loc.remark}</small>
                        </GridItem>
                        <GridItem xs={8} align="right">
                          {data_selectedCustomerData == null ||
                          data_selectedCustomerData.remark === ""
                            ? "-"
                            : data_selectedCustomerData.remark}
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>

              <GridItem xs={12}>
                {/* <h5>{Loc.coupon_on_hand}</h5> */}
                {/* <Card>
                  <CardHeader color="info" text>
                    <CardText color="info">
                      <h4 className={classes.cardTitle}>{Loc.coupon_on_hand}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    
                  </CardBody>
                </Card> */}
                <FormControlLabel
              control={
                <Switch
                  checked={showZeroCoupon}
                  onChange={(event) => {
                    setShowZeroCoupon(event.target.checked);
                    if(data_selectedCustomerData !== null){
                      do_selectCustomer(data_selectedCustomerData,event.target.checked)
                    }
                    console.log("setShowZeroCoupon : "+event.target.checked);
                  }}
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={Loc.show_coupon_zero}
            />
                {data_itemsInPackage.length == 0 ? (
                  div_coupon_empty()
                ) : (
                  <Table
                    tableHead={[
                      // "#",
                      Loc.coupon_name,
                      Loc.purchase_date,
                      Loc.coupon_max_qty,
                      Loc.coupon_balance_qty,
                      Loc.sales_amount_total,
                      Loc.remove,
                    ]}
                    tableData={data_itemsInPackageTableList}
                    customCellClasses={[
                      classes.center,
                      classes.center,
                      classes.right,
                    ]}
                    customClassesForCells={[0, 5, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right,
                    ]}
                    customHeadClassesForCells={[0, 5, 5]}
                  />
                )}
              </GridItem>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                {showSaveStatus ? (
                  <GridItem xs={6} align="center">
                    <Button
                      color="rose"
                      onClick={doneClicked}
                      disabled={fetchingStatus === "fetching" ? true : false}
                    >
                      <ICON_DONE />
                      {Loc.save}
                    </Button>
                  </GridItem>
                ) : null}
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.search}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* <hr/> */}
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: Loc.customer_list,
                    tabContent:
                      data_dataList == null ? (
                        _renderSearchBar()
                      ) : (
                        <>
                          {_renderSearchBar()}
                          <PagingTable
                            tableHead={[
                              "#",
                              Loc.customer_name,
                              Loc.customer_phone,
                              Loc.select,
                            ]}
                            tableData={process_customerTableList(data_dataList)}
                            customCellClasses={[
                              classes_tableBtn.center,
                              classes_tableBtn.center,
                              classes_tableBtn.right,
                            ]}
                            customClassesForCells={[0, 0, 5]}
                            customHeadCellClasses={[
                              classes_tableBtn.center,
                              classes_tableBtn.center,
                              classes_tableBtn.right,
                            ]}
                            customHeadClassesForCells={[0, 0, 5]}
                            gotoPageClicked={(targetPage) => {
                              set_page(targetPage);
                              set_isLoaded(false);
                            }}
                            changedPageCount={(newPageCount) => {
                              set_page(0);
                              set_pageCount(newPageCount);
                              set_isLoaded(false);
                            }}
                            totalDataCount={data_dataListCount}
                            page={data_page}
                            pageCount={data_pageCount}
                          />
                        </>
                      ),
                  },
                  {
                    tabButton: Loc.coupon,
                    tabContent:
                      data_dataListArray == null ||
                      data_dataListArray["Product-Coupon"] == null ||
                      process_couponTableList(
                        data_dataListArray["Product-Coupon"]
                      ).length == 0 ? (
                        div_list_empty()
                      ) : (
                        <ReactTable
                          columns={[
                            {
                              Header: Loc.coupon_name,
                              accessor: "itemName",
                            },
                            {
                              Header: Loc.coupon_price,
                              accessor: "price",
                            },
                            {
                              Header: Loc.add,
                              accessor: "actions",
                            },
                          ]}
                          data={
                            data_dataListArray["Product-Coupon"] == null
                              ? []
                              : process_couponTableList(
                                  data_dataListArray["Product-Coupon"]
                                )
                          }
                        />
                      ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
