import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar_staff from "components/Sidebar/Sidebar_staff.js";
import Sidebar_customer from "components/Sidebar/Sidebar_customer.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import {routes, SystemType} from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import Loc from "localization";
import firebase from 'connectionHandler/firebase';
import fetchAPI from "connectionHandler/FetchAPI.js";

var ps;

const useStyles = makeStyles(styles);

export default function Customer(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/itchidash.svg"));
  // auth
  const [isSignedIn, setSignedIn] = React.useState(false);
  const [isCheckedSignIn, set_isCheckedSignIn] = React.useState(false);
  const [data_staffInfo, setDate_staffInfo] = React.useState(null);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    // console.log("props.location.state.adminInfo: ", props.location.state.adminInfo);
    const subscription_auth = add_authListener();
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        wheelSpeed: 0.1,
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      subscription_auth();
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
      // console.log("cleanup called in Admin");
    };
  }, []);

  function get_staffInfo()
  {
    // get admin info
    const body = {
      // "id":firebase.auth().currentUser.uid
    }
    fetchAPI.do_fetch('get', 'staff/attendance-get-state', body)
    .then((res) => {
      setDate_staffInfo(res.data);
      set_isCheckedSignIn(true);
    }, error => {
      firebase.auth().signOut().then(function() {
        console.log("Sign-out successful.");
        setSignedIn(false);
        set_isCheckedSignIn(true);
        // Sign-out successful.
      }).catch(function(error) {
        console.log("Sign-out fail, ", error);
        // An error happened.
        set_isCheckedSignIn(true);
      });
    })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/customer") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  
  function add_authListener()
  {
    // console.log("add_authListener called in Admin");
    return firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        // console.log("Checking, signed in");
        setSignedIn(true);
        get_staffInfo();
      } else {
        // User is signed out.
        // console.log("Checking, NOT signed in");
        setSignedIn(false);
        set_isCheckedSignIn(true);
      }
    });
  }

  function check_isSignedIn()
  {
    if (isCheckedSignIn)
    {
      if (isSignedIn)
      {
        return null;
      }
      else
      {
        return (<Redirect to="/auth" />);
      }
    }
    else
    {
      return null;
    }
  }
  
  return (
    <div className={classes.wrapper}>
      {/* {check_isSignedIn()} */}
      <Sidebar_customer
        staffInfo={null}
        routes={routes}
        logoText={Loc.app_name}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        {/* <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> */}
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/customer" to="/customer/purchase" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/customer" to="/customer/purchase" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  );
}
