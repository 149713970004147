import React from "react";
import {  Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import moment from 'moment';
import NavPills from "components/NavPills/NavPills.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js"
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";

import Recover from '@material-ui/icons/Replay';

import { saveAs } from "file-saver";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);

export default function Receivable_list(props) {

  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  }
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [data_balanceTableList, setData_balanceTableList] = React.useState([]);

  const [data_dataListArray, setData_dataListArray] = React.useState(null);

  // const data_targetCollection = "Receivable";

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_switch = useStyles_switch();

  const classes_tableBtn = useStyles_tableBtn();
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);

  const [data_searchID, setData_searchID] = React.useState("");
  const [locationState,setLocationState] = React.useState(null)

  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());
  

  React.useEffect(() => {  
    if (!locationState || locationState == null){
      var preloadListStr = localStorage.getItem("preloadDataList")
      var preloadList = JSON.parse(preloadListStr);
      // console.log("preloadList  "+JSON.stringify(preloadList))
      setLocationState(preloadList);
      setData_dataListArray(preloadList);
    }else{
    setLocationState(props.location.state)

    if (data_dataList != null)
    {
      processDataWithActions();
    }

    if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0)
    {
      // set_isLoaded(true);
      // do_loadDataList();
    }

  }
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [data_dataListArray, data_pageCount, data_page, data_dataList,showDeletedData,locationState]);

  var xls = require("exceljs");

async function operation() {
  var workbook = new xls.Workbook();

  workbook.created = new Date();

  workbook.modified = new Date();

  const worksheet = workbook.addWorksheet("sheet");

  //calcualte part

  // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
  // //merge cell
  // worksheet.mergeCells("A1:"+headerEndColumn+"1");
  // worksheet.mergeCells("A2:"+headerEndColumn+"2");

  worksheet.columns = getExcelColumns()

  const tableHeaderList =  tableHeader.map(item=>{
    if(item !== Loc.actions){
      return item
    }
  })
  //Fixed Items
  const rows = [
    tableHeaderList,
  ];
  // //columns
  const titleRows = worksheet.getRow(1);

  // // // report_itemName
  titleRows.values = tableHeaderList;
  titleRows.alignment = { vertical: "middle", horizontal: "center" };

// console.log("getExcelData()   "+JSON.stringify(getExcelData()))
  // worksheet.addRows(rows);
  //add data part
  worksheet.addRows(getExcelData());

  //export excel
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";

  const blob = new Blob([buffer], { type: fileType });
 
  const fileName = `${Loc.receivable_list}_${moment(data_startDate).format(
    "YYYY-MM-DD-hh-mm-ss"
  )}~${moment(data_endDate).format(
    "YYYY-MM-DD-hh-mm-ss"
  )}`;
  saveAs(blob, fileName + fileExtension);
}

  function getExcelColumns(){
    var columns = []
    tableHeader.map((item)=>{
      if (item != Loc.actions)
      columns.push({ header: "", key: item, width: 20 })           
        })
    
    return columns
  }

  function getExcelData(){

    const newData = data_dataList.map((prop, key)=>{
      const itemName = (prop.itemName === "") ? "/" : prop.itemName;
      const remark = (prop.remark === "" || prop.remark == null) ? "/" : prop.remark;
      const amountVal = parseFloat(prop.amount);
      const store = prop.storeName;
      const debtorCode = prop.debtorCode
      const debtorPhone = (prop.debtorPhone === "" || prop.debtorPhone == null) ? "/":prop.debtorPhone
      const paymentStr = getPaymentName(prop.paymentID) == "" ? "/":getPaymentName(prop.paymentID);
      

      const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
      var typeStr = "/"
      if (prop.type === "+")
      {
        typeStr = Loc.receivable
      }
      else if (prop.type === "-")
      {
        typeStr = Loc.repayment
      }

      var debtorStr = prop.debtorName;
      
      
      return [
        debtorCode,
        debtorStr,
        debtorPhone,
        store,
        paymentStr,
        date,
        typeStr,
        itemName,
        amountVal.toLocaleString()
    ]
    })

    return newData

  } 


  function do_loadList(targetCollection)
  {
      return new Promise((onDone) => {
          const body = {
              "data_targetCollection":targetCollection
          }
          fetchAPI.do_fetch('post', 'admin/list-item', body)
          .then((res) => {
              // console.log("item success: ", res.data);
              // setData_itemList(res.data);
              // const newDataList = {"ProductList": res.data}
              onDone(res.data);
          }, error => {
              console.log("failed: ", error);
              onDone(null);
          })
      });
  }

  function do_loadDataList()
  {
    set_isLoading(true);
    const body = {
      "page":data_page,
      "pageCount":data_pageCount,
      showDeletedData:showDeletedData
    }
    fetchAPI.do_fetch('post', 'admin/list-receivable', body)
    .then((res) => {
      set_isLoading(false);
      console.log("success: ", res.data);
      setData_dataList(res.data.data_list);
      setData_dataListCount(res.data.count);
    }, error => {
      set_isLoading(false);
      console.log("failed: ", error);
      showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function do_deleteData(prop)
  {
    // const item = {
    //   id: prop.id,
    //   amount: prop.amount,
    //   type: prop.type,
    //   debtorID: prop.debtorID,
    //   debtorName: prop.debtorName,
    // }
    const body = {
      "itemList": [prop.id],
      "targetCollection":'Receivable',
      "targetID":prop.id
    }
    const path = showDeletedData ? 'admin/recover-deleted':'admin/del-receivable'
    console.log("showDeletedData:  "+showDeletedData);
    fetchAPI.do_fetch('post', path, body)
    // fetchAPI.do_fetch('post', 'admin/del-', body)
    .then((res) => {
        console.log("success: ", res.data);
        do_loadDataList();
        showAlert(true, Loc.success, showDeletedData ? Loc.data_recover_success:Loc.data_delete_success);
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, showDeletedData ? Loc.data_recover_failed:Loc.data_delete_failed);
    })
  }

  const getStoreStringToShow = (targetID) => {
    var strToShow = Loc.store_default;
    data_dataListArray["Store"].map((prop, key) => {
      if (prop.id === targetID)
      {
          strToShow = prop.itemName;
      }
    });

    return strToShow;
  }

  const getDebtorName = (targetID) => {
    var targetname = "";
    data_dataListArray["Customer"].map((customer, key) => {
      if (customer.id === targetID)
      {
          targetname = customer.itemName;
      }
    });
    return targetname;
}
function getPaymentName(paymentID, targetData) {
  var paymentName = "";
  data_dataListArray["Payment"].map((data) => {
    if (paymentID == data.id) {
        paymentName = data.itemName
    }
  });
  return paymentName
}

  function processDataWithActions()
  {
    // const balanceData = data_dataListArray["Customer"].filter(v => v.isDeleted != true).map((prop, key) => {
    //   const customerName = prop.itemName;
    //   const phone = prop.phone;
    //   var totalAmount = 0

    //   data_dataList.filter(v => v.isDeleted != true).map((rec, key_rec) => {
    //     if (rec.debtorID === prop.id)
    //     {
    //       if (rec.type === "+")
    //       {
    //         totalAmount += parseFloat(rec.amount);
    //       }
    //       else if (rec.type === "-")
    //       {
    //         totalAmount -= parseFloat(rec.amount);
    //       }
    //     }
    //   })

    //   return {
    //     no: key+1,
    //     id: key,
    //     itemName:customerName,
    //     customer_phone:phone,
    //    amount: 
    //    (
    //     <div className="actions-right">
    //     {totalAmount.toLocaleString()}
    //   </div>
    //    )
    //   }
    // })
    // setData_balanceTableList(balanceData);

    const newData = data_dataList
    // .filter(v => v.isDeleted != true)
    .map((prop, key) => {
      const item_id = prop.id;
      const itemName = (prop.itemName === "") ? "/" : prop.itemName;
      const remark = (prop.remark === "" || prop.remark == null) ? "/" : prop.remark;
      const amountVal = parseFloat(prop.amount);
      const store = getStoreStringToShow(prop.storeID)//prop.storeName;
      const debtorCode = prop.debtorCode
      // const date = moment(prop.date).format("DD MMM YYYY[\n]h:mm:ss a");
      var onlyDateStr = moment(prop.date).format('DD MMM YYYY');
      var onlyTimeStr = moment(prop.date).format('h:mm:ss a');
      const debtorPhone = (prop.debtorPhone === "" || prop.debtorPhone == null) ? "/":prop.debtorPhone
      const paymentStr = (getPaymentName(prop.paymentID) == "" || prop.type == "+" ) ? "/":getPaymentName(prop.paymentID);
      var typeStr = "/"
      if (prop.type === "+")
      {
        typeStr = <Danger>{Loc.receivable}</Danger>;
      }
      else if (prop.type === "-")
      {
        typeStr = <Success>{Loc.repayment}</Success>;
      }

      var debtorStr = prop.debtorName;
      
      return [
        debtorCode,
        (<span>{debtorStr}</span>),
        debtorPhone,
        store,
        paymentStr,
        (<div>
          {onlyDateStr}
          <br />
          {onlyTimeStr}

        </div>),
        typeStr,
        itemName,
        ((prop.type === "-") ? <Success>{amountVal.toLocaleString()}</Success> : <Danger>{amountVal.toLocaleString()}</Danger>),
        // store,
        // remark,
        (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                var state = {
                  data: prop,
                  config: locationState
                }
                setItemToEdit(state);
                setRedirect("/admin/receivable-new");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(prop);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover />:<Close />}
            </Button>{" "}
          </div>
        )
      ];
    })

    setData(newData);
  }

  function showConfirmDeleteAlert(prop){
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={showDeletedData ? Loc.confirm_recover_title:Loc.confirm_delete_title}
        closeOnClickOutside={false}
        onConfirm={() => {
          hideAlert();
          do_deleteData(prop);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={showDeletedData ? Loc.confirm_recover_confirm:Loc.confirm_delete_confirm}
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "")
    {
      if (itemToEdit != null)
      {
        return (<Redirect to={{
          pathname: pathToRedirect,
          state: itemToEdit
        }} />);
      }
      else
      {
        return (<Redirect to={{
          pathname: pathToRedirect,
          state: null
        }} />);
      }
    }
    else
    {
        return null;
    }
}
const showloading = () => {
  if (isLoading)
  {
    return (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.loading}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
        showConfirm={false}
        showCancel={false}
      >
        {Loc.please_wait}
      </SweetAlert>
    )
  }
  else
  {
    return null;
  }
}

const _renderSearchBar = () => {
  const searchFieldList = [
    { itemName: Loc.code_old, id: "debtorCode", key: ["debtorCode"], type: "text",isPreloadField:false },
    { itemName: Loc.customer_name, id: "debtorName", key: ["debtorName"], type: "text",isPreloadField:false },
    { itemName: "", id: "divider", key: [""], type: "",isPreloadField:false },
    { itemName: Loc.search_by_date, id: "date", key: ["createDate"], type: "date",isPreloadField:false },
    {
      itemName: Loc.multi_search_store,
      id: "date_store",
      key: ["createDate", "storeID"],
      keyToShow:"itemName",
      type: "multi",
      preloadCollectionName:["Store"],
      preloadDefaultName:[Loc.select_store],
      isPreloadField: true,
    },
    {
      itemName: Loc.multi_search_payment,
      id: "date_payment",
      key: ["createDate", "paymentID","storeID"],
      keyToShow:"itemName",
      type: "multi",
      preloadCollectionName:["Payment","Store"],
      preloadDefaultName:[Loc.select_payment,Loc.select_store],
      isPreloadField: true,
    },
  ];

  const searchFieldNameList = [];
  const searchIDList = [];

  searchFieldList.map((item) => {
    if (item.id == "divider") {
      searchFieldNameList.push({ divider: true });
      searchIDList.push("");
    } else {
      searchFieldNameList.push(item.itemName);
      searchIDList.push(item.id);
    }
  });
  if (locationState && locationState !== null) {
  return (
    <SearchBar
      searchFieldList={searchFieldList}
      apiPath={"admin/list-receivable"}
      loadDataList={() => do_loadDataList()}
      searchResultDataList={(dataList) => {
        setData_dataList(dataList);
      }}
      searchResultDataCount={(count) => {
        setData_dataListCount(count);
      }}
      showDeletedData={showDeletedData}
      locationState={locationState}
      didSelectedShowDeletedData= {(_showDeletedData)=>{
        setShowDeletedData(_showDeletedData)
        set_isLoaded(false);
      }}
      showExtendedTable={(bool)=>{
        setShowExtendedTable(bool)
      }}
      searchPeriod={(from,to)=>{
        setData_startDate(from)
        setData_endDate(to)
      }}
    />
  );}
};
const tableHeader = [
  Loc.code_old,
  Loc.customer_name,
  Loc.customer_phone,
  Loc.store_name,
  Loc.payment_name,
  Loc.receivable_date,  
  Loc.type,
  Loc.receivable_name,
  Loc.receivable_amount,
  Loc.actions,
]
function _renderPagingTable(){
  return (
      <PagingTable
      tableHead={tableHeader}
              tableData={data}
              // customCellClasses={[classes.center, classes.right, classes.right]}
            customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.right, classes_tableBtn.right]}
            customClassesForCells={[0,1,2,3,4,5,6,7,8]}
            customHeadCellClasses={[
              classes_tableBtn.left,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.center,
              classes_tableBtn.right,
              classes_tableBtn.right
            ]}
            customHeadClassesForCells={[0,1,2,3,4,5,6,7,8]}
          gotoPageClicked={(targetPage) => {
            set_page(targetPage);
            set_isLoaded(false);
          }}
          changedPageCount={(newPageCount) => {
            set_page(0);
            set_pageCount(newPageCount);
            set_isLoaded(false);
          }}
          totalDataCount={data_dataListCount}
          page={data_page}
          pageCount={data_pageCount}
        />
  )

}


function _renderExtendedTable(){
  return(
    <Table
    tableHead={tableHeader}
            tableData={data}
            // customCellClasses={[classes.center, classes.right, classes.right]}
          customCellClasses={[classes_tableBtn.left, classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center,classes_tableBtn.center, classes_tableBtn.right]}
          customClassesForCells={[0,1,2,3,4,5,6]}
          customHeadCellClasses={[
            classes_tableBtn.left,
            classes_tableBtn.center,
            classes_tableBtn.center,
            classes_tableBtn.center,
            classes_tableBtn.center,
            classes_tableBtn.center,
            classes_tableBtn.right
          ]}
          customHeadClassesForCells={[0,1,2,3,4,5,6]}
          />

  )

}

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
      <GridItem xs={12}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{Loc.receivable_list}</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
          <GridItem xs={12} md={12}>
          <Button color="success" round onClick={() => {
          var state = {
            data: null,
            config: locationState
          }
          setItemToEdit(state);
          setRedirect("/admin/receivable-new");
          }}>
            <ICON_ADD />
            {Loc.receivable_add}
        </Button>
        <Button color="success" round onClick={() => {
          operation()
          }}>
            {Loc.download_excel}
        </Button>
          </GridItem>
          {_renderSearchBar()}
          </GridContainer>
        
          {showExtendedTable ? _renderExtendedTable():_renderPagingTable()}
        </CardBody>
      </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
