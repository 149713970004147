import React from "react";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ProductCategoryDropdown(props) {

    const classes = useStyles();
    const [data_categoryData, setData_categoryData] = React.useState(null);
    const [data_categoryID, setData_categoryID] = React.useState("");
    const [data_categoryList, setData_categoryList] = React.useState([]);
    const [data_categoryIDList, setData_categoryIDList] = React.useState([]);
    const data_targetCollection = "Product-Category";

    React.useEffect(() => {  
        // pre load data for edit
        var p_loadCategory = do_loadCategoryList();
        if (props.categoryID != null && props.categoryID != "")
        {
            setData_categoryID(props.categoryID);
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            p_loadCategory = null;
        };
    }, [props.categoryID]);

    function do_loadCategoryList()
    {
        const body = {
            "data_targetCollection":data_targetCollection,
        }
        fetchAPI.do_fetch('post', 'admin/list-item', body)
        .then((res) => {
            console.log("success: ", res.data);
            processData(res.data);
            setData_categoryData(res.data);
        }, error => {
            console.log("failed: ", error);
        })
    }

    function processData(targetData)
    {
        var newCategoryList = [];
        var newCategoryIDList = [];
        targetData.filter(v => v.isDeleted != true).map((prop, key) => {
            const item_id = prop.id;
            const itemName = prop.itemName;

            const selection_category = (
                <span>{itemName}</span>
            );

            newCategoryList.push(selection_category);
            newCategoryIDList.push(item_id);
        });

        if (newCategoryList.length == 0)
        {
            newCategoryList.push(<span>{Loc.category_default}</span>);
            newCategoryIDList.push("");
        }

        setData_categoryList(newCategoryList);
        setData_categoryIDList(newCategoryIDList);
        props.didSelectedCategory(newCategoryIDList[0]);
    }

    const getCategoryStringToShow = () => {
        var strToShow = Loc.category_default;
        if (data_categoryData != null)
        {
            strToShow = data_categoryList[0];
            data_categoryData.map((prop, key) => {
                if (prop.id === data_categoryID)
                {
                    strToShow = prop.itemName;
                }
            });
        }

        return strToShow;
    }

    return(
        <CustomDropdown
            buttonProps={{
                round: true,
                color: "info",
            }}
            buttonText={<span>{getCategoryStringToShow()}</span>}
            dropdownList={data_categoryList}
            itemIDList={data_categoryIDList}
            onClick={(selectedID) => {
                setData_categoryID(selectedID);
                props.didSelectedCategory(selectedID);
            }}
        />
    );
}