import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import moment from "moment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Heading from "components/Heading/Heading.js";
import CardText from "components/Card/CardText.js";

import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_SEARCH from "@material-ui/icons/Search";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";
import { InputLabel } from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { saveAs } from "file-saver";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

export default function Report_operating(props) {
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  };
  const [pathToRedirect, setRedirect] = React.useState("");
  const [isLoading, set_isLoading] = React.useState(false);

  const [refreshData,set_refreshData] = React.useState(false)

  const [data_selectedPeriod, setData_selectedPeriod] = React.useState("");
  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [dateState_startDate, setdateState_startDate] = React.useState(true);
  const [data_endDate, setData_endDate] = React.useState(new Date());
  const [dateState_endDate, setdateState_endDate] = React.useState(true);

  const [data_report, setData_report] = React.useState(null);
  const [data_reportList, setData_reportList] = React.useState([]);
  const [pdfNameList, setPdfNameList] = React.useState([]);

  const [reportDetailData,setReportDetailData] = React.useState(null)
  const [locationState, setLocationState] = React.useState(null);
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [loadingPercentage,setLoadingPercentage] = React.useState(0.0);

  const [totalProcessCount,setTotalProcessCount] = React.useState(0);
  const [currentProcessCount,setCurrentProcessCount] = React.useState(0);

  const [storeInfoDict,setStoreInfoDict] = React.useState(null)
      

  React.useEffect(() => {
    
    if (locationState == null) {
      setLocationState(props.location.state);
    } else {
      if (data_dataListArray === null) {
  
        setData_dataListArray(locationState)
        
        initStoreInfo()
       
      }
      // console.log("data_reportList.length set "+ JSON.stringify(locationState))

    if(refreshData){
      set_refreshData(false)
      console.log("data_reportList.length set ")
      _renderReportDetail(data_reportList)
    }
  }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [data_reportList,refreshData,data_dataListArray,locationState,currentProcessCount]);

  function initStoreInfo(){
    var _storeInfoDict = {}
    locationState["Store"].map(item=>{
      // console.log("_renderReportDetail : "+item.itemName)
      
      var storeInfo = {
        storeName:"",
        storeID:"",
        count_income_item: 0.0,
        count_income_coupon:0.0,
        count_income_pacakge_product:0.0,
        count_income_redeem:0.0,
        count_subtotal_sales:0.0,
        count_exp_purchases:0.0,
        count_exp_commission:0.0,
        count_exp_discount_allowed_sales:0.0,
        count_exp_discount_allowed_prepaid:0.0,
        count_exp_other:0.0,
      }

      _storeInfoDict[item.id] = storeInfo

      // items.push(show_reportDetail(targetData[item.id]))
    })
    setStoreInfoDict(_storeInfoDict)

  }

  // function convertToPDF(data = null){
  //   console.log("convertToPDF called");
  //   const id = `pdfdiv_${data.storeID}`
  //   const input = document.getElementById(id);

  //   html2canvas(input)
  //   .then((canvas) => {

  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4')
  //     var width = pdf.internal.pageSize.getWidth();
  //     var height = pdf.internal.pageSize.getHeight();

  //     var margin = 10;

  //     var imgWidth = 200 - 2*margin;
  //     var pageHeight = 290;
  //     var imgHeight = canvas.height * imgWidth / canvas.width;
  //     var contentHeight = height;

  //     var position = 0;
  //     var heightLeft = imgHeight;
  //     var leftHeight = contentHeight;

  //     pdf.addImage(imgData, 'JPEG', 5, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, 'JPEG', 5, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }
  //     pdf.save(moment(new Date()).format("YYYY-MM-DD-hh-mm-ss") + ".pdf");

  //   });

  // }

  //Excel
  var xls = require("exceljs");

  async function operation(data_report,startDate,endDate) {
    var workbook = new xls.Workbook();

    workbook.created = new Date();

    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("sheet");

    //calcualte part

    // calculate income part
  //   var count_income_item = 0.0;
  //   var count_income_coupon = 0.0;
  //   var count_income_pacakge_product = 0.0;
  //   var count_income_redeem = 0.0;
  //   var count_subtotal_sales = 0.0;

  //   data_report.sales.map((item, key_salesItem) => {
  //     item.itemsInPackage.map((iip, k_kip) => {
  //       // console.log("typrrrrr "+ iip.type)
  //       if (iip.type === "item" || iip.type === "package_product") {
  //         count_income_item += parseFloat(iip.price * iip.qty);
  //       }
  //   });
 
  //   item.couponUsageList.map((ctu, k_ctu) => {
  //     if (!ctu.isExchange){
  //       var exchange_salesPrice = ctu.exchange_salesPrice
  //       if (!exchange_salesPrice || exchange_salesPrice === null){
  //         exchange_salesPrice = 0
  //       } 
  //       count_income_redeem +=
  //         parseFloat(exchange_salesPrice) * parseFloat(ctu.use);
  //     }
  //   });
  // })
  //   count_subtotal_sales =
  //     count_income_item +
  //     count_income_coupon +
  //     count_income_pacakge_product +
  //     count_income_redeem;

  //   // calcualte expenditure part
  //   var count_exp_purchases = 0.0;
  //     var count_exp_commission = 0.0;
  //     var count_exp_discount_allowed_sales = 0.0
  //     var count_exp_discount_allowed_prepaid = 0.0
  //     var count_exp_other = 0.0;

    var otherExpList = [];

    //   data_report.expenditureList.map((expItem, key_expItem) => {
    //   if (expItem.type === "inventory-in") {
    //     count_exp_purchases += parseFloat(expItem.amount);
    //   } else if (expItem.type === "commission") {
    //     count_exp_commission += parseFloat(expItem.amount);
    //   }else if (expItem.type === "discount_allowed_sales") {
    //     count_exp_discount_allowed_sales += parseFloat(expItem.amount);
    //   }else if (expItem.type === "discount_allowed_prepaid") {
    //     count_exp_discount_allowed_prepaid += parseFloat(expItem.amount);
    //   } else {
    //     console.log(`Other Case`);
    //     count_exp_other += parseFloat(expItem.amount);
    //   }
    // });

    otherExpList.push(
      {itemName:Loc.discount_allowed_sales,amount:data_report.count_exp_discount_allowed_sales},
      {itemName:Loc.discount_allowed_prepaid,amount:data_report.count_exp_discount_allowed_prepaid},
      {itemName:Loc.other_expenditure,amount:data_report.count_exp_other},
      );

    // calculate
    const gross_margin = data_report.count_subtotal_sales - data_report.count_exp_purchases;
    const operating_expenses = data_report.count_exp_commission;
    const operating_income = gross_margin - operating_expenses;
    const net_profit_or_lost = operating_income - (data_report.count_exp_other+data_report.count_exp_discount_allowed_sales+data_report.count_exp_discount_allowed_prepaid);
    const storeName =
    data_report.storeName.length > 0
    ? data_report.storeName
    : Loc.store_default;
    //merge cell
    worksheet.mergeCells("A1:D1");
    worksheet.mergeCells("A2:D2");
    worksheet.mergeCells("A3:D3");
    worksheet.mergeCells("A4:D4");

    worksheet.columns = [
      { header: "", key: "itemName", width: 50 },
      { header: "", key: "blank", width: 60 },
      { header: "", key: "amount", width: 20 },
      { header: "", key: "totalAmount", width: 20 },
    ];

    //columns
    const titleCol = worksheet.getColumn(2);
    //rows
    const storeNameRow = worksheet.getCell("B2");
    const dateRow = worksheet.getCell("B3");

    // // report_itemName
    titleCol.header = Loc.report_operating;
    titleCol.alignment = { vertical: "middle", horizontal: "center" };
    storeNameRow.value = storeName;
    storeNameRow.alignment = { vertical: "middle", horizontal: "center" };
    dateRow.value =
      moment(startDate).format("YYYY/MM/DD") +
      " ~ " +
      moment(endDate).format("YYYY/MM/DD"); //the dates
    dateRow.alignment = { vertical: "middle", horizontal: "center" };

    //Fixed Items
    const rows = [
      [Loc.report_itemName, "", Loc.report_amount, Loc.report_amount],
      ["", "", "", ""],
      [Loc.report_sales, "", "", ""], // row by array
      [Loc.report_sales_product, "", data_report.count_income_item.toLocaleString(), ""],
      // [
      //   Loc.report_sales_package,
      //   "",
      //   count_income_pacakge_product.toLocaleString(),
      //   "",
      // ],
      [
        Loc.report_sales_coupon_use,
        "",
        roundValue(data_report.count_income_redeem).toLocaleString(),
        roundValue(data_report.count_subtotal_sales).toLocaleString(),
      ],
      [Loc.report_cost_of_good_sold, "", "", ""],
      [
        Loc.report_exp_purchases,
        "",
        roundValue(data_report.count_exp_purchases).toLocaleString(),
        `-${roundValue(data_report.count_exp_purchases).toLocaleString()}`,
      ],
      [Loc.report_gross_margin, "", "", roundValue(gross_margin).toLocaleString()],
      ["", "", "", ""],
      [Loc.report_exp_operating, "", "", ""],
      [
        Loc.report_exp_commission,
        "",
        roundValue(data_report.count_exp_commission).toLocaleString(),
        `-${roundValue(operating_expenses).toLocaleString()}`,
      ],
      [Loc.report_operating_income, "", "", roundValue(operating_income).toLocaleString()],
      ["", "", "", ""],
      [Loc.report_exp_other, "", "", ""],
    ];

    worksheet.addRows(rows);
    //Other Expenditure Items
    const otherExpRows = otherExpList.map((item, key) => {
      return [
        item.itemName,
        "",
        parseFloat(item.amount).toLocaleString(),
        key === otherExpList.length - 1
          ? `-${roundValue(data_report.count_exp_other+data_report.count_exp_discount_allowed_sales+data_report.count_exp_discount_allowed_prepaid).toLocaleString()}`
          : "",
      ];
    });
    worksheet.addRows(otherExpRows);

    worksheet.addRows([
      ["", "", "", ""],
      [
        net_profit_or_lost < 0 ? Loc.report_net_loss : Loc.report_net_income,
        "",
        "",
        roundValue(net_profit_or_lost).toLocaleString(),
      ],
      
    ]);

    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });
    const storeID =
    data_report.storeID > 0 ? data_report.storeID : "default";
    const fileName = `${storeID}_${moment(new Date()).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}`;
    saveAs(blob, fileName + fileExtension);
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={pathToRedirect} />;
    } else {
      return null;
    }
  };

  const showloading = (_currentProcessCount) => {
    // console.log("_loadingPercentage ??????? "+_loadingPercentage)
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
          {Math.ceil(currentProcessCount/totalProcessCount *100)+" %"}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

 

  function get_dropdownTextToShow() {
    switch (data_selectedPeriod) {
      case "":
        return Loc.select_period;
      case "week":
        return Loc.this_week;
      case "month":
        return Loc.this_month;
      case "year":
        return Loc.this_year;
      case "other":
        return Loc.select_custom_period;
      default:
        return Loc.select_period;
    }
  }

  function showHide_selectPeriod() {
    if (data_selectedPeriod !== "other") {
      return null;
    } else {
      return (
        <GridItem xs={12} sm={9} md={9} lg={9}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4} lg={4}>
              <InputLabel className={classes.label}>{Loc.from_date}</InputLabel>
              <Datetime
                timeFormat={false}
                defaultValue={data_startDate}
                value={
                  data_startDate == null ? new Date() : moment(data_startDate)
                }
                inputProps={{
                  placeholder: Loc.from_date,
                }}
                onChange={(m) => {
                  if (moment.isMoment(m)) {
                    setdateState_startDate(true);
                    setData_startDate(m.startOf("day").toDate());
                  } else {
                    setdateState_startDate(false);
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4} md={4} lg={4}>
              <InputLabel className={classes.label}>{Loc.to_date}</InputLabel>
              <Datetime
                timeFormat={false}
                defaultValue={data_endDate}
                value={data_endDate == null ? new Date() : moment(data_endDate)}
                inputProps={{
                  placeholder: Loc.to_date,
                }}
                onChange={(m) => {
                  if (moment.isMoment(m)) {
                    setdateState_endDate(true);
                    setData_endDate(m.endOf("day").toDate());
                  } else {
                    setdateState_endDate(false);
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4} md={4} lg={4}>
              <Button color="info" onClick={searchClicked}>
                <ICON_SEARCH />
                {Loc.search}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    }
  }

  const searchClicked = () => {
    do_loadReportInPeriod(data_startDate, data_endDate);
  };

  const handle_fast_search = (selected) => {
    switch (selected) {
      case "week":
        const monday = moment().startOf("isoWeek").toDate();
        const sunday = moment().endOf("isoWeek").toDate();

        setData_startDate(monday);
        setData_endDate(sunday);
        do_loadReportInPeriod(monday, sunday);

        // console.log("monday: ", monday, "\nsunday; ", sunday);
        break;
      case "month":
        const start_month = moment().startOf("month").toDate();
        const end_month = moment().endOf("month").toDate();

        setData_startDate(start_month);
        setData_endDate(end_month);
        do_loadReportInPeriod(start_month, end_month);
        // console.log("start_month: ", start_month, "\nend_month; ", end_month);
        break;
      case "year":
        const start_year = moment().startOf("year").toDate();
        const end_year = moment().endOf("year").toDate();

        setData_startDate(start_year);
        setData_endDate(end_year);
        do_loadReportInPeriod(start_year, end_year);
        // console.log("start_year: ", start_year, "\nend_year; ", end_year);
        break;

      default:
        break;
    }
  };

  function do_loadReportInPeriod(startDate, endDate) {
    
    // console.log("startDate: ", startDate, "\nendDate; ", endDate); 

    // if(locationState !== null && data_dataListArray !== null){
    //   // set_isLoading(true);
    //   var _storeList = data_dataListArray["Store"]
      
      getCollectionCount("Sales",startDate, endDate).then(saleCount=>{
        // console.log("saleCount: " + saleCount);
        getCollectionCount("Expenditure",startDate, endDate).then(expenditureCount=>{
          // console.log("do_loadReportInPeriod  saleCount: "+saleCount+" expenditureCount: "+expenditureCount)
          
          var getSalesAPITimes = Math.ceil(saleCount/1000)
          var getExpenditureAPITimes = Math.ceil(expenditureCount/1000)
          var totalCount = getSalesAPITimes + getExpenditureAPITimes
          setTotalProcessCount(totalCount)
          // setCurrentProcessCount()
          console.log("do_loadReportInPeriod  totalCount: "+totalCount+" getSalesAPITimes: "+getSalesAPITimes+" getExpenditureAPITimes: "+getExpenditureAPITimes)
          var salesRowList = []
          var expenditureRowList = []
          for(var i = 0 ;i<getSalesAPITimes;i++){
            salesRowList.push(i*1000)
          }

          for(var i = 0 ;i<getExpenditureAPITimes;i++){
            expenditureRowList.push(i*1000)
          }

          if (getSalesAPITimes > 0){
            do_loadReport("Sales",startDate,endDate,salesRowList,[],0).then(process=>{
              _renderReportDetail(null,true)
                // set_isLoading(false)
                  // console.log("sales process"+  currentProcessCount);
                
                if(getExpenditureAPITimes > 0){
                  do_loadReport("Expenditure",startDate,endDate,expenditureRowList,[],0).then(process=>{
                  // console.log("Expenditure process"+  currentProcessCount);

                    // set_refreshData(true)
                    // setCurrentProcessCount(process)

                    _renderReportDetail(null,true,startDate,endDate)

                    set_isLoading(false)
                    // setCurrentProcessCount(0)
      
                  
                  })
                }else{
                    // setCurrentProcessCount(process)
                  
                  // setCurrentProcessCount(currentProcessCount+1)
                  _renderReportDetail(null,true,startDate,endDate)
                    set_isLoading(false)
                    
                    // setCurrentProcessCount(0)
                  
                }
              
            })
          }else{

            if(getExpenditureAPITimes > 0){
              do_loadReport("Expenditure",startDate,endDate,expenditureRowList,[],0).then(process=>{
                // set_refreshData(true)
                // setCurrentProcessCount(currentProcessCount+1)
                // setCurrentProcessCount(process)

                _renderReportDetail(null,true,startDate,endDate)
                set_isLoading(false)
                // setCurrentProcessCount(0)

                
              })
            }else{
              
              set_isLoading(false)
              // setCurrentProcessCount(0)
            }

          }

        })


        })
      

        setCurrentProcessCount(0)
        setTotalProcessCount(0)
      
      
  }

  function getCollectionCount(collectionName,startDate,endDate){
    return new Promise((onDone) => {
     
      var body = {
        targetCollection:collectionName,
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
      };
      
      fetchAPI.do_fetch("post", "admin/get_collection_count", body).then(
        (res) => {
          // console.log("getCollectionCount: ", JSON.stringify(res.data));
          // setData_reportList(res.data.storeList);
          // set_refreshData(true);
          onDone(res.data)
          set_isLoading(false);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, Loc.errorMsg_load_report_faild);
          set_isLoading(false);
        }
      );
      
    })

  }

  function do_loadReport(collectionName,startDate, endDate, rowList, onHoldList,_currentProcessCount){//startRow) {
    var newList = rowList;
    var newOnHoldList = onHoldList;
    var newProcessCount = _currentProcessCount;

    console.log("do_loadReport newProcessCount "+ newProcessCount + " currentProcessCount "+currentProcessCount)

    return new Promise((onDone) => {
        if(locationState !== null && data_dataListArray !== null){
          // console.log("do_loadReport  startDate: "+ startDate+ " endDate "+endDate)
          set_isLoading(true);
          var _storeList = data_dataListArray["Store"]
          var body = {
            targetCollection:collectionName,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            // storeList: _storeList,
            startRow: newList[0]
          // store: _storeList[0].itemName
        };
        // console.log("newList[0]: " + newList[0]);

        fetchAPI.do_fetch("post", "admin/get-data-by-startRow", body).then(
          (res) => {
            
            // newOnHoldList.push(res.data);
            newProcessCount = newProcessCount+1
            setCurrentProcessCount(newProcessCount)
            // showloading(newProcessCount)
            // console.log("Loading ----> _currentProcessCount: "+(newProcessCount))
            
            newList.splice(0, 1);
            
            var _dataList = res.data
            console.log("_dataList.length: " + _dataList.length);
            // var storeList = [];
            
            var newObj = {}

            if (collectionName == "Sales"){
              _storeList.map(item=>{
                var newSaleList = []
                _dataList.map((data) => {
                  if (data.storeID == item.id){
                    newSaleList.push(data)
                  }
                });
                const store = {
                  storeName: item.itemName,
                  storeID: item.id,
                  sales: newSaleList,
                  expenditureList: [],
                };
                newObj[item.id] = store;

                console.log("newSaleList.length: " + newSaleList.length);
              })
              
              
            }else{
              _storeList.map(item=>{
                var newExpList = []
                _dataList.map((data) => {
                  if (data.storeID == item.id){
                    newExpList.push(data)
                  }
                });

                const store = {
                  storeName: item.itemName,
                  storeID: item.id,
                  sales: [],
                  expenditureList: newExpList,
                };
                newObj[item.id] = store;
              })
             
            }

            //cal data
          _renderReportDetail(newObj)
           
            // set_refreshData(true)
          // console.log("Loading ----> _currentProcessCount: "+(newProcessCount))
          if (newList.length > 0) {
            // var currentListCount = newList.length
            do_loadReport(collectionName,startDate,endDate,newList, newOnHoldList,newProcessCount).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          } else {
            // setCurrentProcessCount(newProcessCount)
            onDone(newProcessCount);
            
          }
            // setData_reportList(res.data.storeList);
            // set_refreshData(true)
            // set_isLoading(false);
          },
          (error) => {
            console.log("failed: ", error);
            showAlert(false, Loc.failed, Loc.errorMsg_load_report_faild);
            set_isLoading(false);
          }
        );
      }

    })
    
  }
  
  function reportHeader(storeName, data,startDate,endDate) {
    // console.log(`report header  ${storeName}`);
    return (
      <CardHeader color="rose" text>
        <CardText color="rose">
          <h4 className={classes.cardTitle}>{storeName}</h4>
        </CardText>
        <Button color="success" onClick={() => operation(data,startDate,endDate)}>
          {Loc.download_excel}
        </Button>
      </CardHeader>
    );
  }

      

  function calReportInfo(_storeInfoDict,targetData){
      var newStoreInfo = _storeInfoDict[targetData.storeID]
      // console.log("targetData.storeID: " + targetData.storeID);
      var count_income_item = 0.0;
      var count_income_coupon = 0.0;
      var count_income_pacakge_product = 0.0;
      var count_income_redeem = 0.0;
      var count_subtotal_sales = 0.0;

      var count_exp_purchases = 0.0;
      var count_exp_commission = 0.0;
      var count_exp_discount_allowed_sales = 0.0
      var count_exp_discount_allowed_prepaid = 0.0
      var count_exp_other = 0.0;

      
      // if (targetData.storeID == "MQ5vj5sTlQSXOxIC9vMD")
      // {
      //   console.log("Num of sales: " + targetData.sales.length);
      // }

      
      targetData.sales.map((item, key_salesItem) => {
        var subcount_item = 0;
        var subcount_coupon = 0;
        // console.log("================================================");
        // console.log("sales id: " + item.id);
        // console.log("================================================");
        item.itemsInPackage.map((iip, k_kip) => {
          // console.log("typrrrrr "+ iip.type)
          if (iip.type === "item" || iip.type === "package_product") 
          {
            var val = parseFloat(iip.price) * parseFloat(iip.qty);
            subcount_item += val;

            // console.log("itemName: " + iip.itemName + " type: " + iip.type);
            // console.log("subcount_item: " + subcount_item);
            // if (targetData.storeID == "MQ5vj5sTlQSXOxIC9vMD")
            // {
            //   console.log("_val: " + val);
            // }
          }
        });
        // console.log("subcount_item val: " + subcount_item);
        count_income_item += subcount_item;
        // if (targetData.storeID == "MQ5vj5sTlQSXOxIC9vMD")
        // {
        //   console.log("check val: " + count_income_item);
        // }

        item.couponUsageList.map((ctu, k_ctu) => {
          if (!ctu.isExchange)
          {
            var exchange_salesPrice = ctu.exchange_salesPrice
            if (!exchange_salesPrice || exchange_salesPrice === null){
              exchange_salesPrice = 0
            } 
            subcount_coupon +=
              parseFloat(exchange_salesPrice) * parseFloat(ctu.use);
              //  console.log("exchange_salesPrice: " + exchange_salesPrice + " ctu: " + ctu.exchange_itemName + " use: " + ctu.use);
          }

        });
        // console.log("subcount_coupon: " + subcount_coupon);
        count_income_redeem += subcount_coupon;
      });
      // console.log("count_income_item: " + _subcount_item);
      // console.log("count_income_redeem: " + _subcount_coupon);
      // console.log("================================================");

     count_subtotal_sales =
       count_income_item +
       count_income_coupon +
       count_income_pacakge_product +
       count_income_redeem;
      
       // console.log("otherExpList  "+JSON.stringify(data_report.expenditureList))
       targetData.expenditureList.map((expItem, key_expItem) => {
       // console.log("otherExpList  "+JSON.stringify(data_report.expItem))

         if (expItem.type === "inventory-in") {
          count_exp_purchases += parseFloat(expItem.amount);
         } else if (expItem.type === "commission") {
          count_exp_commission += parseFloat(expItem.amount);
         }else if (expItem.type === "discount_allowed_sales") {
          count_exp_discount_allowed_sales += parseFloat(expItem.amount);
         }else if (expItem.type === "discount_allowed_prepaid") {
          count_exp_discount_allowed_prepaid += parseFloat(expItem.amount);
         } else {
           console.log(`Other Case`);
           count_exp_other += parseFloat(expItem.amount);
         }
       });

       newStoreInfo.count_income_item += count_income_item
       newStoreInfo.count_income_coupon += count_income_coupon
       newStoreInfo.count_income_pacakge_product += count_income_pacakge_product
       newStoreInfo.count_income_redeem += count_income_redeem
       newStoreInfo.count_subtotal_sales += count_subtotal_sales

       newStoreInfo.count_exp_purchases += count_exp_purchases
       newStoreInfo.count_exp_commission += count_exp_commission
       newStoreInfo.count_exp_discount_allowed_sales += count_exp_discount_allowed_sales
       newStoreInfo.count_exp_discount_allowed_prepaid += count_exp_discount_allowed_prepaid
       newStoreInfo.count_exp_other += count_exp_other
      
       newStoreInfo.storeName = targetData.storeName
       newStoreInfo.storeID = targetData.storeID
        // console.log("newStoreInfo: "+JSON.stringify(targetData.storeName))

       storeInfoDict[targetData.storeID] = newStoreInfo //update 
       setStoreInfoDict(storeInfoDict)

  }

  function show_reportDetail(data_report,startDate,endDate) {
    if (!data_report ||data_report === null) {
      return null;
    } else {
      // calculate income part
      // var count_income_item = 0.0;
      // var count_income_coupon = 0.0;
      // var count_income_pacakge_product = 0.0;
      // var count_income_redeem = 0.0;
      // var count_subtotal_sales = 0.0;

      // const { sales } = data_report;
      // console.log("show_reportDetail  data_report:"+JSON.stringify(data_report))

      // data_report.sales.map((item, key_salesItem) => {
      //  item.itemsInPackage.map((iip, k_kip) => {
      //   // console.log("typrrrrr "+ iip.type)
      //   if (iip.type === "item" || iip.type === "package_product") {
      //     count_income_item += parseFloat(iip.price) * parseFloat(iip.qty);
      //   }
        
      //   // else if (iip.type === "coupon")
      //   // {
      //   //   count_income_coupon += parseFloat(iip.price * iip.qty);
      //   // }
      //   // else if (iip.type === "package_product") {
      //   //   count_income_pacakge_product += parseFloat(iip.price * iip.qty);
      //   // }
      // });

      // item.couponUsageList.map((ctu, k_ctu) => {
      //   if (!ctu.isExchange){
      //     var exchange_salesPrice = ctu.exchange_salesPrice
      //     if (!exchange_salesPrice || exchange_salesPrice === null){
      //       exchange_salesPrice = 0
      //     } 
      //     count_income_redeem +=
      //       parseFloat(exchange_salesPrice) * parseFloat(ctu.use);
      //   }
      // });
      // })
      // count_subtotal_sales =
      //   count_income_item +
      //   count_income_coupon +
      //   count_income_pacakge_product +
      //   count_income_redeem;

      // // calcualte expenditure part
      // var count_exp_purchases = 0.0;
      // var count_exp_commission = 0.0;
      // var count_exp_discount_allowed_sales = 0.0
      // var count_exp_discount_allowed_prepaid = 0.0
      // var count_exp_other = 0.0;

      var otherExpList = [];
     
        // console.log("otherExpList  "+JSON.stringify(data_report.expenditureList))
        // data_report.expenditureList.map((expItem, key_expItem) => {
        // // console.log("otherExpList  "+JSON.stringify(data_report.expItem))

        //   if (expItem.type === "inventory-in") {
        //     count_exp_purchases += parseFloat(expItem.amount);
        //   } else if (expItem.type === "commission") {
        //     count_exp_commission += parseFloat(expItem.amount);
        //   }else if (expItem.type === "discount_allowed_sales") {
        //     count_exp_discount_allowed_sales += parseFloat(expItem.amount);
        //   }else if (expItem.type === "discount_allowed_prepaid") {
        //     count_exp_discount_allowed_prepaid += parseFloat(expItem.amount);
        //   } else {
        //     console.log(`Other Case`);
        //     count_exp_other += parseFloat(expItem.amount);
        //   }
        // });
        
        otherExpList.push(
          {itemName:Loc.discount_allowed_sales,amount:data_report.count_exp_discount_allowed_sales},
          {itemName:Loc.discount_allowed_prepaid,amount:data_report.count_exp_discount_allowed_prepaid},
          {itemName:Loc.other_expenditure,amount:data_report.count_exp_other},
          );

      const otherExpDiv = otherExpList.map((expItem, k) => {
        // console.log(`expItem : =======>  ${JSON.stringify(expItem)}`);

        return (
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={6}>
                <h5 style={{ paddingLeft: 20 }}>{expItem.itemName}</h5>
              </GridItem>

              <GridItem xs={2} style={{ textAlign: "right" }}>
                <h5>{/* {"00.00%"} */}</h5>
              </GridItem>

              <GridItem xs={2} style={{ textAlign: "right" }}>
                <h5 style={{ paddingLeft: 20 }}>
                  {roundValue(expItem.amount).toLocaleString()}
                </h5>
              </GridItem>

              <GridItem xs={2} style={{ textAlign: "right" }}>
                <h5>
                  {k === otherExpList.length - 1 ? (
                    <b>{"-" + roundValue(data_report.count_exp_other+data_report.count_exp_discount_allowed_sales+data_report.count_exp_discount_allowed_prepaid).toLocaleString()}</b>
                  ) : null}
                </h5>
              </GridItem>
            </GridContainer>
          </GridItem>
        );
      });

      // calculate
      const gross_margin = data_report.count_subtotal_sales - data_report.count_exp_purchases;
      const operating_expenses = data_report.count_exp_commission;
      const operating_income = gross_margin - operating_expenses;
      const net_profit_or_lost = operating_income - (data_report.count_exp_other+data_report.count_exp_discount_allowed_sales+data_report.count_exp_discount_allowed_prepaid);
      const storeName =
        (data_report && data_report.storeName !== "")
          ? data_report.storeName
          : Loc.store_default;

      const pdfdiv = `pdfdiv_${(data_report && data_report.storeID !== "") ? data_report.storeID:'default'}`;

      return (
        <Card>
          {reportHeader(storeName, data_report,startDate,endDate)}

          <div id={pdfdiv}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} style={{ textAlign: "center" }}>
                  <h2>
                    <b>{Loc.report_operating}</b>
                  </h2>
                  <h5>{storeName}</h5>
                  <h5>
                    {moment(startDate).format("YYYY/MM/DD") +
                      " ~ " +
                      moment(endDate).format("YYYY/MM/DD")}
                  </h5>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_itemName}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>{/* <b>{Loc.report_percentage}</b> */}</h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{Loc.report_amount}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{Loc.report_amount}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                  <hr />
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_sales}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {Loc.report_sales_product}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>{/* {"00.00%"} */}</h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {roundValue(data_report.count_income_item).toLocaleString()}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                {/* <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={6}>
                <h5 style={{paddingLeft: 20}}>
                {Loc.report_sales_coupon}
                </h5>
                </GridItem>

                <GridItem xs={2} style={{textAlign: "right",}}>
                <h5 style={{paddingLeft: 20}}>
                {"00.00%"}
                </h5>
                </GridItem>

                <GridItem xs={2} style={{textAlign: "right",}}>
                <h5>
                {count_income_coupon.toLocaleString()}
                </h5>
                </GridItem>

                <GridItem xs={2} style={{textAlign: "right",}}>
                <h5>
                </h5>
                </GridItem>
              </GridContainer>  
            </GridItem> */}

                {/* <GridItem xs={12}> */}
                  {/* <GridContainer> */}
                    {/* <GridItem xs={6}> */}
                      {/* <h5 style={{ paddingLeft: 20 }}> */}
                        {/* {Loc.report_sales_package} */}
                      {/* </h5> */}
                    {/* </GridItem> */}

                    {/* <GridItem xs={2} style={{ textAlign: "right" }}> */}
                      {/* <h5 style={{ paddingLeft: 20 }}>{"00.00%"}</h5> */}
                    {/* </GridItem> */}

                    {/* <GridItem xs={2} style={{ textAlign: "right" }}> */}
                      {/* <h5>{roundValue(count_income_pacakge_product).toLocaleString()}</h5> */}
                    {/* </GridItem> */}

                    {/* <GridItem xs={2} style={{ textAlign: "right" }}> */}
                      {/* <h5></h5> */}
                    {/* </GridItem> */}
                  {/* </GridContainer> */}
                {/* </GridItem> */}

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {Loc.report_sales_coupon_use}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5 style={{ paddingLeft: 20 }}>{/* {"00.00%"} */}</h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>{roundValue(data_report.count_income_redeem).toLocaleString()}</h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{roundValue(data_report.count_subtotal_sales).toLocaleString()}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_cost_of_good_sold}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {Loc.report_exp_purchases}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>{/* {"00.00%"} */}</h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {roundValue(data_report.count_exp_purchases).toLocaleString()}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{"-" + roundValue(data_report.count_exp_purchases).toLocaleString()}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  style={{ height: 1, backgroundColor: "lightgray" }}
                ></GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_gross_margin}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{roundValue(gross_margin).toLocaleString()}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <br />
                </GridItem>
                <GridItem xs={12}>
                  <br />
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_exp_operating}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {Loc.report_exp_commission}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>{/* {"00.00%"} */}</h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5 style={{ paddingLeft: 20 }}>
                        {roundValue(data_report.count_exp_commission).toLocaleString()}
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{"-" + roundValue(operating_expenses).toLocaleString()}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  style={{ height: 1, backgroundColor: "lightgray" }}
                ></GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_operating_income}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{roundValue(operating_income).toLocaleString()}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <br />
                </GridItem>
                <GridItem xs={12}>
                  <br />
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>{Loc.report_exp_other}</b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>{otherExpDiv}</GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  style={{ height: 1, backgroundColor: "lightgray" }}
                ></GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5>
                        <b>
                          {net_profit_or_lost < 0
                            ? Loc.report_net_loss
                            : Loc.report_net_income}
                        </b>
                      </h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5></h5>
                    </GridItem>

                    <GridItem xs={2} style={{ textAlign: "right" }}>
                      <h5>
                        <b>{roundValue(net_profit_or_lost).toLocaleString()}</b>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem
                  xs={12}
                  style={{ height: 1, backgroundColor: "lightgray" }}
                ></GridItem>
                <GridItem xs={12} style={{ height: 1 }}></GridItem>
                <GridItem
                  xs={12}
                  style={{ height: 1, backgroundColor: "lightgray" }}
                ></GridItem>
              </GridContainer>
            </CardBody>
          </div>
        </Card>
      );
    }
  }

  function _renderReportDetail(targetData,isGenReport,startDate,endDate) {
    // if (targetData.length == 0) {
    //   return null;
    // }
    // targetData.sort((a, b) => (a.storeID > b.storeID ? 1 : -1));

    var items = []
    
    // targetData.map((item) => {
    //   items.push(show_reportDetail(item));
    // });
    if(locationState !== null && data_dataListArray !== null){
    if (!isGenReport){
        data_dataListArray["Store"].map(item=>{
          console.log("_renderReportDetail : "+item.itemName)
    
          calReportInfo(storeInfoDict,targetData[item.id]);
    
          // items.push(show_reportDetail(targetData[item.id]))
        })
    
      }else{


        data_dataListArray["Store"].map(item=>{
          // console.log("_renderReportDetail : "+item.itemName)
    
          // show_reportDetail();
    
          items.push(show_reportDetail(storeInfoDict[item.id],startDate,endDate))
        })
      }
    }
    
    // console.log("storeInfoDict Now : "+JSON.stringify(storeInfoDict))

    
    setReportDetailData(items)
    initStoreInfo();
    // return items;
  }

  function showHide_download() {
    // if (data_reportList === null || data_reportList.length ==0)
    // {
    //   return null;
    // }
    // else
    // {
    return (
      <Button color="success" onClick={() => operation()}>
        {Loc.download_pdf}
      </Button>
    );
    // }
  }
  function roundValue(value){
    return Math.floor(value * 100) / 100;
  }
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{Loc.report_operating}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} lg={3}>
                  <CustomDropdown
                    dropup
                    dropdownHeader={Loc.select_period}
                    buttonText={get_dropdownTextToShow()}
                    buttonProps={{
                      round: true,
                      color: "info",
                    }}
                    dropdownList={[
                      Loc.this_week,
                      Loc.this_month,
                      Loc.this_year,
                      { divider: true },
                      Loc.select_custom_period,
                    ]}
                    itemIDList={["week", "month", "year", "", "other"]}
                    onClick={(e) => {
                      if (e !== "other") {
                        handle_fast_search(e);
                      }
                      setData_selectedPeriod(e);
                     
                    }}
                  />

                  {/* {showHide_download()} */}
                </GridItem>

                {showHide_selectPeriod()}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>{reportDetailData}</GridItem>
      </GridContainer>
    </div>
  );
}
